import React, { useState } from "react";
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { dotStream } from 'ldrs'
import { Link } from "react-router-dom";

dotStream.register()

// Default values shown


export default function NewDemand() {
    const db = getFirestore()

    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)

    var urlActual = new URL(window.location.href);
    var urlParams = urlActual.search;
    var params = urlParams;

    function obtenerValorDeAtributo(parametroBuscado) {
        // Obtener el URL actual y su cadena de consulta
        var urlActual = window.location.href;
        var queryString = urlActual.split('?')[1];

        // Verificar si hay una cadena de consulta
        if (queryString) {
            // Dividir la cadena de consulta en pares clave=valor
            var parametros = queryString.split('&');

            // Iterar sobre los pares clave=valor
            for (var i = 0; i < parametros.length; i++) {
                var parametro = parametros[i].split('=');

                // Verificar si el parámetro es igual al atributo buscado
                if (parametro[0] === parametroBuscado) {
                    // El valor después del "=" está en parametro[1]
                    var valor = parametro[1];
                    return valor;
                }
            }
        }
    }




    const initialRequest = {
        name: '', //
        ig: '',
        wa: '',
        zone: params,
        service: '',
        date: '',
        address: '',
        timestamp: Date.now()
    };

    // Estado del usuario
    const [request, setRequest] = useState(initialRequest);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRequest({
            ...request,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)

        setTimeout(() => {
            setSent(true)
            setLoading(false)
        }, 3000);

        const queryCollection = collection(db, `requests`)
        addDoc(queryCollection, request)
            .then(res => console.log(res))
            .catch(err => console.log(err))

    };


//     const sendEmailF = async () => {
//         let data = request; // Aquí asignas el objeto 'request' a la variable 'data'
    
//         axios.post("https://emails-api-i5ek.onrender.com/api/mailer/send", { data: request }, { withCredentials: true })
// .then(() => {
//             console.log(JSON.stringify(data))
//         }).catch((error) => {
//             // Manejar errores aquí
//             console.log(JSON.stringify(data))
//             console.error('Error al obtener datos:', error);
//         });
//     }
    

    
    const sendEmailF = async () => {
        try {
            // Enviar los datos actualizados al servidor
            const response = await fetch(`https://emails-api-i5ek.onrender.com/api/mailer/send`, {
                method: 'POST',
                mode: 'cors',  // Utiliza 'cors' en lugar de 'no-cors'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            })

            // Manejar la respuesta aquí si es necesario
            const data = await response.json();

        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
    };

    return (
        <div className='newCalendarCont'>
            <img className='logoCentered' src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Flogo.jpg?alt=media&token=8d13d404-2cbf-48d9-8e14-578f9f4f5b74" alt="" />

            <Link to="/" className="button">
                            volver al inicio
                        </Link>
            <p className="micrositeTittle minitextBogue blue ">
                coordinemos un horario <span className="montserrat">!</span>
            </p>
            <form className="newRequestForm" onSubmit={handleSubmit} action="">

                <div className='requestInputCont' >
                    <label className='requestLabel' htmlFor="Nombre" > Nombre </label>
                    <input className='requestInput' placeholder="Nombre" type="text" name="name" value={request.name} onChange={handleInputChange} />
                </div>
                <div className='requestInputCont' >
                    <label className='requestLabel' htmlFor="wa" > Whatsapp </label>
                    <input className='requestInput' placeholder="Whatsapp" type="text" name="wa" value={request.wa} onChange={handleInputChange} />
                </div>
                <div className='requestInputCont' >
                    <label className='requestLabel' htmlFor="ig" > Instagram </label>
                    <input className='requestInput' placeholder="Tu usuario de IG" type="text" name="ig" value={request.ig} onChange={handleInputChange} />
                </div>
                <div className='requestInputCont' >
                    <label className='requestLabel' htmlFor="service" > Servicio </label>
                    <input className='requestInput' placeholder="Servicio. Por ejm: corte de pelo corto/largo, balayage..." type="text" name="service" value={request.service} onChange={handleInputChange} />
                </div>

                <div className='requestInputCont' >
                    <label className='requestLabel' htmlFor="address" > Dirección </label>
                    <input className='requestInput' placeholder="Ej.: Calle 45 e/10 y 11." type="text" name="address" value={request.address} onChange={handleInputChange} />
                </div>

                <div className='requestInputCont' >
                    <label className='requestLabel' htmlFor="date" > Disponibilidad </label>
                    <input className='requestInput' placeholder="Ej.: Lunes y martes de 16 a 18 y jueves de 13 a 18." type="text" name="date" value={request.date} onChange={handleInputChange} />
                </div>
                {/* <div className='requestInputCont' >
                    <label className='requestLabel' htmlFor="Horario" > Horario de </label>
                    <input className='requestInput' type="time" name="timeFrom" value={request.timeFrom} onChange={handleInputChange} />
                </div>
                <div className='requestInputCont' >
                    <label className='requestLabel' htmlFor="Horario" > Horario hasta </label>
                    <input className='requestInput' type="time" name="timeTo" value={request.timeTo} onChange={handleInputChange} />
                </div> */}

                {request.name.length > 3 && request.wa.length > 3 && request.ig.length > 3 && request.service.length > 3 && request.address.length > 3 && request.date.length > 3 ?
                    <button onClick={sendEmailF} className="button2"> {loading ?             <l-dot-stream
                        size="40"
                        speed="2.5" 
                        color="blue" 
                      ></l-dot-stream> : sent ? "listo!" : "enviar solicitud" }  </button>
                    : <></>}

            </form>


            <footer>
              <div className='footerTexts '>


                <Link className='row mobileColumn' to="https://api.whatsapp.com/send/?phone=5492213990248&text&type=phone_number&app_absent=0">
                  <p className='footerTextItem'> si tenes alguna duda o inquietud sobre el sistema, contactame! </p>
                  <img className='whatsappLogo' src="https://img.icons8.com/ios/50/76d273/whatsapp--v1.png" />
                </Link>

                <img className='wavyPeq' src="https://img.icons8.com/dotty/80/ffffff/wavy-line.png" />


                <Link className='footerTextItem ' to="https://tomitorresdev.com.ar/turnos">
                  Quiero mi sitio de turnos
                </Link>
              </div>


            </footer>


        </div>
    )
}
