import React, { useState, useEffect } from 'react';
import { useBookingContext } from '../context/BookingContext'
import { addDoc, collection, getFirestore, getDocs } from 'firebase/firestore';
import { useRoomsContext } from '../context/RoomsContext'
import { useLoginContext } from '../context/LoginContext'
import { useParams } from 'react-router-dom';
import Login from './login/Login';
import NavbarPanel from './NavbarPanel';
import NewBookings from './dashboard/NewBookings';
import BookingsWidget from './widgets/BookingsWidget';



export default function Dashboard() {

  const db = getFirestore()
  const { customer } = useParams()

  const { bookings, rerender } = useBookingContext()
  const { services, rerenderF } = useRoomsContext()
  const { loginCheck } = useLoginContext()


  const [month, setMonth] = useState()
  const [year, setYear] = useState()
  const [today, setToday] = useState()
  const [todaysMonth, setTodaysMonth] = useState()
  const [todaysYear, setTodaysYear] = useState()
  const [nextmonth, setNextmonth] = useState()
  const [monthDays, setMonthDays] = useState()


  const [comments, setComments] = useState([])

  useEffect(() => {

    const QueryCollection = collection(db, `comments`)

    getDocs(QueryCollection)
      .then(resp => setComments(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
      .catch((err) => console.log(err))


  }, [ ])

  useEffect(() => {
    rerender()
  }, [])

  useEffect(() => {

    if (services.length === 0) {
      rerenderF()
    }
  }, [services])

  const [loader, setLoader] = useState(true)

  setTimeout(() => {
    setLoader(false)
  }, 3000);


  useEffect(() => {

    if (month <= 11) {
      const nextmonthF = month + 1
      setNextmonth(nextmonthF)
    } if (month === 12) {
      setNextmonth(1)
    }
  }, [month])

  useEffect(() => {

    if (month <= 11) {
      const nextmonthF = month + 1
      setNextmonth(nextmonthF)
    } if (month === 12) {
      setNextmonth(1)
    }
  }, [month])

  useEffect(() => {
    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }
    setMonthDays(getDaysInMonth(year, month))
  }, [month])

  useEffect(() => {
    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }

    const date = new Date();
    const yearF = date.getFullYear()
    const monthF = date.getMonth() + 1
    const todayF = date.getDate()
    setYear(yearF)
    setMonth(monthF)
    setToday(todayF)
    setTodaysMonth(monthF)
    setTodaysYear(yearF)
  }, [])

  useEffect(() => {
    sessionStorage.setItem('adminToken', true);
  }, [])

  const [loginAnimation, setLoginAnimation] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])



  return (
    <>

      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
     

          <Login />
        </div>
        :

        <>
          <div className='dashboardCont'>
            <NavbarPanel bookings={bookings} customer={customer} />
            <p className='sectionTittles'>| DASHBOARD</p>

            <div className='dashboardRowCont'>


               <NewBookings bookings={bookings} month={month} year={year} today={today} todaysMonth={todaysMonth} todaysYear={todaysYear} customer={customer} rerender={rerender} />

  
        
            </div>
          </div>
        </>
      }


    </>

  )
}
