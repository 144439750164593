import React, { useState, useEffect } from 'react'

import Comment from './Comment'
import { LineWobble } from '@uiball/loaders'

export default function CommentsList({ comments, dashboard, customer, changed }) {



  const commentFilter = dashboard === true ? comments : comments.filter(e => e.state === true)

  const [direction, setDirection] = useState(true)
  const [order, setOrder] = useState(0)
  const [commentToShow, setCommentToShow] = useState([commentFilter[0]])

  const next = () => {
    if (commentFilter.length > order + 1) {
      let a = order + 1
      setCommentToShow([commentFilter[a]])
      setOrder(order + 1)
      setDirection(true)
    } else {
      setOrder(order)
    }
  }

  const previous = () => {
    if (commentFilter.length > order - 1 && order > 0) {
      let a = order - 1
      setCommentToShow([commentFilter[a]])
      setOrder(order - 1)
      setDirection(false)
    } else {
      setDirection(true)
    }
  }


  useEffect(() => {
    if (order === 0) {
      setDirection(true)
    }
  }, [order])



  return (
    <>

      {commentFilter.length === 0 ?
        <>
          {dashboard === true ?
            <LineWobble
              size={80}
              lineWeight={5}
              speed={1.75}
              color="#0010d5" /> : <p className='ourGuests'> este espacio está reservado para vos!</p>}
        </>

        :
        <>
          {dashboard === true ? <>

            {commentFilter.map((commentary) =>

              <Comment key={commentary.id} {...commentary} dashboard={dashboard} customer={customer} changed={changed} />
            )}


          </> :

            <>
              {commentToShow.map((commentary) =>

                <Comment key={commentary.id} {...commentary} dashboard={dashboard} customer={customer} changed={changed} />
              )}

              {commentFilter.length > order + 1 && direction === true ? <button className='button2' onClick={next}>siguiente</button> : <button className='button2' onClick={previous}>anterior</button>}

            </>
          } </>
      }

    </>
  )
}
