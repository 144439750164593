import { useState, useEffect } from 'react';
import RequestItem from './RequestItem';


export default function RequestsList({
    requests, search, rerender, cancel, transferTrue
}) {

    const [filteredBookings, setFilteredBookings] = useState([])


    var normalize = require('lodash');
    const oneDayInMilliseconds = 86400000; // Número de milisegundos en un día
  
    // Filtrar las reservas que ocurrieron en los últimos 7 días
    const bookingsLast7Days = requests.filter(
      (booking) =>
        Date.now() - new Date(booking.timestamp).getTime() <= 7 * oneDayInMilliseconds
    );
  
    // Filtrar por búsqueda (si hay alguna)
    useEffect(() => {
      let filter = search.length === 0
      ? bookingsLast7Days
      : requests.filter((booking) =>
          normalize(booking.client.name.toLowerCase()).includes(normalize(search.toLowerCase()))
        )
      setFilteredBookings(filter)
    }, [requests, search, rerender])
  


    return (
      
      <>
    <div className='bookOrderDashboardlist'>
          {requests.sort((a, b) => b.timestamp - a.timestamp).map((request) => (
            <RequestItem
              key={request.id}
              {...request}
              requests={requests}
              rerender={rerender}
              cancel={cancel}
              transferTrue={transferTrue}
            />
          ))}
        </div>
      </>
    )
  }
  