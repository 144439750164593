import React, { useState, useEffect } from 'react';
import { addDoc, collection, getFirestore} from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { RaceBy } from '@uiball/loaders'


export default function NewComment({ newCommentModal, closeModal }) {
    const db = getFirestore()
    const { customer, roomedit } = useParams()

    const [loading, setLoading] = useState()
    const [loaded, setLoaded] = useState()
    const [newname, setNewname] = useState(null)
    const [newmessage, setNewmessage] = useState(null)



    const handleSubmit = (event) => {
        event.preventDefault();

    }

    const newComment = () => {

        setLoading(true)

        const newMessage = {}

        newMessage.name = newname
        newMessage.message = newmessage
        newMessage.timestamp = Date.now()
        newMessage.state = false

        const queryCollection = collection(db, `comments`)
        addDoc(queryCollection, newMessage)
            .then(res => console.log(res))
            .catch(err => console.log(err))

        setTimeout(() => {
            setLoading(false)
            setLoaded(true)
            setTimeout(() => {
                closeModal()
            }, 2000);
        }, 2000);


    }

    return (
        <div className={newCommentModal === false ? "displayNone" : 'newcommentModal'} >
            <p className='button ' onClick={closeModal}>cerrar</p>
            <p className='ourGuests'>dejame tu mensaje!</p>

            <form className='newCommentForm' action="" onSubmit={handleSubmit}>


                <div className='inputRoomEditLabelCont'>
                    <label className='labelRoomEdit' > Nombre </label>
                    <input className='newcommentinput' type="text" onChange={(e) => setNewname(e.target.value)} />
                </div>


                <div className='inputRoomEditLabelCont'>
                    <label className='labelRoomEdit' > Mensaje </label>
                    <textarea className='newcommentinput newcommenttextarea' type="date" onChange={(e) => setNewmessage(e.target.value)} />
                </div>

            {newname !== null && newmessage !== null ?  <button className='button2 marginauto2' onClick={newComment}>

{loading === true ?

    <RaceBy
        size={80}
        lineWeight={5}
        speed={1.4}
        color="white"
    /> : loaded === true ? "¡Gracias!" : "Enviar"
}


</button> : <></>}
               
            </form>

        </div>
    )
}
