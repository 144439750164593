import React, { useState, useEffect } from 'react';
import ImgEditButton from '../microsite/imgEditButton'
import ImgEditButtonRooms from './buttons/ImgEditButtonRooms'
import RemoveImg from './buttons/RemoveImg';

export default function RoomsEditImage({ roomname, prop, image, customer, edit, roomid, rerender, microsite, col, openGallery, allImagesLinksFilter, rerenderMicro }) {

  const [micro, setMicro] = useState(false)


  useEffect(() => {
    setMicro(microsite)
  }, [microsite])


  return (
    <div className='imgEditCont'>

      {image != null ?

        <>
        {micro === true ? 
          <RemoveImg rerenderMicro={rerenderMicro} prop={prop} roomid={roomid} link={image} customer={customer} edit={edit} col={col} micro={micro} />
        : 
        <RemoveImg rerender={rerender} prop={prop} roomid={roomid} link={image} customer={customer} edit={edit}/>
        }
        </>



        : <></>}

      {image != null ?
        <>
          <img className='editRoomImg' src={image} alt="" />
        </> :

        micro === true ?
          <ImgEditButton prop={prop} customer={customer} edit={edit} col={col} micro={micro} openGallery={openGallery}  rerenderMicro={rerenderMicro} /> :

          <ImgEditButtonRooms rerender={rerender} prop={prop} roomid={roomid} link={image} customer={customer} edit={edit} openGallery={openGallery} roomname={roomname} />
      }



    </div>
  )
}
