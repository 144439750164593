import { useState, useEffect } from 'react';

import BookOrderDashboard from './BookOrderDashboard'


export default function BookOrderDashboardList({
    bookings, search, rerender, cancel, transferTrue
}) {


  const [filteredBookings, setFilteredBookings] = useState([])


  var normalize = require('lodash');
  const oneDayInMilliseconds = 86400000; // Número de milisegundos en un día

  // Filtrar las reservas que ocurrieron en los últimos 7 días
  const bookingsLast7Days = bookings.filter(
    (booking) =>
      Date.now() - new Date(booking.timestamp).getTime() <= 7 * oneDayInMilliseconds
  );

  // Filtrar por búsqueda (si hay alguna)
  useEffect(() => {
    let filter = search.length === 0
    ? bookingsLast7Days
    : bookings.filter((booking) =>
        normalize(booking.client.name.toLowerCase()).includes(normalize(search.toLowerCase()))
      )
    setFilteredBookings(filter)
  }, [bookings, search, rerender])


  return (
    
    <>
  <div className='bookOrderDashboardlist'>
        {filteredBookings.sort((a, b) => b.timestamp - a.timestamp).map((book) => (
          <BookOrderDashboard
            key={book.id}
            {...book}
            bookings={bookings}
            rerender={rerender}
            cancel={cancel}
            transferTrue={transferTrue}
          />
        ))}
      </div>
    </>
  )
}
