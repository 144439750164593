import React, { useState, useEffect } from 'react'
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useRoomsContext } from '../context/RoomsContext'
import { useCalendarContext } from '../context/CalendarContext'

import BookList from '../booking/BookList';
import { Link } from 'react-router-dom';

export default function BookCont({ images, asesoria, toHomeF, asesoriaTrue }) {

    const db = getFirestore()

    const [serviceChoose, setServiceChoose] = useState(null)

    const { forbiddenDays, availDaysForDetail, resetAvailDaysDetail } = useCalendarContext()
    const { services, rerenderF, render } = useRoomsContext()


    const [month, setMonth] = useState()
    const [year, setYear] = useState()
    const [firstDay, setFirstDay] = useState("")
    const [monthDays, setMonthDays] = useState()
    const [currentMonth, setCurrentMonth] = useState(null)
    const [serviceYear, setServiceYear] = useState(null)
    const [serviceMonth, setServiceMonth] = useState(null)
    const [itemsData, setItemsData] = useState([])

    const toZero = () => {
        setServiceChoose(null)
        setServiceYear(null)
        setServiceMonth(null)
        resetAvailDaysDetail()
        toHomeF()
        if(serviceChoose === "asesoria") {
            asesoriaTrue()
        }
     
    }


    
  useEffect(() => { // OK
    const queryCol = collection(db, `items`)

    getDocs(queryCol)
      .then(resp => setItemsData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
      .catch()

  }, [])





    useEffect(() => { // OK
        if (serviceChoose !== null) {
            const QueryCollection = collection(db, `services/${serviceChoose}/${year}`)
            getDocs(QueryCollection)
                .then(resp => setServiceYear(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
                .catch((err) => console.log(err))

        } else {

        }

    }, [serviceChoose, year, month])

    const [serviceData, setServiceData] = useState([])
    const [serviceItems, setServiceItems] = useState([])
    const [itemsByTime, setItemsByTime] = useState([])

    useEffect(() => {
        if(serviceChoose !== null) {
            let thisSe = services.filter(e => e.id == serviceChoose)
            setServiceData(thisSe)
        }

    }, [services, render, serviceChoose])
 
    
    const promise = new Promise((resolve, reject) => {
        if(serviceData.length > 0) {
            resolve()
        } else {

        }
      });

    useEffect(() => {
        promise.then(() => {
            serviceData[0].items.forEach( e => 
                itemsByTime.push(itemsData.filter(a => a.id == e))
            )
        })

    }, [serviceData, itemsData, serviceChoose])

   



    useEffect(() => {
        if (serviceYear !== null) {
            setServiceMonth(serviceYear.filter(e => e.id == month))
        } else {

        }
    }, [serviceYear])


    
    useEffect(() => {
        function getDaysInMonth(y, m) {
            return new Date(y, m, 0).getDate();
        }

        const date = new Date();
        const yearF = date.getFullYear()
        const monthF = date.getMonth() + 1

        setYear(yearF)
        setMonth(monthF)
        setCurrentMonth(monthF)

    }, [])

    useEffect(() => {

        function getDaysInMonth(y, m) {
            return new Date(y, m, 0).getDate();
        }

        const daysInCurrentMonthF = setDaysInCurrentMonth(getDaysInMonth(year, month));

    }, [])




    const [daysInCurrentMonth, setDaysInCurrentMonth] = useState()

    const serviceElection = (a) => {
        setServiceChoose(a)
    }

    useEffect(() => {
      if(asesoria === true) {
        setServiceChoose("asesoria")
        rerenderF()
      }
    }, [asesoria])

    
    useEffect(() => {

        const yearf = year
        const monthf = month
        const firstDayF = new Date(yearf, monthf - 1, 1)

        setFirstDay(firstDayF.toString()[0] + firstDayF.toString()[1] + firstDayF.toString()[2])
    }, [month])




    useEffect(() => {
        function getDaysInMonth(y, m) {
            return new Date(y, m, 0).getDate();
        }
        setMonthDays(getDaysInMonth(year, month))
    }, [month])
    

    const listaOrdenada = ['domiciliozona1', 'domiciliozona3', 'domiciliozona4', 'domiciliozona2'];


    const compararIDs = (obj1, obj2) => {
        const index1 = listaOrdenada.indexOf(obj1.id);
        const index2 = listaOrdenada.indexOf(obj2.id);
        if (index1 < index2) {
            return -1;
        } else if (index1 > index2) {
            return 1;
        } else {
            return 0;
        }
    }




    const siguiente = () => {
        setMonth(month + 1)
    
    }

    const anterior = () => {
        setMonth(month - 1)
   
    }



    return (
        <>
            {images && (
                <img onClick={() => {
                    toZero();  toHomeF()
                }} className='logoMicrosite' src={images.logo} alt="" />
            )}

            {serviceChoose === null ? <h1 className='micrositeTittle subtittleBogue'> en donde preferis? </h1> : <></>}
            {serviceChoose == "dom" ? <div className='column'> <h1 className='micrositeTittle subtittleBogue'> elegi tu zona</h1> <p className='micrositeDescription'>aprovechamos el cuadrado que es La Plata para divirlo en zonas de trabajo. fijate en cuál cae tu casa y elegí el cuadrante correspondiente.
</p> </div> : <></>}
            {serviceChoose == "dom" ? <button onClick={toZero} className='button2'> VOLVER </button> : <></>}

            {serviceChoose == "local" ? <h1 className='micrositeTittle subtittleBogue'> que dia te espero? </h1> : <></>}
            {serviceChoose == "domicilio" ? <h1 className='micrositeTittle subtittleBogue'> que dia voy? </h1> : <></>}
            {serviceChoose === null ? <div>


                {serviceChoose === null ? <div>

                    <button className='servicesButton' onClick={() => {
                        serviceElection("local")
                    }}> en el COQUEstudio </button>

                    <button className='servicesButton' onClick={() => {
                        serviceElection("dom")
                    }}> en tu casita </button>

<Link className='bluegradient' to="https://api.whatsapp.com/send/?phone=5492213990248&text&type=phone_number&app_absent=0">
por <b className='margin2'>retoque de raíces</b> o <b className='margin2'>balayage</b> consultame disponibilidad por whatsapp        <img className='whatsappLogo' src="https://img.icons8.com/ios/50/000000/whatsapp--v1.png" />
                </Link>


                </div> : <></>}

            </div> : <></>}

            {serviceChoose === "dom" ?

                <div className='mapServicesCont'>

                    <img className='serviceMapImg' src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Fmapa.png?alt=media&token=b5259e79-5893-44a8-97ac-f5c3cba63bb8" alt="" />

                    {services.filter(e => e.id !== "local" && e.id !== "asesoria").sort(compararIDs).map((a) =>
                        <button className='servicesMapButton' key={a.id} onClick={() => {
                            serviceElection(a.id)
                        }}> 🚲 </button>
                    )}
                </div>
                :
                <></>
            }

            {serviceChoose !== null && serviceChoose !== "dom"  ?
                <>
                    <button onClick={toZero} className='button2'> VOLVER </button>

           
                    <BookList availDaysForDetail={availDaysForDetail} itemsByTime={itemsByTime} render={render} siguiente={siguiente} anterior={anterior} currentMonth={currentMonth} firstDay={firstDay} month={month} monthDays={monthDays} year={year} serviceChoose={serviceChoose} serviceMonth={serviceMonth} rerenderF={rerenderF} />

             

                </> : <></>}


        </>
    )
}
