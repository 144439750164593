import React, { useState, useEffect } from 'react';
import { useLocation} from 'react-router-dom';
import { addDoc, collection, getFirestore, getDocs} from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'

export default function SavePaymentData() {
    const db = getFirestore()
    const location = useLocation()

    const [loader, setLoader] = useState(true)
    const [images, setImages] = useState([])

    

    useEffect(() => {

      const QueryCollection = collection(db, `holdear/homepage/app`)
  
      getDocs(QueryCollection)
        .then(resp => setImages(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
        .catch((err) => console.log(err))
  
  
    }, [])


    const [TG, setTG] = useState()
    const [search, setSearch] = useState()

    useEffect(() => {
      const subg = location.search.substr(9, 34)

  
      setTG(subg)
  }, [location])

  
  // MERCADOPAGO

  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        `Bearer APP_USR-2085148632588929-090716-57eb9ccc55965f20750436b86248dd0e-169291933`,
        
    }
  };


  useEffect(() => {
    //getFetch();
    fetch(`https://api.mercadopago.com/v1/oauth`, data)
      .then(function (resp) {
        return resp.json();
      })
      .then((resp) => setSearch(resp));

  }, [TG]);

    useEffect(() => {
      const queryCollection = collection(db, `tokens`)
      let mercadopagoToken = {}
      mercadopagoToken.timestamp = Date.now()
      mercadopagoToken.code = location.search



      if (location.search.length > 5 && location.search.includes("error") === false) {
              addDoc(queryCollection, mercadopagoToken)
              .then(res => console.log(res))
              .catch(err => console.log(err))
      }

    
    }, [location])
    


      useEffect(() => {
        setLoader(true)
      
        setTimeout(() => {
          setLoader(false)
        }, 1500);
       }, [images])
  return (
    <div>
{loader === true ? <> <LineWobble  size={80} lineWeight={5} speed={1.75} color="white" /> </> :
   
   <>
       <p className='sectionTittles'>| PAYMENTS ROOM </p>
       <p className='mercadopagoTittle'>¡Listo! Activaremos cuanto antes tu nuevo método de pago.</p>

        <img className='appImg' src={images[0].app7} alt="Llevá tu emprendimiento al próximo nivel." />
   </>
   
   }
 

    </div>
  )
}
