import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
export default function CancelButton({ cancelOrder, client, service, blockTimes, date, time, item, month, year, day, id }) {
  
  const [cancelled, setCancelled] = useState(false)

  const cancellingUpdate = () => {
    setCancelled(true)
  }

  //// EMAILJS


  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    customer: "",
    to_name: "",
    email_to: "",
    clientName: "",
    date: "",
    service: "",
    time: "",
    item: "",
  });
//   "lacoqueteria.lp@gmail.com"
  useEffect(() => {
      setFormData({
        to_name: "Valu",
        customer: "La Coquetería",
        email_to: "lacoqueteria.lp@gmail.com",   
        clientName: client.name,
        date: date,
        service: service,
        time: time,
        item: item,
      })
     }, [client, date, item, time ])


  const handleChange2 = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit3 = (event) => {
    event.preventDefault();

    emailjs.sendForm(
      "lacoqueteria",
      "template_qi1znrb",
      formRef.current,
      "5HliEyZdhFxxuTp9H",
      { variables: formData }
    ).then(response => {
      if (response.status === 200) {

      } else {

      }
    });

  };
  
    return (
        <>
              <button  type='submit' onClick={() => {
        cancelOrder(blockTimes, service, month, year, day, id); cancellingUpdate()
      }} className='cancelButton44'>
          {cancelled === false ? "CANCELAR" :  "¡LISTO!"}
      </button>
        
        
        <div className='noShowForm' >
        <form className='formAgregarProducto' action="" ref={formRef}
                      onSubmit={handleSubmit3}>
<input type="email"
  name="email_to"
  value={formData.email_to}
  onChange={handleChange2} />


<input type="text"
  name="to_name"
  value={formData.to_name}
  onChange={handleChange2} />

<input type="text"
  name="clientName"
  value={formData.clientName}
  onChange={handleChange2} />


<input type="text"
  name="date"
  value={formData.date}
  onChange={handleChange2} />

<input type="text"
  name="service"
  value={formData.service}
  onChange={handleChange2} />
<input type="text"
  name="time"
  value={formData.time}
  onChange={handleChange2} />
<input type="text"
  name="item"
  value={formData.item}
  onChange={handleChange2} />

<input type="text"
  name="customer"
  value={formData.customer}
  onChange={handleChange2} />




</form>

</div>
        </>

  )
}
