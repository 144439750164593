import React, { useState, useEffect } from 'react';
import { setDoc, doc, getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import NavbarPanel from '../NavbarPanel';
import Backwardsbutton from '../../buttons/Backwardsbutton';
import AddRoomInput from './AddRoomInput';
import { useRoomsContext } from '../../context/RoomsContext'
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
export default function RoomsEditAddRoom() {

  const { customer } = useParams()
  const { rooms, contextRooms, rerender } = useRoomsContext()



  useEffect(() => {
    contextRooms.push(customer)
  }, [])

  const db = getFirestore()
  const [thisyear, setThisyear] = useState()
  const [ingresado, setIngresado] = useState(false)
  const [id, setId] = useState("")
  const [roomname, setRoomname] = useState("")
  const [description, setDescription] = useState("")
  const [beds, setBeds] = useState(0)
  const [type, setType] = useState("")
  const [enabled, setEnabled] = useState("true")
  const [price, setPrice] = useState(0)
  const [size, setSize] = useState(0)
  const [year, setYear] = useState()
  const [month, setMonth] = useState("")
  const [roomMonthsNY, setRoomMonthsNY] = useState([])
  const [roomMonths, setRoomMonths] = useState([])
  const [roomMonthsNYsorted, setRoomMonthsNYsorted] = useState([])
  const [roomMonthssorted, setRoomMonthssorted] = useState([])
  const [openMonthEditorSwitch, setOpenMonthEditorSwitch] = useState(false)
  const { contextLogin, loginUsers, rerenderLogin, ip, loginCheck } = useLoginContext()


  const newroomid = "room" + (rooms.length + 1)
  useEffect(() => {

    setId(newroomid)

  }, [])


  const handleSubmit = (event) => {
    event.preventDefault();

  }


  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear())
    setThisyear(date.getFullYear())
  }, [])

  const openMonthEditor = () => {
    if (openMonthEditorSwitch === true) {
      setOpenMonthEditorSwitch(false)
    } else {
      setOpenMonthEditorSwitch(true)
    }
  }
  const getMonths = () => {
    function saveMonths(a, set) {
      const queryCol = collection(db, `clients/${customer}/rooms/${id}/${a}`)

      getDocs(queryCol)
        .then(resp => set(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
        .catch((err) => console.log(err))
    }

    saveMonths(year, setRoomMonths)
    saveMonths((year + 1), setRoomMonthsNY)
  }

  useEffect(() => {
    setRoomMonthsNYsorted(roomMonthsNY.map(e => e.id).sort((a, b) => a - b))
    setRoomMonthssorted(roomMonths.map(e => e.id).sort((a, b) => a - b))
  }, [roomMonthsNY])

  // // This function adds a new room to firebase.
  const addRoom = () => {

    let guestx = {}

    guestx.name = ""
    guestx.id = Number()
    guestx.idType = ""
    guestx.email = ""
    guestx.phone = Number()
    guestx.nationality = ""
    guestx.checkinDate = ""
    guestx.checkoutDate = ""
    guestx.payment = ""

    let guestxx = new Array(guestx)

    setDoc(doc(db, `clients/${customer}/rooms`, id), { //id is for label each room formaly, like: room1, room2, room25.
      roomname: roomname, //  fantasy name for room, like: "SeaView 1", "LakeView 5", "Royal Suite".
      description: description, // brief description of the room. 
      beds: Number(beds), // number of beds. Just as information. Reservation beds will be below, at days collection.
      type: type, // shared or single, double, triple...
      enabled: true, // to show or not at the page.
      price: Number(price), // price expressed in numbers.
      size: size, // size expressed in meters2.
      img: null, // cover img.
      img1: null, // room img.
      img2: null, // room img.
      img3: null, // room img.
      img4: null, // room img.
      img5: null, // room img.
      img6: null, // room img.
      img7: null, // room img.
      img8: null, // room img.
      img9: null, // room img.
      guest1: guestxx,
      guest2: guestxx,
      guest3: guestxx,
      guest4: guestxx,
      guest5: guestxx,
      guest6: guestxx,
      guest7: guestxx,
      guest8: guestxx,
      guest9: guestxx,
      guest10: guestxx,
    })

    setTimeout(() => {
      rerender()
    }, 500);

  }



  const addMonth = () => { // This function adds the month to the calendar in firebase.
    if ((type === "compartida" || type === "parcela") && (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12)) {

      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": beds, "d2": beds, "d3": beds, "d4": beds, "d5": beds, "d6": beds, "d7": beds, "d8": beds, "d9": beds, "d10": beds, "d11": beds, "d12": beds, "d13": beds, "d14": beds, "d15": beds, "d16": beds, "d17": beds, "d18": beds, "d19": beds, "d20": beds, "d21": beds, "d22": beds, "d23": beds, "d24": beds, "d25": beds, "d26": beds, "d27": beds, "d28": beds, "d29": beds, "d30": beds, "d31": beds
        }
        )
      }, 1000);
    } if ((type === "compartida" || type === "parcela") && (month == 4 || month == 6 || month == 9 || month == 11)) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": beds, "d2": beds, "d3": beds, "d4": beds, "d5": beds, "d6": beds, "d7": beds, "d8": beds, "d9": beds, "d10": beds, "d11": beds, "d12": beds, "d13": beds, "d14": beds, "d15": beds, "d16": beds, "d17": beds, "d18": beds, "d19": beds, "d20": beds, "d21": beds, "d22": beds, "d23": beds, "d24": beds, "d25": beds, "d26": beds, "d27": beds, "d28": beds, "d29": beds, "d30": beds
        }
        )
      }, 1000);
    } if ((type === "compartida" || type === "parcela") && month == 2) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": beds, "d2": beds, "d3": beds, "d4": beds, "d5": beds, "d6": beds, "d7": beds, "d8": beds, "d9": beds, "d10": beds, "d11": beds, "d12": beds, "d13": beds, "d14": beds, "d15": beds, "d16": beds, "d17": beds, "d18": beds, "d19": beds, "d20": beds, "d21": beds, "d22": beds, "d23": beds, "d24": beds, "d25": beds, "d26": beds, "d27": beds, "d28": beds, "d29": beds
        }
        )
      }, 1000);
    }
    if ((type !== "compartida" && type !== "parcela") && (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12)) {

      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": true, "d2": true, "d3": true, "d4": true, "d5": true, "d6": true, "d7": true, "d8": true, "d9": true, "d10": true, "d11": true, "d12": true, "d13": true, "d14": true, "d15": true, "d16": true, "d17": true, "d18": true, "d19": true, "d20": true, "d21": true, "d22": true, "d23": true, "d24": true, "d25": true, "d26": true, "d27": true, "d28": true, "d29": true, "d30": true, "d31": true
        }
        )
      }, 1000);
    } if ((type !== "compartida" && type !== "parcela") && (month == 4 || month == 6 || month == 9 || month == 11)) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": true, "d2": true, "d3": true, "d4": true, "d5": true, "d6": true, "d7": true, "d8": true, "d9": true, "d10": true, "d11": true, "d12": true, "d13": true, "d14": true, "d15": true, "d16": true, "d17": true, "d18": true, "d19": true, "d20": true, "d21": true, "d22": true, "d23": true, "d24": true, "d25": true, "d26": true, "d27": true, "d28": true, "d29": true, "d30": true
        }
        )
      }, 1000);
    } if ((type !== "compartida" && type !== "parcela") && month == 2 && year != 2024 && year != 2028 && year != 2032 && year != 2036) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": true, "d2": true, "d3": true, "d4": true, "d5": true, "d6": true, "d7": true, "d8": true, "d9": true, "d10": true, "d11": true, "d12": true, "d13": true, "d14": true, "d15": true, "d16": true, "d17": true, "d18": true, "d19": true, "d20": true, "d21": true, "d22": true, "d23": true, "d24": true, "d25": true, "d26": true, "d27": true, "d28": true, "d29": true
        }
        )
      }, 1000);
    } if ((type !== "compartida" && type !== "parcela") && month == 2 && (year == 2024 || year == 2028 || year == 2032 || year == 2036)) {
      setTimeout(() => {
        const roomRef = doc(db, `clients/${customer}/rooms`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": true, "d2": true, "d3": true, "d4": true, "d5": true, "d6": true, "d7": true, "d8": true, "d9": true, "d10": true, "d11": true, "d12": true, "d13": true, "d14": true, "d15": true, "d16": true, "d17": true, "d18": true, "d19": true, "d20": true, "d21": true, "d22": true, "d23": true, "d24": true, "d25": true, "d26": true, "d27": true, "d28": true
        }
        )
      }, 1000);
    }

    setTimeout(() => {
      rerender()
    }, 500);
  }



  const [loginAnimation, setLoginAnimation] = useState(false)
  const [loginAnimation2, setLoginAnimation2] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])

  
  return (
    <>
      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
          <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

          <Login customer={customer} />
        </div>
        :

        <>
          <div className='dashboardCont'>
            <NavbarPanel customer={customer} />
            <p className='sectionTittles'>| NEW ROOMS ROOM </p>

            <div className='column center'>

              <Link className='link navbarList' to={`/${customer}/dashboard/rooms`}>
                <Backwardsbutton text="⬸ Regresar" />
              </Link>

              <div>
                <p className='roomsEditCont-title'>HABITACIONES YA REGISTRADAS</p>
                <div className='row'>
                  {rooms.length != 0 ? rooms.map((room) => 
                  
            
                    <div className='roomitem'>
                      {room.id}
                    </div>


                  ) : <></>}
                </div>


              </div>

              <div className='roomeditCard'>

                <form className='RoomEditform' action="" onSubmit={handleSubmit}>

                    <div className='noShow'>
                          <AddRoomInput type="text" name="id" value={newroomid} onchange={(e) => setId(e.target.value)} />
                    </div>
                  <AddRoomInput type="text" name="Nombre" place="Nombre de fantasía como: Vista al lago I, Vista al bosque..." onchange={(e) => setRoomname(e.target.value)} />


                  <div className='inputRoomEditLabelCont'>
                    <label className='labelRoomEdit' htmlFor=""> Descripción</label>
                    <textarea className='RoomEditInput newcommenttextarea' placeholder="Breve descripción de la habitación." type="text" onChange={(e) => setDescription(e.target.value)} />
                  </div>


                  {/* <AddRoomInput textarea={true} type="text" name="Descripción" place="Breve descripción de la habitación." onchange={(e) => setDescription((e.target.value).toLowerCase())} /> */}

                  <AddRoomInput type="number" name="Camas/parcelas" place="Cantidad de camas/parcelas expresada en números." onchange={(e) => setBeds(e.target.value)} />

                  <p className='labelRoomEdit labelw100' >*Si está registrando un camping, sólo agregue una "habitación" y elija aquí arriba la cantidad de parcelas disponibles. </p>

                  <AddRoomInput type="text" name="tipo" place="compartida, simple, doble, triple, parcela" onchange={(e) => setType(e.target.value)} />

                  <AddRoomInput type="number" name="Precio" place="Precio expresado en números." onchange={(e) => setPrice(e.target.value)} />

                  <AddRoomInput type="text" name="Tamaño" place="size expressed in meters2." onchange={(e) => setSize(e.target.value)} />





                  <button className='addRoomButton' type='submit' onClick={() => {
                    setIngresado(true); addRoom()
                  }}> AGREGAR HABITACIÓN </button>

                  <button className='roomsEditCont-title buttonMonths' onClick={() => {
                    openMonthEditor(); getMonths()
                  }}>AGREGAR MESES AL CALENDARIO</button>


                  {openMonthEditorSwitch === false ? <></> :
                    <div>
                      <AddRoomInput type="number" name="Año" place="Año" onchange={(e) => setYear(e.target.value)} />
                      <AddRoomInput type="number" name="Mes" place="Mes expresado en número" onchange={(e) => setMonth(e.target.value)} />

                      <button className="addRoomButton" onClick={addMonth}> Agregar mes </button>
                      <p className='roomsEditCont-title'>MESES HABILITADOS</p>
                      <div>
                        <p className='roomsEditCont-title buttonMonths'>{thisyear}</p>
                        <div className='row'>
                          {roomMonths.length > 0 ?
                            <>
                              {roomMonthssorted.map((e) =>
                                <p className='monthItem'> {e} </p>
                              ).sort((a, b) => a - b)}
                            </> : <></>
                          }
                        </div>

                        <div>
                          <p className='roomsEditCont-title buttonMonths'>{thisyear + 1}</p>
                          <div className='row'>
                            {roomMonthsNY.length > 0 ?
                              <>
                                {roomMonthsNYsorted.map((e) =>
                                  <p className='monthItem'> {e} </p>
                                ).sort((a, b) => a - b)}
                              </> : <></>
                            }
                          </div>
                        </div>
                      </div>
                    </div>}
                </form>
              </div>
            </div>
          </div>
        </>}</>
  )
}
