import React, { useState, useEffect } from 'react';

export default function BookAddCalendar({ year, month, day, serviceMonth, selectDate }) {





  const [daysAvail, setDaysAvail] = useState()
  const [dayChosed, setDayChosed] = useState(false)
  const [forbiddenDay, setForbiddenDay] = useState(null)
  const [dayMouseOver, setDayMouseOver] = useState(false)
  // const [availability, setAvailability] = useState([])

  const a = "d" + day



  useEffect(() => {
    var newDay = new Date();
    var today = newDay.getDate();
    var currentMonth = newDay.getMonth() + 1;
    var currentYear = newDay.getFullYear();


    if (today > day && currentMonth === month && currentYear === year) {
      setForbiddenDay(true)

    } else {
      setForbiddenDay(false)
    }
  }, [month])




  const [dayOfTheWeek, setDayOfTheWeek] = useState(null)

  useEffect(() => {
    const fecha = new Date(`${year}-${month}-${day}`);

    setTimeout(() => {
      setDayOfTheWeek(fecha.toString()[0] + fecha.toString()[1] + fecha.toString()[2])
    }, 200);


  }, [year, day, month])





  const mouseOver = () => {
    setDayMouseOver(true)
  }

  const mouseLeave = () => {
    setDayMouseOver(false)
  }

  const [ThisDayDates, setThisDayDates] = useState(null)




  const dayPlusD = "d" + day
  useEffect(() => {

    if (serviceMonth !== null && serviceMonth.length > 0) {
      if (dayPlusD in serviceMonth[0]) {
        setThisDayDates(serviceMonth[0][dayPlusD])
      } else {
        setThisDayDates(null)
      } 
    } else {
      setThisDayDates(null)
    }


  }, [serviceMonth, month])


  const [checkAvail, setCheckAvail] = useState(null)
  useEffect(() => {

    if (ThisDayDates !== null) {
   
      const myArray = Object.entries(ThisDayDates).map(([key, value]) => (value));
      setCheckAvail(myArray)
    } else {
      setCheckAvail(null)
    }
  }, [ThisDayDates, month])

  const [avail, setAvail] = useState(null)

  useEffect(() => {
    if (checkAvail !== null) {

      const myArray = checkAvail.some(e => e === true);

      setAvail(myArray)

    } else {
      setAvail(false)
    }
  }, [checkAvail, forbiddenDay, month])



  const [showup, setShowup] = useState(false)

  const showupF = () => {
    if (dayChosed === true) {
      setDayChosed(false)
    } else {
      setDayChosed(true)
    }
  }

  const unselect =() => {
    setDayChosed(false)
  }

  return (

    /// USER CALENDAR

    <>

                  <button className={dayChosed === true ? 'daybox dayboxColor' : 'daybox'} onClick={() => {
                   selectDate(day, month, year)
                  }} onMouseEnter={mouseOver} onMouseLeave={mouseLeave} >

                    <span >
                      {day} </span> </button>


        </>



  )

}