import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className="footerRow barlow">
        <div className="footerSubColumn">
          <p className="footerTextRow">
            {/* <img
              width="20"
              height="20"
              src="https://img.icons8.com/ios-filled/ffffff/100/map-pin.png"
              alt="map-pin"
            /> */}
            nuevo COQUEstudio! 59 #993 e/ 14 y 15{" "}

              <b>- otracasaCLUB.</b>
          
          </p>

          <div className="row ">
            <img
              className="footerLogo"
              src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/coquewhite.png?alt=media&token=6cf7da91-f830-4dbb-9a2b-d887f89068e3"
            />
            <img
              className="footerLogo"
              src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/otracasa.png?alt=media&token=a5417d82-264e-47a4-82f4-839d02926374"
            />
          </div>
        </div>
        <iframe
          className="footerMap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.172126094187!2d-57.95339012437862!3d-34.92721987283999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a2e6287af50f8d%3A0xd6699880182754c1!2sC.%2059%20993%2C%20B1900BTM%20La%20Plata%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1709729947063!5m2!1ses-419!2sar"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="footerRow2">
        {/* <Link
          className="footerWhatsappText"
          to="https://api.whatsapp.com/send/?phone=5492213990248&text&type=phone_number&app_absent=0"
        >
          <img
            className="whatsappLogo"
            src="https://img.icons8.com/ios/50/76d273/whatsapp--v1.png"
          />
          <p className="footerTextItem">
            si tenés alguna duda o inquietud sobre el sistema, escribime!
          </p>
        </Link> */}
        {/* <img
          className="footerDivider"
          src="https://img.icons8.com/ios-filled/100/ffffff/barber-scissors.png"
        /> */}
        <Link
          className="footerTextItem "
          to="https://tomitorresdev.com.ar/turnos"
        >
          Quiero mi sitio de turnos
        </Link>
        {/* <img
          className="footerDivider"
          src="https://img.icons8.com/ios-filled/100/ffffff/barber-scissors.png"
        /> */}
        <Link
          className="footerTextItem "
          to="https://tomitorresdev.com.ar/turnos"
        >
          Desarrollado por tomitorresdev.com.ar
        </Link>
      </div>
    </footer>
  );
}
