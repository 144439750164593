import React, { useState, useEffect } from 'react';
import { collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'
import NavbarPanel from '../NavbarPanel'
import { useParams } from 'react-router-dom';
import RoomsEditInput from '../roomsEdit/RoomsEditInput';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';

export default function ProfileCont() {
    const db = getFirestore()
    const { customer } = useParams()

    const [edited, setEdited] = useState(false)
    const [loader, setLoader] = useState(true)

    const [profileData, setProfileData] = useState()
    const [personalData, setPersonalData] = useState()

    const [newName, setNewName] = useState()
    const [newAddress, setNewAddress] = useState()
    const [newEmail, setNewEmail] = useState()
    const [newCuit, setNewCuit] = useState()
    const [newusername, setNewusername] = useState()
    const [newpassword, setNewpassword] = useState()

    const { loginUsers, loginCheck } = useLoginContext()



    const handleSubmit = (event) => {
        event.preventDefault();
    }
    useEffect(() => {

        const QueryCollection = collection(db, `clients/${customer}/profile`)

        getDocs(QueryCollection)
            .then(resp => setProfileData(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
            .catch((err) => console.log(err))


    }, [customer, edited])

    const dataLoad = (a) => {
        return new Promise((resolve,reject) => {
            if(a === undefined || a === []) {
                reject()
            } else {
                resolve()
            }
        })
    }
    
    useEffect(() => {

        dataLoad(profileData)
        .then(() => {
            const data = profileData.find(e => e.id == "data")
            setPersonalData(data)
        })
        .catch(err => err)

    }, [profileData])


    const loaderPromise = new Promise((resolve, reject) => {
        setTimeout(() => { resolve([]); }, 2000);
    });

    loaderPromise.then(() => {
        setLoader(false);
    });

    const dataArr = ["data"]
    const loginArr = ["login"]
    const editProp = async (a, b, c) => {

        const batch = writeBatch(db)

        const queryCol = collection(db, `clients/${customer}/profile`)
        const queryRooms = await query(
            queryCol,
            where(documentId(), 'in', c.map(e => e)))

        await getDocs(queryRooms)
            .then(resp => resp.docs.forEach(((res) => {
                batch.update(res.ref, { [a]: b })
            })))

        await batch.commit()

        if (edited === true) {
            setEdited(false)
        } else {
            setEdited(true)
        }
    }


    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)


        }, 2000);
    }, [])


    return (
        <>
            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
                    <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

                    <Login customer={customer} />
                </div>
                :
                <>
                    <div className='dashboardCont'>
                        <NavbarPanel customer={customer} />
                        <p className='sectionTittles'>| PROFILE ROOM </p>

                        {loader === true ?

                            <div className='weekcalendarloader'>
                                <LineWobble
                                    size={80}
                                    lineWeight={5}
                                    speed={1.75}
                                    color="#576F72" />
                            </div>
                            :

                            <>
                                <div className='roomeditCard'>

                                    <form className='RoomEditform' action="" onSubmit={handleSubmit}>
                                        <p className='profileName'> {personalData.name[0]} {personalData.name[personalData.name.length - 1]} </p>

                                        <RoomsEditInput name="nombre" data={personalData.name} type="text" onchange={(e) => setNewName(e.target.value)} edit={editProp} newData={newName} prop="name" col={dataArr} />

                                        <RoomsEditInput name="dirección" data={personalData.address} type="text" onchange={(e) => setNewAddress(e.target.value)} edit={editProp} newData={newAddress} prop="address" col={dataArr} />

                                        <RoomsEditInput name="email" data={personalData.email} type="text" onchange={(e) => setNewEmail(e.target.value)} edit={editProp} newData={newEmail} prop="email" col={dataArr} />

                                        <RoomsEditInput name="CUIT/DNI" data={personalData.cuit} type="text" onchange={(e) => setNewCuit(e.target.value)} edit={editProp} newData={newCuit} prop="cuit" col={dataArr} />

                                        <p className='profileTittle'>Login</p>

                                        <RoomsEditInput name="Usuario" data={loginUsers.username} type="text" onchange={(e) => setNewusername(e.target.value)} edit={editProp} newData={newusername} prop="username" col={loginArr} />

                                        <RoomsEditInput name="Contraseña" data={loginUsers.password} type="text" onchange={(e) => setNewpassword(e.target.value)} edit={editProp} newData={newpassword} prop="password" col={loginArr} />

                                    </form>
                                </div>
                            </>
                        }
                    </div>
                </>}</>
    )
}
