import React, { useState, useEffect } from 'react'

export default function MessagesItem({ name, phone, fulldate, email, timestamp, hour, text }) {
  const date = new Date();

  const [dayspassed, setDayspassed] = useState(Number)


  const monthspassed = (date.getMonth() + 1) - fulldate.month
  const yearspassed = (date.getFullYear()) - fulldate.year


  const hourspassed = (date.getHours() - Number(hour.substring(0, 2))) + ((dayspassed + (monthspassed * 30.5) + (yearspassed * 365)) * 24)


  const week = parseInt(hourspassed / (7 * 24))
  const month = parseInt(hourspassed / (30 * 24))
  const year = parseInt(hourspassed / (365 * 24))

  useEffect(() => {
    const days = date.getDate() - fulldate.day

    if (days >= 0) {
      setDayspassed(days)
    } else {
      setDayspassed(days + 30)
    }


  }, [])

  return (
    <div className='dashboardBookOrder'>
      <div className='bookDataTop'>
        <div className='messageDataTopLeft'>
          <p className='clientMessageText'>
            {name.toUpperCase()}
          </p>
        </div>


        <div className='hourTextCont'>
          {hourspassed <= 24 ? <p className='minitext'> {hourspassed} hour/s ago</p> : <></>}
          {dayspassed > 0 && dayspassed <= 7 ? <p className='minitext'> {dayspassed} day/s ago</p> : <></>}
          {week > 1 && week <= 4 ? <p className='minitext'> {week} week/s ago</p> : <></>}
          {month > 1 && month <= 12 ? <p className='minitext'> {month} month/s ago</p> : <></>}
          {year > 1 ? <p className='minitext'> {year} year/s ago</p> : <></>}
        </div>
      </div>

      <div className='messageTextCont'>

        <p> {text} </p>

      </div>

      <div className='dateData'>
        {/* REPLY WHATSAPP */}
        <button className='actionButton' onClick={() => window.location = `https://wa.me/${Number(phone)}`}>
          <img className='actionImg' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
        </button>
        {/* REPLY BY PHONE */}
        <button className='actionButton' onClick={() => window.location = `tel:${Number(phone)}`}>
          <img className='actionImg' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
        </button>

        {/* REPLY BY EMAIL  */}

        <button className='actionButton' onClick={() => window.location = `mailto: ${email}`}>
          <img className='actionImg' src="https://img.icons8.com/material-rounded/96/000000/reply.png" />
        </button>
      </div>
    </div>
  )
}
