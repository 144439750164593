import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { useRoomsContext } from '../../context/RoomsContext'
import NavbarPanel from '../NavbarPanel';
import RoomsEditDetail from './RoomsEditDetail';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';

export default function RoomsEditDetailCont() {

    const { customer, roomedit } = useParams()
    const { services, contextRooms, rerender } = useRoomsContext()
    const [exists, setExists] = useState()
    const [id, setId] = useState()
    const [roomname, setRoomname] = useState("")
    const [description, setDescription] = useState("")
    const [enabled, setEnabled] = useState(true)
    const [img, setImg] = useState()
    const [monthAdded, setMonthAdded] = useState(false)
    const [render, setRender] = useState(false)
    const { loginCheck } = useLoginContext()

    const renderMonthAdded = () => {
        if (monthAdded === true) {
            setMonthAdded(false)
        } else {
            setMonthAdded(true)
        }
    }



    //TOMA EL ID DE LA ORDEN Y GUARDA UN ESTADO QUE CONTIENE SOLO LA ORDEN CORRESPONDIENTE AL ID y SUS ITEMS

    useEffect(() => {
        let roomfind = services.find(roo => roo.id == roomedit)
        let roommap = services.map((roo) => roo.id)


        if (roomfind === undefined) {
            return setExists("Doesn't exists")
        } else {
            return setExists("Exists"), setId(roomfind.id),  setDescription(roomfind.description),  setEnabled(roomfind.enabled), setImg(roomfind.img)
        }
    }, [services, render])


    const renderize = () => {
        if (render === true) {
            setRender(false)
        } else {
            setRender(true)

        }

    }

    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)

        }, 2000);
    }, [])


    return (
        <>
            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>

                    <Login />
                </div>
                :
                <>
                    <div className='dashboardCont'>
                        <NavbarPanel />
                        <p className='sectionTittles'>| EDITING ROOM </p>

                        <RoomsEditDetail render={renderize} renderized={render}  services={services} id={id} roomname={roomname} description={description} enabled={enabled} img={img} rerender={rerender} />
                    </div>
                </>}</>
    )
}
