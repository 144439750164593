import React, { useState, useEffect } from 'react';
import WeekCalendarItem from './WeekCalendarItem'
import ForWeekCalendarItem from './ForWeekCalendarItem';

export default function ForWeekCalendarList({ services, blockDay, daysInCurrentMonth, monthDays, daysLoop, year, month, today, todaysMonth, todaysYear, bookings, scroll, changeWeek, render, renderF, servicesSchedules, onScroll}) {


  const [todayBookings, setTodayBookings] = useState([])




  useEffect(() => {

    if (bookings.length > 0) {
      let todayFilter = bookings.filter(e => e.date === today + "/" + todaysMonth + "/" + todaysYear)
      setTodayBookings(todayFilter)
    } else {

    }

  }, [bookings, render])

  const [allDates, setAllDates] = useState([])
  const [allDatesW, setAllDatesW] = useState([])
  useEffect(() => {
    setAllDates([]) // Reiniciar el estado allDates a un array vacío
    setAllDatesW([])
    servicesSchedules.map((e) => {
      let y = e.docsData.filter(a => a.id == todaysMonth)
      let todayy = "d"+today
     
      let dates = y.length > 0 ? y[0][todayy] : undefined
      if(dates !== undefined) {
        setAllDates(prevDates => [...prevDates, Object.keys(dates)])
        setAllDatesW(prevDates => [...prevDates, [Object.keys(dates), e]]) // Actualizar el estado usando la función setAllDates
      }
    })
  }, [servicesSchedules, today, todaysMonth, render])

  const allDatesMerged = allDates.reduce((acc, curr) => {
    return acc.concat(curr);
  }, []);



  useEffect(() => {

    const newTodayBookings = [];
    for (let hora = 9; hora < 20; hora++) {
      for (let minuto = 0; minuto < 60; minuto += 15) {
        const horaFormateada = hora.toString().padStart(2, '0');
        const minutoFormateado = minuto.toString().padStart(2, '0');
        const horario = { "time": `${horaFormateada}:${minutoFormateado}`, "client": null, "item": null };
        newTodayBookings.push(horario);
      }
    }
    setTodayBookings(prevState => prevState.concat(newTodayBookings));

  }, [render])



  return (

    <>

      <div className='BookListWeekly'>

                 

        <div className='row relative'>



          <div className='weekorderCardCont darkblue w3rem' >
            <p className='weeklyOrderCardText whitetext'>HORA</p>
          </div>

          <div className='weekorderCardCont darkblue' >
            <p className='weeklyOrderCardText whitetext'>CLIENTE</p>
          </div>

 



        </div>

        {todayBookings && (
          todayBookings.filter((item, index, self) => {
            const firstIndex = self.findIndex((t) => t.time === item.time);
            return (firstIndex === index) || (item.client !== null);
          }).sort((a, b) => {
            if (a.time < b.time) {
              return -1;
            }
            if (a.time > b.time) {
              return 1;
            }
            return 0;
          }).map((e) =>
        
            <ForWeekCalendarItem key={e.id} {...e} render={render} allDatesMerged={allDatesMerged} blockDay={blockDay} today={today} todaysMonth={todaysMonth} todaysYear={todaysYear} allDatesW={allDatesW} renderF={renderF} services={services} servicesSchedules={servicesSchedules}   />
          )
        )}

      </div>

    </>
  )
}
