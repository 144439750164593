import React, { useState } from 'react';

export default function RefreshButton( {refreshFunction} ) {
    const [rotation, setRotation] = useState(false)

    const rotationF = () => {
    refreshFunction()
      setRotation(true)

      setTimeout(() => {
        setRotation(false)
      }, 2000);
    }

  return (
    <button onClick={rotationF} className={rotation === false ? 'refreshButtonCont' : 'refreshButtonCont bgChangingRotationF'}>
        <img className={rotation === false ? 'refresButtonImg' : 'refresButtonImg rotationF'} src="https://img.icons8.com/ios-filled/100/ffffff/refresh--v1.png" alt="refresh--v1"/>
    </button>
  )
}
