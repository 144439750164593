import React from 'react'
import { Link } from 'react-router-dom';

export default function BookingsWidgetItem({client, time}) {

  return (
    <Link className='BookingsWidgetItemCont'>
       {time} - {client.name}
    </Link>
  )
}
