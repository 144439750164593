import React, { useState } from 'react';
import EditButtonRooms from './buttons/EditButtonRooms'

export default function RoomsEditInput({ prop, name, type, data, onchange, edit, newData, col, textarea, options, widthM, renderMicroEdit }) {


  const [edition, setEdition] = useState(false)

  const editing = () => {
    if (edition === false) {
      setEdition(true)
    } else {
      setEdition(false)
    }
  }




  return (


    <div className='inputRoomEditLabelCont'>
      <label className='labelRoomEdit' htmlFor=""> {name.toUpperCase()} </label>

      {textarea === true ?
        <textarea className='RoomEditInput textareainput' name={name} type={type} onChange={onchange} value={edition === true ? null : data} />
        :

        <input className={widthM === true ? "RoomEditInput widthFitCont" : 'RoomEditInput'} name={name} type={type} onChange={onchange} value={edition === true ? null : data} />


      }

      {widthM === true ? <p className='minitext'>%</p> : <></>}

      <EditButtonRooms col={col} edit={edit} newData={newData} prop={prop} editing={editing} />
    </div>

  )
}
