import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { Link } from 'react-router-dom';

export default function RequestItem({ id, date, zone, timestamp, name, day, month, service, time, wa, ig, address }) {
    const [switchApprove, setSwitchApprove] = useState(true)
    const [switchText, setSwitchText] = useState(false)
    const [cancelling, setCancelling] = useState(false)

    const newdate = new Date();


    const cancellingUpdate = () => {
        setCancelling(true)
        setTimeout(() => {
            setCancelling(false)
        }, 2000);
    }




    const onSwitch = () => {
        if (switchApprove === false) {
            setSwitchApprove(true)
        } if (switchApprove === true) {
            setSwitchApprove(false)
        }
    }

    const hoverSwitchText = () => {
        if (switchText === false) {
            setSwitchText(true)
        } if (switchText === true) {
            setSwitchText(false)
        }
    }


    const mensajeDom = `hola ${name}! Soy Valu de la Coque. `;

    const baseUrl = "https://wa.me/";
    const phoneNumber = wa;
    const encodedMessageDom = encodeURIComponent(mensajeDom);
    const fullUrlDom = `${baseUrl}${phoneNumber}?text=${encodedMessageDom}`;

    // Dividir la cadena usando el signo igual como separador
    const partes = zone.split("=");

    // La segunda parte del array contendrá lo que está después del signo igual
    const splitZone = partes[1];

    return (


        <div className='dashboardBookOrder'>
            <div className='requestDataTop'>

                <p className='clientTextRequest'>
                    {name.toUpperCase()}
                </p>




            </div>


            {zone && (
                <>
                <b className='modalText modalMini'>Zona</b>
                <p className='modalText modalMini'>{splitZone}</p>
            </>
            )}



            {address && (
                
                <>
                <b className='modalText modalMini'>Dirección</b>
                <p className='modalText modalMini'>{address}</p>
            </>
            )}


            {service && (
                <>
                    <b className='modalText modalMini'>Servicio</b>
                    <p className='modalText modalMini'>{service}</p>
                </>
            )}


            {date && (
                <>
                    <b className='modalText modalMini'>Disponibilidad </b>
                    <p className='modalText modalMini'>{date}</p>
                </>
            )}



            <div className='dateData'>

                {/* <button className='actionButton' onClick={() => window.location = `mailto: ${client.email}`}>
            <img className='actionImg' src="https://img.icons8.com/ios-filled/100/000000/new-post.png" />
          </button> */}

<Link className='actionButton' to={`https://www.instagram.com/${ig}`}>
                    <img className='actionImg' src="https://img.icons8.com/ios-glyphs/90/instagram-new.png" />
                </Link>

                <button className='actionButton' onClick={() => window.location = `${fullUrlDom}`}>
                    <img className='actionImg' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
                </button>

                <button className='actionButton' onClick={() => window.location = `tel:${Number(wa)}`}>
                    <img className='actionImg' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
                </button>

            </div>


        </div>
    )
}


