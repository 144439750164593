import React, { useState, useEffect } from 'react';
import { setDoc, doc, collection, getFirestore, getDocs } from 'firebase/firestore';
import { useParams, useLocation } from 'react-router-dom';
import NavbarPanel from '../NavbarPanel';
import RoomsEditInput from '../roomsEdit/RoomsEditInput';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import { LineWobble } from '@uiball/loaders'
import { Link } from 'react-router-dom';

export default function PaymentsCont() {
    const db = getFirestore()
    const { customer, roomedit } = useParams()

    const [paymentData, setPaymentData] = useState([])
    const [conditiondata, setConditiondata] = useState()

    const [showBankData, setShowBankData] = useState()
    const location = useLocation()

    const [name, setName] = useState()
    const [cbu, setCbu] = useState()
    const [alias, setAlias] = useState()
    const [bankname, setBankname] = useState()

    const [condition, setCondition] = useState()
    const [editado, setEditado] = useState(false)
    const [newname, setNewname] = useState(name)
    const [newcbu, setNewcbu] = useState(cbu)
    const [newalias, setNewalias] = useState(alias)
    const [newbankname, setNewbankname] = useState(bankname)
    const { loginCheck, planCheck } = useLoginContext()


    const [loader, setLoader] = useState(true)


    const loaderPromise = new Promise((resolve, reject) => {
        setTimeout(() => { resolve([]); }, 2000);
    });

    loaderPromise.then(() => {
        setLoader(false);
    });

    useEffect(() => {
        const queryCol = collection(db, `profile`)

        getDocs(queryCol)
            .then(resp => setPaymentData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
            .catch((err) => console.log(err))
    }, [customer, conditiondata])


    const dataLoad = (a) => {
        return new Promise((resolve,reject) => {
            if(a === undefined || a === []) {
                reject()
            } else {
                resolve()
            }
        })
    }
    


    useEffect(() => {



        const bankData = paymentData.find(pay => pay.id == "bankdata")
        const conditionData = paymentData.find(pay => pay.id == "paymentcondition")

        dataLoad(conditionData).then(() => {

            setConditiondata(conditionData.condition)
        })
        .catch(error => error)

        dataLoad(bankData).then(() => {
            setName(bankData.name)
            setCbu(bankData.cbu)
            setAlias(bankData.alias)
            setBankname(bankData.bankname)

        })
        .catch(error => error)
  

    }, [paymentData])



   

    const handleSubmit = (event) => {
        event.preventDefault();
    }



    const editProp = async (a, b) => {

        setDoc(doc(db, `profile`, `bankdata`), {
            name: newname === undefined ? name : newname,
            cbu: newcbu === undefined ? cbu : newcbu,
            alias: newalias === undefined ? alias : newalias,
            bankname: newbankname === undefined ? bankname : newbankname

        })

    }

    const chooseCondition = (a) => {
        setCondition(a)
    }

    const setPaymentCondition = async (a) => {
  setEditado(true)
        setDoc(doc(db, `profile`, "paymentcondition"), {
            condition: condition
        })

        setTimeout(() => {
            window.location.reload(true)
        }, 2000);

      

        setTimeout(() => {
          setEditado(false)
   
        }, 1000);
    }





    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)


        }, 2000);
    }, [])


    return (
        <>
            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>

                    <Login customer={customer} />
                </div>
                :
                <>
                    <div className='dashboardCont'>
                        <NavbarPanel customer={customer} />
                        <p className='sectionTittles'>| MÉTODOS DE PAGO </p>

                        {loader === true ? <div className='weekcalendarloader'>
                            <LineWobble
                                size={80}
                                lineWeight={5}
                                speed={1.75}
                                color="#0010d5" />
                        </div> :

                            <>


                                <div className='paymentscont'>
                                    <p className='mercadopagoTittle'>Datos bancarios</p>
                                    <div className='row center'>

                                

                              
                                     

                                        <div className='paymentItem column w100' >
                                            <button className="ButtonNone" >
                                                <img className='checkOutImgSmall' src="https://img.icons8.com/ios-glyphs/90/000000/museum.png" alt="" />
                                            </button>

                                            <form className="RoomEditform" action="" onSubmit={handleSubmit}>
                                                <RoomsEditInput name="name" type="text" data={name} text={name} onchange={(e) => setNewname(e.target.value)} edit={editProp} newData={newname} prop="name" />
                                                <RoomsEditInput name="cbu" type="text" data={cbu} text={cbu} onchange={(e) => setNewcbu(e.target.value)} edit={editProp} newData={newcbu} prop="cbu" />
                                                <RoomsEditInput name="alias" type="text" data={alias} text={alias} onchange={(e) => setNewalias(e.target.value)} edit={editProp} newData={newalias} prop="alias" />
                                                <RoomsEditInput name="bankname" type="text" data={bankname} text={bankname} onchange={(e) => setNewbankname(e.target.value)} edit={editProp} newData={newbankname} prop="bankname" />
                                            </form>
                                        </div>

                                    </div>
                                </div>



                                {/* <div className='paymentscont'>
                                    <p className='mercadopagoTittle'> Elegí cómo cobrar </p>

                               

                                            <div className='paymentItem column w50'>

                                                <p className='paymentsText'>Seleccioná entre estas opciones <b>el porcentaje</b> que deseás cobrarle a tus clientes al momento de la reserva.</p>
                                                <div className='row center flexwrap'>

                                                    <button className={conditiondata === null ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(null)
                                                    }}>
                                                        0%
                                                    </button>

                                                    <button className={conditiondata === 10 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(10)
                                                    }}>
                                                        10%
                                                    </button>

                                                    <button className={conditiondata === 4 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(4)
                                                    }}>
                                                        25%
                                                    </button>

                                                    <button className={conditiondata === 2 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(2)
                                                    }}>
                                                        50%
                                                    </button>

                                                    <button className={conditiondata === 1 ? 'paybutton paybuttonselected' : 'paybutton'} onClick={() => {
                                                        chooseCondition(1)
                                                    }}>
                                                        100%
                                                    </button>
                                                </div>

                                                <button className='button2 margintop' onClick={() => {
                                                    setPaymentCondition()
                                                }}>CONFIRMAR</button>

{editado === false ? <span></span> : <span className='editDone button2'> ¡LISTO!</span>}


                                            </div>
                                



                                </div> */}

                            </>}






                    </div>


                </>} </>
    )
}
