import React, { useState, useEffect } from 'react'

import {
    collection,
    getFirestore,
    getDocs,
    writeBatch,
    where,
    query,
    documentId,
  } from "firebase/firestore";
import { useCalendarContext } from '../context/CalendarContext'
import { useBookingContext } from '../context/BookingContext'
import { useRoomsContext } from '../context/RoomsContext'
import { Link } from 'react-router-dom';
import NewCalendarListCont from './NewCalendarListCont';
import CancelButton from '../microsites/CancelButton';

export default function NewCalendarCont({ asesoria, toHomeF, asesoriaTrue }) {
    const db = getFirestore()
    const { dateList,  addToDates, availDaysForDetail, resetAvailDaysDetail } = useCalendarContext()
    const { services, render, rerenderF } = useRoomsContext()
    const { bookings, rerender } = useBookingContext()

    const [serviceChoose, setServiceChoose] = useState(null)

    const [month, setMonth] = useState()
    const [year, setYear] = useState()
    const [serviceYear, setServiceYear] = useState(null)
    const [serviceMonth, setServiceMonth] = useState(null)
    const [currentMonth, setCurrentMonth] = useState(null)
    const [itemsData, setItemsData] = useState([])

    const [serviceData, setServiceData] = useState([])


    useEffect(() => {
        if (serviceChoose !== null) {
            let thisSe = services.filter(e => e.id == serviceChoose)
            setServiceData(thisSe)
        }

    }, [services, render, serviceChoose])


    useEffect(() => { // OK
        const queryCol = collection(db, `items`)

        getDocs(queryCol)
            .then(resp => setItemsData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
            .catch()

    }, [])


    const toZero = () => {
        setServiceChoose(null)
        setServiceYear(null)
        setServiceMonth(null)
        resetAvailDaysDetail()
        toHomeF()
        if (serviceChoose === "asesoria") {
            asesoriaTrue()
        }

    }
    useEffect(() => {


        const date = new Date();
        const yearF = date.getUTCFullYear()
        const monthF = date.getUTCMonth() + 1

        setYear(yearF)
        setMonth(monthF)
        setCurrentMonth(monthF)

    }, [])


    const dataLoad = (a) => {
        return new Promise((resolve, reject) => {
          if (a === undefined || a.length === 0 || a === "") {
            reject(setnoBookings(true));
          } else {
            resolve();
          }
        });
      };
    

    // const listaOrdenada = ['domiciliozona1', 'domiciliozona3', 'domiciliozona4', 'domiciliozona2'];

    // const compararIDs = (obj1, obj2) => {
    //     const index1 = listaOrdenada.indexOf(obj1.id);
    //     const index2 = listaOrdenada.indexOf(obj2.id);
    //     if (index1 < index2) {
    //         return -1;
    //     } else if (index1 > index2) {
    //         return 1;
    //     } else {
    //         return 0;
    //     }
    // }


    const [bookInput, setBookInput] = useState(false);
    const [dniForSearcher, setDniForSearcher] = useState("");
    const [bookFound, setBookFound] = useState("");
    const [showBooks, setShowBooks] = useState(false);
    const [noBookings, setnoBookings] = useState(false);
  
    const showBookInput = () => {
      if (bookInput === true) {
        setBookInput(false);
      } else {
        setBookInput(true);
      }
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
    };

    const closeSearcher = () => {
        setShowBooks(false);
        setBookInput(false);
      };
    

      const searchMyBook = async () => {
        let bookSearcher = bookings.filter((e) => e.client.dni == dniForSearcher);
    
        dataLoad(bookSearcher)
          .then(
            setBookFound([
              bookSearcher.reduce((anterior, actual) => {
                return anterior.timestamp > actual.timestamp ? anterior : actual;
              }),
            ])
          )
          .then(setnoBookings(false))
          .then(setShowBooks(true))
          .catch((err) => {
            console.log(err);
            if (err === undefined || err.length === 0 || err === "") {
              setnoBookings(true);
            }
          });
      };

      const cancel = async (a, b, c, d, f, g) => {
        const arr = [g.toString()];
    
        const queryCol = collection(db, `bookings/`);
    
        const batch = writeBatch(db);
        const updateId2 = await query(queryCol, where(documentId(), "in", arr));
    
        await getDocs(updateId2).then(
          (resp) =>
            resp.docs.forEach((res) => {
              batch.update(res.ref, { status: false });
            })
          // const updateData = { ['d' + dateList[1]]: { status: false } };
        );
        await batch.commit();
        a.forEach(async (e) => {
          const arr2 = [c.toString()];
    
          const queryCol = collection(db, `services/${b}/${d}/`);
    
          const batch = writeBatch(db);
          const updateId = await query(queryCol, where(documentId(), "in", arr2));
    
          await getDocs(updateId).then((resp) =>
            resp.docs.forEach((res) => {
              batch.update(res.ref, { ["d" + f + "." + e]: true });
            })
          );
          await batch.commit();
        });
    
        rerender();
      };

    useEffect(() => { // OK
        if (serviceChoose !== null) {
            const QueryCollection = collection(db, `services/${serviceChoose}/${year}`)
            getDocs(QueryCollection)
                .then(resp => setServiceYear(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
                .catch((err) => console.log(err))

        } else {

        }

    }, [serviceChoose, year, month])


    useEffect(() => {
        if (serviceYear !== null) {
            setServiceMonth(serviceYear.filter(e => e.id == month))
        } else {

        }
    }, [serviceYear])

    const     agregarParametroALaURL = (parametro, valor) => {
 // Obtener la URL actual
 var urlActual = new URL(window.location.href);

 // Crear un objeto URLSearchParams a partir de la URL
 var urlParams = urlActual.searchParams;

 // Agregar el nuevo parámetro o actualizar su valor si ya existe
 urlParams.set(parametro, valor);
 // Crear una nueva URL con los parámetros actualizados
 var nuevaURL = urlActual.origin + "/" + urlParams.toString();

 // Actualizar la URL en el navegador
 window.history.replaceState(null, null, nuevaURL);
    }
    



    const serviceElection = (a, b) => {
        setServiceChoose(a)
        if(b) {
            agregarParametroALaURL('zona', a)
        }
    }




    const siguiente = () => {   
        if(month == 12) {
            setMonth(1)
            setYear(year + 1)
        } else {
            setMonth(month + 1)
        }
   

    }
    const anterior = () => {
        if(month == 1) {
            setMonth(12)
            setYear(year - 1)
        } else {
            setMonth(month - 1)
        }
  

    }

    const promise = new Promise((resolve, reject) => {
        if (serviceData.length > 0) {
            resolve()
        } else {

        }
    });

    const [itemsByTime, setItemsByTime] = useState([])

    useEffect(() => {
        promise.then(() => {
            serviceData[0].items.forEach(e =>
                itemsByTime.push(itemsData.filter(a => a.id == e))
            )
        })

    }, [serviceData, itemsData, serviceChoose])

    useEffect(() => {
        if(asesoria === true) {
          setServiceChoose("asesoria")
          rerenderF()
        }
      }, [asesoria])
  
const isDatePastOrToday = (dateString) => {
    // Crear un objeto Date con la fecha de hoy y ajustar la hora a 00:00:00
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Convertir la cadena de texto a un objeto Date
    const [day, month, year] = dateString.split('/').map(Number);
    const inputDate = new Date(year, month - 1, day);
  
    // Comparar las fechas
    return inputDate <= today;
  }
    return (
        <div className='newCalendarCont'>
            {/* {serviceChoose === null ? <h1 className='micrositeTittle subtittleBogue'> en donde preferis? </h1> : <></>} */}

            {serviceChoose === null && !asesoria ? 
            
            <div className='newCalendarCol'>

                <button className='servicesButton' onClick={() => {
                    serviceElection("local")
                }}> COQUEstudio </button>

<button className='servicesButton' onClick={() => {
                    serviceElection("color")
                }}> color </button>
                
<div className={`BookSearcher ${bookInput ? "BookSearcherOpen" : ""}`}>
                        {bookInput === false ? (
                          <>
                            <button
                              onClick={showBookInput}
                              className="BookSearcherTittle"
                            >
                              mis reservas
                              <br />
                              <p className="cancelation">cancelaciones acá</p>
                            </button>
                           
                          </>
                        ) : (
                          <>
                                    <img
              className="logoNoBg"
              src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Flogo.jpg?alt=media&token=8d13d404-2cbf-48d9-8e14-578f9f4f5b74"
              alt="logo"
            />
                          <button
                                onClick={closeSearcher}
                                className="closeButtonSearcher off"
                              >
                                cerrar
                              </button>
                          <p   className="BookSearcherTittle textbigger">mis reservas</p>
                            <form action="" onSubmit={handleSubmit}>
                              <input
                                className="searcherInput"
                                type="text"
                                name="DNI"
                                placeholder="Ingresa tu DNI/ID"
                                onChange={(e) =>
                                  setDniForSearcher(e.target.value)
                                }
                              />
                              <button
                                className="searcherButton"
                                type="submit"
                                onClick={searchMyBook}
                              >
                                Buscar
                              </button>

                            </form>
                            {showBooks === false ? (
                              <> </>
                            ) : (
                              <>
                                {bookFound.map((e) => {
                                  return (
                                    <div className="bookFoundedCont" key={e.id}>
                                      <p className="bookFoundedText">
                                        {" "}
                                        <b>{e.client.name}</b>{" "}
                                      </p>
                                      <p className="bookFoundedText">
                                        {" "}
                                        Fecha: <b>{e.day}</b>/<b>{e.month}</b>/
                                        <b>{e.year}</b>{" "}
                                      </p>
                                      <p className="bookFoundedText">
                                        {" "}
                                        Hora: <b>{e.time}</b>{" "}
                                      </p>
                                      <p className="bookFoundedText">
                                        {" "}
                                        Duración: <b>
                                          {e.blocks * 15} minutos
                                        </b>{" "}
                                      </p>
                                      {e.service === "local" || e.service === "color"  ? (
                                        <p className="bookFoundedText">
                                          Dirección: <b>calle 59 993</b>,
                                          OtraCasaClub{" "}
                                        </p>
                                      ) : (
                                        <></>
                                      )}

                                      {e.status !== null ? (
                                        <>
                                          {e.status === true ? (
                                            <div className="row fitcontent">
                                              <p className="bookFoundedText on">
                                                Estado: <b>OK</b>
                                              </p>
                                              
                                              {isDatePastOrToday(e.date) ? "" :   <CancelButton
                                                key={e.id}
                                                {...e}
                                                cancelOrder={cancel}
                                              /> }
                                            
                                            </div>
                                          ) : (
                                            <p className="bookFoundedText off">
                                              Estado: <b>CANCELADA</b>
                                            </p>
                                          )}
                                        </>
                                      ) : (
                                        <p className="bookFoundedText pending">
                                          Estado: <b>PENDIENTE</b>
                                        </p>
                                      )}
                                      {e.status === true ? (
                                        <p className="bookFoundedTextmini">
                                          Podés cancelar el turno hasta <br />
                                          un día antes, en caso contrario <br />
                                          <b>no se devolverá la seña</b>
                                        </p>
                                      ) : (
                                        <> </>
                                      )}
                                    </div>
                                  );
                                })}
                              </>
                            )}

                            {noBookings === true ? (
                              <p>No existen reservas con ese DNI/ID</p>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
{/* 
                <button className='servicesButton servicesButtonPeq' onClick={() => {
                    serviceElection("dom")
                }}> en tu casita </button> */}

                <Link className='bluegradient2 servicesButtonPeq' to="https://api.whatsapp.com/send/?phone=5492213990248&text&type=phone_number&app_absent=0">
                    por retoque de raíces o balayage consultame disponibilidad 
                    
                    <img className='whatsappLogoServicesButton' src="https://img.icons8.com/ios/50/000000/whatsapp--v1.png" />
                </Link>


            </div> : <></>}


            {serviceChoose === "dom" ?
                <>
                    <div className='column'> <h1 className='micrositeTittle subtittleBogue'> coordinemos un horario</h1> 
                    
                    <p className='micrositeDescription'> Hacé click abajo, completá el formulario y me pondré en contacto lo antes posible! temporalmente el servicio a domicilio solo será con esta modalidad.
                    </p> 

                    {/* <p className='micrositeDescription'>aprovechamos el cuadrado que es La Plata para divirlo en zonas de trabajo. fijate en cuál cae tu casa y elegí el cuadrante correspondiente.
                    </p>  */}
                    </div>

                    <Link to={`/contactos-turno?xxxx`}  className="chooseHaircutButton w20rem"> ✂ Enviar solicitud ✂ </Link> 


                    {/* <div className='mapServicesCont'>

                        <img className='serviceMapImg' src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Fmapa.png?alt=media&token=b5259e79-5893-44a8-97ac-f5c3cba63bb8" alt="" />

                        {services.filter(e => e.id !== "local" && e.id !== "asesoria").sort(compararIDs).map((a) =>
                            <button className='servicesMapButton' key={a.id} onClick={() => {
                                serviceElection(a.id, true)
                            }}> 🚲 </button>
                        )}
                    </div> */}
                </>
                :
                <></>
            }

            {serviceChoose !== null && serviceChoose !== "dom" ?
                <>
                    {/* <button onClick={toZero} className='button2'> VOLVER </button> */}


                    <NewCalendarListCont itemsByTime={itemsByTime} availDaysForDetail={availDaysForDetail} toZero={toZero} siguiente={siguiente} anterior={anterior} currentMonth={currentMonth} month={month} year={year} serviceChoose={serviceChoose} serviceMonth={serviceMonth} addToDates={addToDates} dateList={dateList}  />

                    {/* <BookList availDaysForDetail={availDaysForDetail} itemsByTime={itemsByTime} render={render} siguiente={siguiente} anterior={anterior} currentMonth={currentMonth} firstDay={firstDay} month={month} monthDays={monthDays} year={year} serviceChoose={serviceChoose} serviceMonth={serviceMonth} rerenderF={rerenderF} /> */}



                </> : <></>}


        </div>
    )
}
