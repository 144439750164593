import React, { useState } from 'react';
import { collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';

export default function Comment({ id, name, message, dateFrom, dateTo, rating, dashboard, state, customer, changed }) {
  const db = getFirestore()

  const [switchApprove, setSwitchApprove] = useState(true)
  const [cancelling, setCancelling] = useState(false)

  const onSwitch = () => {
    if (switchApprove === false) {
      setSwitchApprove(true)
    } if (switchApprove === true) {
      setSwitchApprove(false)
    }
  }



  const switchState = async () => {


    changeStatus()
    setCancelling(true)


    setTimeout(() => {

      setCancelling(false)

      setTimeout(() => {
        changed()
      }, 100);
    }, 2000);



  }

  const changeStatus = async () => {

    const arrId = [id]

    const batch = writeBatch(db)
    const queryCollection = collection(db, `comments`)

    const queryStatus = await query(
      queryCollection,
      where(documentId(), 'in', arrId.map((e) => e)))

    await getDocs(queryStatus)
      .then(resp => resp.docs.forEach(((res) => {
        if (state === false) {
          return batch.update(res.ref, { state: true })
        } else {
          return batch.update(res.ref, { state: false })
        }

      })))

    batch.commit()


  }

  return (
    <div className={dashboard === true ? 'commentCont commentDash' : 'commentCont'}>
    
        <img className='commentImg margin' src="https://img.icons8.com/external-others-inmotus-design/67/000000/external-Comment-comments-others-inmotus-design-3.png" alt="" />
      
      <p className='commentName'> <b>{name} </b></p>
      <p className='commentText'> {message} </p>


      {dashboard === true ?

        <>
          <div className='row'>
            <p className='commentName'> <b>{state === false ? 'Mostrar' : 'Dejar de mostrar'}</b> </p>
            <button className={state === false ? 'switch off' : 'switch on'} onClick={() => {
              onSwitch(); switchState()
            }}  >  <span className={cancelling === true ? "switchBallCancelling" : state === false ? 'switchBallOff' : 'switchBallOn'}>⬤</span> </button>

          </div>



        </> : <></>

      }
    </div>
  )
}
