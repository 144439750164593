import React, { useState, createContext, useContext, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, listAll, uploadBytes, getDownloadURL } from "firebase/storage";


const RoomsContext = createContext([])

export const useRoomsContext = () => useContext(RoomsContext)


const RoomsContextProvider = ({ children }) => {


    const db = getFirestore()
    const [services, setServices] = useState([])
    const [render, setRender] = useState(0)
    const [contextRooms, setContextRooms] = useState([])


    useEffect(() => { // OK
        const QueryCollection = collection(db, `services`)
        getDocs(QueryCollection)
            .then(resp => setServices(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
            .catch((err) => console.log(err))

    }, [render])




    const rerender = () => {
        setRender(render +1)
    }

    const rerenderF = () => {
            setRender(render +1)
    }




    const [allImages, setAllImages] = useState([])
    const [allImagesLinks, setAllImagesLinks] = useState([])

    const storage = getStorage();



    // // Create a reference under which you want to list
    // const listRef = ref(storage, 'clients/holdear/rooms/room1');

    // // Find all the prefixes and items.
    // listAll(listRef)
    //     .then((res) => {
    //         res.prefixes.forEach((folderRef) => {

    //             // All the prefixes under listRef.
    //             // You may call listAll() recursively on them.
    //         });
    //         res.items.forEach((itemRef) => {
    //             allImages.push(itemRef)
    //             // All the items under listRef.
    //         });
    //     }).catch((error) => {
    //         // Uh-oh, an error occurred!
    //     });



    // useEffect(() => {
    //     allImages.forEach(e => {
    //         const storageRef = ref(storage, `rooms/room1/${e.name}`)
    //         getDownloadURL(storageRef)
    //             .then(url => allImagesLinks.push(`${url}`))
    //             .catch((error) => { console.log(error) })
    //     } )

    // }, [allImages, contextRooms])


    return (
        <RoomsContext.Provider value={{
            contextRooms,
            services,
            rerender,
            rerenderF,
            allImagesLinks,
            render


        }}>
            {children}

        </RoomsContext.Provider>
    )

}

export default RoomsContextProvider




