
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function MiniCalendarItem({ unblockDay, serviceChosen, blockDay, allDatesMerged,   year, address, client, day, item, month, service, status, time, blocks, today, todaysMonth, todaysYear, allDatesW, servicesSchedules, render}) {


  const [forbiddenDay, setForbiddenDay] = useState(false)
  const [dayMouseOver, setDayMouseOver] = useState(false)
  const [availability, setAvailability] = useState(null)
  const [rrender, setRrender] = useState(0)

  const rrenderF = () => {
    setRrender(rrender+1)
  }

  const a = "d" + day

  const checkDate = async () => {

    var newDay = new Date();
    var today = newDay.getDate();
    var currentMonth = newDay.getMonth() + 1;
    var currentYear = newDay.getFullYear();



    if (today > day && currentMonth === month && currentYear === year) {
      setForbiddenDay(true)

      setTimeout(() => {
        setForbiddenDay(false)
      }, 2900);
    } else {

    }

  }

  // const mouseOver = () => {
  //   setDayMouseOver(true)
  // }

  // const mouseLeave = () => {
  //   setDayMouseOver(false)
  // }

  const [ThisDayDates, setThisDayDates] = useState(null)


  const [onHoverItem, setOnHoverItem] = useState(false)

  const onHoverItemIn = () => {
    setOnHoverItem(true)
  }

  const onHoverItemOut = () => {
    setOnHoverItem(false)
  }

  const color = service === ("local" || "asesoria") ? "ybg" : "gbg"

  const [exists, setExists] = useState(false)

  useEffect(() => {
    let existsF = allDatesMerged.includes(time[0] + time[1] + time[3] + time[4])

    if (existsF === true) {
      setExists(true)
    } else {
      setExists(false)
    }

  }, [allDatesMerged])

  const [serviceOfBlock, setServiceOfBlock] = useState(null)
  const [confirmar, setConfirmar] = useState(false)
  const [blocked, setBlocked] = useState(false)
  const [unblocked, setUnblocked] = useState(false)

  useEffect(() => {
    allDatesW.forEach((e) => {
      const findex = e[0].find(a => a == time[0] + time[1] + time[3] + time[4])
      if (findex !== undefined) {
        setServiceOfBlock(e[1].id)
      }
    })
  }, [allDatesW])
  


  useEffect(() => {



    if (servicesSchedules !== null && serviceOfBlock !== null) {
      let tod = "d" + today
      let tim = time[0] + time[1] + time[3] + time[4]
  
      let ll = servicesSchedules.filter(e => e.id === serviceOfBlock)
      let kk = ll[0].docsData.filter(e => e.id == todaysMonth)

 
      if(kk[0] !== undefined && kk[0][tod] !== undefined) {
        setAvailability(kk[0][tod][tim])
      } else {
        setAvailability(false)
      }

    } else {

    }
  }, [serviceOfBlock, servicesSchedules])



 const conf = () => {
  if(confirmar === true) {
    setConfirmar(false)
  } else {
    setConfirmar(true)
  }

 }

 const blockedF = () => {
  setBlocked(true)
 }

 const unblockedF = () => {
  setUnblocked(true)
 }

 const [showAddress, setShowAddress] = useState(false)

const showAddressF = () => {
  setShowAddress(true)

}

const closeAddressF = () => {
  setShowAddress(false)
}

const [showOrNot, setShowOrNot] = useState(false)

useEffect(() => {
 
  setShowOrNot(serviceChosen === service || serviceChosen === null || serviceChosen === "todos")

}, [serviceChosen])


const mensajeDom = client !== null ? `hola ${client.name}! Soy Valu de la Coque. el día ${day}/${month} a las ${time} tenemos turno en tu espacio.\n\n ✂ Me repetirías tu dirección así me queda por este medio? \n ✂ por favor, esperame con el pelo limpio o sin productos de finalizar (cremas o geles)\n ✂ siempre trato de ser puntual, pero por cuestiones de traslado entre casa y casa, el margen de demora puede ser de hasta 10 minutos  \n ✂ en caso de que necesites cancelar, avisando con más de 24 horas se reprograma o devuelve la seña. con menos tiempo, se pierde la seña y la posibilidad de programar nuevamente el turno.\n\n Muchas gracias y te veo en tu espacio :)`: null

const baseUrl = "https://api.whatsapp.com/send/?phone=";
const phoneNumber =  client !== null ? client.phone : null; 
const encodedMessageDom = encodeURIComponent(mensajeDom);
const fullUrlDom = `${baseUrl}${phoneNumber}&text=${encodedMessageDom}`;

const mensajeLocal=  client !== null ? `hola ${client.name}! soy valu de la coque. el día ${day}/${month} a las ${time} tenemos turno en el COQUEstudio:) 
\n\n 📍 La dirección es 59 #993 casi esquina 15 (espacio de OtraCasaclub). 
\n 〰️ la coqueteria esta en el piso de arriba. si tenes dificultades para subir escaleras ¡avisanos! que nos adaptamos a tu situación 
\n 👉🏻 venir con pelo limpio o sin productos de finalizar (cremas o geles) 
\n ⏰ xfi tratar de ser puntual asi no hacemos esperar a los siguientes clientes. 
\n 〰️ si venis en auto, considera el tiempo de estacionamiento! es una zona con poca disponibilidad 
\n 👉🏻 en caso de no poder asistir, avisando con mas de 24 hs se reprograma o devuelve la seña. con menos de ese tiempo, se pierde la seña y la posibilidad de programar de nuevo el turno. ➖no avisando o cancelandouna hora antes, se deberá abonar el valor completo del turno. 
\n  🤩 en el espacio de @otracasa_ funcionan algunos talleres y emprendimientos. también podes venir a merendar o tomar matecitos que la casa esta abierta al publico de 16 a 20hs . pasate cuando quieras a compartir el rato! 🥰
\n\n muchas gracias, te espero!
\n valu de @lacoque.teria 🙋🏼‍♀️`: null

const encodedMessageLocal = encodeURIComponent(mensajeLocal);
const fullUrlLocal = `${baseUrl}${phoneNumber}&text=${encodedMessageLocal}`;








  return (
    <>
 {client === null && exists === true && availability === true ?   
 
 
 <div className='row relative'>
      <div className="orderCardCont darkblue w3rem thinLine">
        <p className='orderCardTextMini whitetext'>{time}</p>
      </div>


    </div> : <></>

 

 }
</>
      

)
}

