import React, { useState } from 'react';


export default function EditButtonRooms({ confirmar, edit, newData, inputType, prop, editing, col }) {

  const [editar, setEditar] = useState(false)
  const [editado, setEditado] = useState(false)

  const editando = () => {
    if (editar === false) {
      setEditar(true)
    } else {
      setEditar(false)
      setEditado(true)

      setTimeout(() => {
        setEditado(false)
        editing()
      }, 1000);
    }
  }

  const editF = () => {
    edit(prop, newData, col)

    setTimeout(() => {
      
    }, 500);
  }



  return (



    <div className='buttonEditCont '>
      {editar === false ? <button className='button2' onClick={() => {
        editando(); editing()
      }}> EDITAR </button> :

        <button className='button2' type='submit' onClick={() => { editF(); editando() }}> CONFIRMAR </button>
      }

      {editado === false ? <span></span> : <span className='editDone button2'> ¡LISTO!</span>}
    </div>

  )
}
