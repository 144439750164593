import React, { useState, useEffect } from 'react';
import { setDoc, doc, addDoc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';

import { useParams } from 'react-router-dom';
import { useRoomsContext } from '../../context/RoomsContext'
import NavbarPanel from '../NavbarPanel';
import Login from '../login/Login';
import { Link } from 'react-router-dom';
import ScheduleButton from './buttons/ScheduleButton';
import Backwardsbutton from '../../buttons/Backwardsbutton';
import DaysButton from './buttons/DaysButton';
import ItemsEditButton from '../items/ItemsEditButton';
import ItemsSelectButton from '../items/ItemsSelectButton';
export default function Schedule() {


    const db = getFirestore()
    const { roomedit } = useParams()



    const [data, setData] = useState([])

    const [services, setServices] = useState([])
    const [thisService, setThisService] = useState([])
    const [thisServiceSchedule, setThisServiceSchedule] = useState([])
    const [thisServiceDays, setThisServiceDays] = useState([])


    const [render, setRender] = useState(0)
    const [items, setItems] = useState([])


    const renderF = () => {
        setRender(render + 1)
    }


    useEffect(() => {
        const QueryCollection = collection(db, `services`)

        getDocs(QueryCollection)
            .then(resp => setServices(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
            .catch((err) => console.log(err))
    }, [roomedit, render])




    useEffect(() => {
        const QueryCollection = collection(db, `items`)

        getDocs(QueryCollection)
            .then(resp => setItems(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
            .catch((err) => console.log(err))

    }, [render])



    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)


        }, 2000);
    }, [])













    return (




        <>
            <div className='dashboardCont'>
                <NavbarPanel />
                <p className='sectionTittles'>| ITEMS Y HORARIOS </p>



                <div className='sectionPanelCont'>
                    {roomedit && (
                        <p className='button'>Agenda: {roomedit.toUpperCase()} </p>
                    )}
                    <div className='schedulesCont'>

                        {items && (
                            items.map((e) =>
                                <ItemsSelectButton key={e.id} {...e} render={renderF} service={roomedit} services={services} />
                            )
                        )}
                    </div>


                    <Link className='navbarList' to={`/dashboard/${roomedit}`}>
                        <Backwardsbutton text="⬸ Regresar" />
                    </Link>
                </div>
            </div>
        </>



    )
}