import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { addDoc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'
import NavbarPanel from '../../NavbarPanel';
import WeekCalendarList from '../../calendar/WeekCalendarList';
import { useRoomsContext } from '../../../context/RoomsContext'
import { useBookingContext } from '../../../context/BookingContext'
import { useLoginContext } from '../../../context/LoginContext'; 

import moment from 'moment';
import RefreshButton from '../../widgets/RefreshButton';
import MiniCalendarList from './MiniCalendarList';
export default function MiniCalendarListCont() {
  const db = getFirestore()

  const { bookings } = useBookingContext()
  const { services, rerenderF } = useRoomsContext()


  useEffect(() => {

    if (services.length === 0) {
      rerenderF()
    }
  }, [services])

  const location = useLocation()
  const [loader, setLoader] = useState(true)
  const [serviceChosen, setServiceChosen] = useState("todos")
  const [firstDay, setFirstDay] = useState("")
  const [daysInCurrentMonth, setDaysInCurrentMonth] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()
  const [today, setToday] = useState()
  const [todaysMonth, setTodaysMonth] = useState()
  const [todaysYear, setTodaysYear] = useState()
  const [nextmonth, setNextmonth] = useState()
  const [monthDays, setMonthDays] = useState()
  const [daysLoop, setDaysLoop] = useState([])

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [render, setRender] = useState(0)

  const [thisDaySchedules, setThisDaySchedules] = useState([
  ])

  
  function dataLoaded(a) {
    return new Promise((resolve, reject) => {

      if(a === undefined || a.length === 0 || a === null) {
        reject("Not ok")
      } else {
        resolve("ok")
      }
    } )
    
  }

  const [servicesSchedules, setServicesSchedules] = useState([])

  useEffect(() => {
  
    const newServicesSchedules = services.map((service) => {
      const queryCollection = collection(db, `services/${service.id}/${todaysYear}`)
      return getDocs(queryCollection)
        .then((querySnapshot) => {
          const docsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          return { id: service.id, docsData }
        })
        .catch((err) => console.log(err))
    })
  
    Promise.all(newServicesSchedules).then((data) => setServicesSchedules(data))  

  }, [services, todaysYear, render])

  const [allDates, setAllDates] = useState([])
  const [allDatesW, setAllDatesW] = useState([])
  useEffect(() => {
    setAllDates([]) // Reiniciar el estado allDates a un array vacío
    setAllDatesW([])

    if(serviceChosen !== null && serviceChosen != "todos") {
      servicesSchedules.filter(e => e.id == serviceChosen).map((e) => {
        let y = e.docsData.filter(a => a.id == todaysMonth)
        let todayy = "d"+today
       
        let dates = y.length > 0 ? y[0][todayy] : undefined
        if(dates !== undefined) {
          setAllDates(prevDates => [...prevDates, Object.keys(dates)])
          setAllDatesW(prevDates => [...prevDates, [Object.keys(dates), e]]) // Actualizar el estado usando la función setAllDates
        }
      })
    } else {
   
    servicesSchedules.map((e) => {
      let y = e.docsData.filter(a => a.id == todaysMonth)
      let todayy = "d"+today
     
      let dates = y.length > 0 ? y[0][todayy] : undefined
      if(dates !== undefined) {
        setAllDates(prevDates => [...prevDates, Object.keys(dates)])
        setAllDatesW(prevDates => [...prevDates, [Object.keys(dates), e]]) // Actualizar el estado usando la función setAllDates
      }
    }) }
  }, [servicesSchedules, today, todaysMonth, render, serviceChosen])


  const allDatesMerged = allDates.reduce((acc, curr) => {
    return acc.concat(curr);
  }, []);


  useEffect(() => {
    // Obtiene los días del mes actual utilizando Moment.js
    const days = [];
    const daysInCurrentMonth = moment().daysInMonth();
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      days.push(day);
    }
    setDaysInMonth(days);
  }, [render]);

  useEffect(() => {
    // Obtiene los meses utilizando Moment.js
    const monthsArray = moment.months();
    setMonths(monthsArray);

    // Obtiene los años
    const currentYear = moment().year();
    const yearsArray = [];
    for (let year = currentYear; year <= currentYear + 1; year++) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
  }, [render]);



  //LOGIN

  const { loginCheck } = useLoginContext()
  const [loginAnimation, setLoginAnimation] = useState(false)


  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)
    }, 2000);
  }, [])



  useEffect(() => {

    const yearf = year
    const monthf = month
    const firstDayF = new Date(yearf, monthf - 1, 1)

    setFirstDay(firstDayF.toString()[0] + firstDayF.toString()[1] + firstDayF.toString()[2])
  }, [month])




  useEffect(() => {

    if (month <= 11) {
      const nextmonthF = month + 1
      setNextmonth(nextmonthF)
    } if (month === 12) {
      setNextmonth(1)
    }
  }, [month])

  useEffect(() => {

    if (month <= 11) {
      const nextmonthF = month + 1
      setNextmonth(nextmonthF)
    } if (month === 12) {
      setNextmonth(1)
    }
  }, [month])


  useEffect(() => {
    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }
    setMonthDays(getDaysInMonth(year, month))
  }, [month, render])



  useEffect(() => {
    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }

    const date = new Date();
    const yearF = date.getFullYear()
    const monthF = date.getMonth() + 1
    const todayF = date.getDate()
    setYear(yearF)
    setMonth(monthF)
    setToday(todayF)
    setTodaysMonth(monthF)
    setTodaysYear(yearF)
  }, [])
  useEffect(() => {

    function getDaysInMonth(y, m) {
      return new Date(y, m, 0).getDate();
    }

    const daysInCurrentMonthF = setDaysInCurrentMonth(getDaysInMonth(year, month));

  }, [month, render])



  useEffect(() => {
    const days = []

    for (var i = 1; i <= monthDays; i++) {
      days.push(i);
    }
    setDaysLoop(days)

  }, [monthDays, render])

  const [scroll, setScroll] = useState(1)
  const [changeWeek, setChangeWeek] = useState(false)


  useEffect(() => {
    if (today < 8) {
      return setScroll(1)
    } if (today < 15 && today >= 7) {
      return setScroll(1)
    } if (today < 22 && today >= 14) {
      return setScroll(2)
    } if (today < 29 && today >= 21) {
      return setScroll(2)
    } if (today > 29) {
      return setScroll(2)
    }
  }, [daysLoop, render])

  useEffect(() => {
    if (month !== todaysMonth) {
      return setScroll(1)
    }
  }, [daysLoop])

  const scrollRight = () => {
    if (scroll === 2) {
      setScroll(scroll)
    } else {
      setScroll(scroll + 1)
      setChangeWeek(true)

      setTimeout(() => {
        setChangeWeek(false)
      }, 500);
    }

  }

  const scrollLeft = () => {
    if (scroll === 1) {
      setScroll(scroll)
    } else {
      setScroll(scroll - 1)
      setChangeWeek(true)

      setTimeout(() => {
        setChangeWeek(false)
      }, 2000);
    }

  }

  const loaderPromise = new Promise((resolve, reject) => {
    setTimeout(() => { resolve([]); }, 2000);
  });

  loaderPromise.then(() => {
    setLoader(false);
  });



  const renderF = () => {
    setRender(render + 1)
  }

  const handleDayChange = (event) => {
    setToday(event.target.value);
    renderF()
  }


  const handleMonthChange = (event) => {
    setTodaysMonth(event.target.value);
    renderF()
  }

  const handleYearChange = (event) => {
    setTodaysYear(event.target.value);
    renderF()
  }

  const handleServiceChange = (event) => {
    setServiceChosen(event.target.value);

  }

//   useEffect(() => {
//     if(serviceChosen !== null) {
//       setServicesSchedules(servicesSchedules.filter(e => e.id == serviceChosen ))
//     } else {
//       setServicesSchedules(servicesSchedules)
//     }
   
//   }, [serviceChosen, render, services, todaysYear])
  
// console.log(servicesSchedules)

  const blockDay = async (a, b, c, d, e) => {
    const docId = c.toString();
    const arr = [c.toString()]
    const queryCol = collection(db, `services/${a}/${b}/`)

    const batch = writeBatch(db)
    const updateId = await query(
      queryCol,
      where(documentId(), 'in', arr))

    await getDocs(updateId)
      .then(resp => resp.docs.forEach(res => {
        batch.update(res.ref, { ['d' + d + '.' + e]: false });
      })
      ).catch(error => console.log(error))
  await batch.commit();
  }

  const unblockDay = async (a, b, c, d, e) => {
    const docId = c.toString();
    const arr = [c.toString()]
    const queryCol = collection(db, `services/${a}/${b}/`)

    const batch = writeBatch(db)
    const updateId = await query(
      queryCol,
      where(documentId(), 'in', arr))

    await getDocs(updateId)
      .then(resp => resp.docs.forEach(res => {
        batch.update(res.ref, { ['d' + d + '.' + e]: true });
      })
      ).catch(error => console.log(error))
  await batch.commit();
  }



  const [dayOfTheWeek, setDayOfTheWeek] = useState(null)

  useEffect(() => {
    let realtoday = Number(today) // > 9 ? Number(today) + 1 : Number(today)
    const fecha = new Date(`${todaysYear}-${todaysMonth}-${realtoday}`);

    setTimeout(() => {
      setDayOfTheWeek(fecha.toString()[0] + fecha.toString()[1] + fecha.toString()[2])
    }, 200);


  }, [todaysYear, today, todaysMonth])

  return (
    <>

          <div className='dashboardCont'>
            {loader === true ? <div className='weekcalendarloader'>
              <LineWobble
                size={80}
                lineWeight={5}
                speed={1.75}
                color="#0010d5" />
            </div> : <>
              <div className='BookListContWeekMini '>
          
                <div className='dateSelectorContMini'>
                <RefreshButton refreshFunction={rerenderF} />

                    <p className='button2'>{dayOfTheWeek === "Mon" ? "LUNES" : dayOfTheWeek === "Tue" ? "MARTES" : dayOfTheWeek === "Wed" ? "MIÉRCOLES" : dayOfTheWeek === "Thu" ? "JUEVES" : dayOfTheWeek === "Fri" ? "VIERNES" : dayOfTheWeek === "Sat" ? "SÁBADO" : dayOfTheWeek === "Sun" ? "DOMINGO" : "" }</p>
                    <label className='labelSelector' htmlFor="days">Día:</label>
                    <select className='selector' id="days" value={today} onChange={handleDayChange}>
                      <option value="">Seleccione un día</option>
                      {daysInMonth.map((day) => (
                        <option className='selector thin' key={day} value={day}>{day}</option>
                      ))} 
                    
                    </select>              
           
               
                  <label className='labelSelector' htmlFor="months">Mes:</label>
                  <select className='selector' id="months" value={todaysMonth} onChange={handleMonthChange}>
                    <option value="">Seleccione un mes</option>
                    {months.map((month, index) => (
                      <option className='selector thin'  key={month} value={index + 1}>{month === "April" ? "Abril" : month === "March" ? "Marzo" : month === "January" ? "Enero" :  month === "February" ? "Febrero" :  month === "May" ? "Mayo" :  month === "June" ? "Junio" :  month === "July" ? "Julio" :  month === "August" ? "Agosto" :  month === "September" ? "Septiembre" :  month === "October" ? "Octubre" :  month === "November" ? "Noviembre" :  month === "December" ? "Diciembre" :<></>}</option>
                    ))}
                  </select>

                  <label className='labelSelector' htmlFor="years">Año:</label>
                  <select className='selector' id="years" value={todaysYear} onChange={handleYearChange}>
                    <option value="">Seleccione un año</option>
                    {years.map((year) => (
                      <option className='selector thin' key={year} value={year}>{year}</option>
                    ))}
                  </select>

                  <label className='labelSelector' htmlFor="services">Servicio:</label>
                  <select className='selector' id="services" value={serviceChosen} onChange={handleServiceChange}>
                    <option value="">Seleccionar servicio</option>
                    <option className='selector thin' value={"todos"}>todos</option>
                    {services.map((year) => (
                      <option className='selector thin' key={year.id} value={year.id}>{year.id}</option>
                    ))}
                     
                  </select>
  

                </div>
          
                <div className='calendarCont'>
         
                  <MiniCalendarList unblockDay={unblockDay} blockDay={blockDay} services={services} daysInCurrentMonth={daysInCurrentMonth} monthDays={monthDays} daysLoop={daysLoop} year={year} month={month} today={today} todaysMonth={todaysMonth} todaysYear={todaysYear} bookings={bookings} scroll={scroll} changeWeek={changeWeek} renderF={renderF} render={render} allDatesMerged={allDatesMerged} allDatesW={allDatesW} servicesSchedules={servicesSchedules} serviceChosen={serviceChosen} />

                </div>
              </div>
            </>}
          </div>
        </>

  )
}
