import React, { useState, useEffect } from 'react';
import { addDoc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
export default function ItemsEditButton({id, name, time, price, render, adding, addNew, error, sub}) {

    const db = getFirestore()

    const [currentTime, setcurrentTime] = useState(time)
    const [currentName, setcurrentName] = useState(name)
    const [currentPrice, setcurrentPrice] = useState(price)
    const [currentSub, setcurrentSub] = useState(sub ? sub : "")
    const [newTime, setNewTime] = useState(time)
    const [newSub, setNewSub] = useState(sub)
    const [newName, setNewName] = useState(name)
    const [newPrice, setNewPrice] = useState(price)
    const [editing, setEditing] = useState(false)

    
    const handleTime = (event) => {
        setNewTime(event.target.value);
    }

    
    const handleName = (event) => {
      setNewName(event.target.value);
    }


    
    const handlePrice = (event) => {
        setNewPrice(event.target.value);
    }

        
    const handleSub = (event) => {
      setNewSub(event.target.value);
  }


    const editarF = () => {
      setcurrentName(null)
      setcurrentTime(null)
      setcurrentPrice(null)
      setcurrentSub(null)
      setEditing(true)
    }

    const confirm = async () => {

       
              const arr = [id.toString()]
      
             
              const queryCol = collection(db, `items`)
             
              const batch = writeBatch(db)
              const updateId = await query(
                  queryCol,
                where(documentId(), 'in', arr))
             
             
                
                 await getDocs(updateId)
                 .then(resp => resp.docs.forEach(res => {
                  batch.update(res.ref, {price: newPrice === null ? Number(currentPrice) : Number(newPrice), time: newTime === null ? Number(currentTime) : Number(newTime), name: newName === null ? currentName : newName, name: newName === null ? currentName : newName, sub: newSub === null ? currentSub : newSub });
                })
                 )
                 await batch.commit(); 
            

        setEditing(false)
        render()
      }

  return (
    
    <div className='itemsEditInputsCont'>
    <input className={editing === true ? 'itemsEditInput w30rem bgEditing' : 'itemsEditInput w30rem'} placeholder={name} value={currentName} onChange={handleName}>
  </input> 
 
    <input className= {editing === true ? 'itemsEditInput w5itemsrem bgEditing alignCenter2' : 'itemsEditInput w5itemsrem alignCenter2'} placeholder={time} value={currentTime} onChange={handleTime}>
  
    </input>

    <input className= {editing === true ? 'itemsEditInput w5itemsrem bgEditing alignCenter2' : 'itemsEditInput w5itemsrem alignCenter2'} placeholder={price} value={currentPrice} onChange={handlePrice}>

    </input>

    <input className= {editing === true ? 'itemsEditInput w30rem  bgEditing alignCenter2 itemsEditInputSub' : 'itemsEditInput w5itemsrem alignCenter2'} placeholder={sub ? sub : "Vacío"} value={currentSub} onChange={handleSub}>

</input>
    {adding === true ? 
    <button onClick={() => {
        addNew(newName, newTime, newPrice, newName, newSub)
    }} className='button2'>Agregar nuevo</button>
    : 
    <>    {editing === true ? <button onClick={confirm} className='button2'>Confirmar cambios</button> :     <button onClick={editarF} className='button2'>Editar</button>
}</>
    }

    {error === true ? <button  className='cancelButton44 '>¡Ya existe un item con el mismo nombre!</button> : <></>}

  </div>
  )
}
