import React from 'react'

export default function InputCheckOut({ place, nameIn, type, onchange}) {
  return (

    <input className='inputCheckOut' type={type} name={nameIn} placeholder={place} onChange=
      {onchange} />

  )
}
