import React, { useState, useEffect } from 'react';
import { useCalendarContext } from '../context/CalendarContext'



export default function NewCalendarDay({
 serviceChoose, year, month, day, serviceMonth, itemsByTime
}) {

    const { availDays, availDaysForDetail } = useCalendarContext();

    const [forbiddenDay, setForbiddenDay] = useState(null)
    const [dayOfTheWeek, setDayOfTheWeek] = useState(null)
   
    useEffect(() => {
      const fecha = new Date(`${year}-${month}-${day.substring(1) >= 9 ? Number(day.substring(1)) + 1 : day.substring(1)}`);
      
      const dayOfWeek = fecha.getDay();
  
      // Crear un array con los nombres de los días de la semana
      const daysOfWeekArray = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  
      // Establecer el día de la semana
      setDayOfTheWeek(daysOfWeekArray[dayOfWeek]);
  }, [year, day, month]);
  

    useEffect(() => {
        var newDay = new Date();
        var today = newDay.getUTCDate();
        var currentMonth = newDay.getUTCMonth() + 1;
        var currentYear = newDay.getUTCFullYear();
    
        if (today >= day.substring(1) && currentMonth === month && currentYear === year) {
          setForbiddenDay(true)    
        } else {
          setForbiddenDay(false)
        }
      }, [month])

      const [ThisDayDates, setThisDayDates] = useState(null)
      const [ThisDayDatesArr, setThisDayDatesArr] = useState(null)
      const dayPlusD = day
    
      useEffect(() => {
    
        if (serviceMonth && serviceMonth.length > 0) {
          if (dayPlusD in serviceMonth[0]) {
            setThisDayDates(serviceMonth[0][dayPlusD])
          } else {
            setThisDayDates(null)
          } 
        } else {
          setThisDayDates(null)
        }
    
      }, [serviceMonth, month])
    
    
      const [checkAvail, setCheckAvail] = useState(null)
      useEffect(() => {
    
        if (ThisDayDates !== null) {
       
          const myArray = Object.entries(ThisDayDates).map(([key, value]) => (value));
          setCheckAvail(myArray)
        } else {
          setCheckAvail(null)
        }
      }, [ThisDayDates, month])
    
      const [avail, setAvail] = useState(null)
      useEffect(() => {
        if (checkAvail !== null) {
    
          const myArray = checkAvail.some(e => e === true);
          setAvail(myArray)
        } else {
          setAvail(false)
        }
      }, [checkAvail, forbiddenDay, month])
    

    
      const [availDays2, setAvailDays2] = useState([])

    useEffect(() => {  
    
      let dayOf = dayOfTheWeek == "Sun" || dayOfTheWeek == "Sat"
    
      if(serviceMonth && serviceMonth.length > 0) {
        setAvailDays2(Object.keys(serviceMonth[0]).filter((clave) => clave.startsWith("d") && Object.values(serviceMonth[0][clave]).includes(true)).filter(item => item !== 'id').map(item => item.slice(1)).map(e => Number(e)))
      } else {
        setAvailDays2([])
      }
    
    }, [serviceMonth])
    
    
    useEffect(() => {
      availDays.splice(0)
      if(availDays.length === 0) {
        if(availDays2.length > 0) {
          availDays.push(availDays2.sort((a, b) => a - b))
        } else {
    
        }
       
      }
    
    }, [availDays2, month])
    
    
    
      const [showup, setShowup] = useState(false)
    
      const showupF = () => {
        if (showup === true) {
          setShowup(false)
        } else {
          setShowup(true)
        }
      }
    
      const [thisDaySchedules, setThisDaySchedules] = useState([])
      useEffect(() => {
    if(ThisDayDates !== null) {
      const horariosArray = Object.entries(ThisDayDates)
      .filter(([_, valor]) => valor)
      .map(([hora]) => parseInt(hora));
    
      setThisDaySchedules(horariosArray);
    }
      
      }, [ThisDayDates, month])
    
      useEffect(() => {
        if (ThisDayDates !== null) {
          const myArray = Object.entries(ThisDayDates).map(([key, value]) => ({ key, value }));
          setThisDayDatesArr(myArray)
        }
    
      }, [ThisDayDates, day, month])
    
      const [availBlocks, setAvailBlocks] = useState([])
    
      
      useEffect(() => {
    
        setAvailBlocks([])
        const MINUTES_IN_BLOCK = 15;
        let currArray = [];
        let prevKey = null;
        let result = [];
     
    
        if (ThisDayDatesArr !== null) {
          let sortedArr = ThisDayDatesArr.filter(element => element.value === true).sort((a, b) => a.key.localeCompare(b.key));
    
          for (let i = 0; i < sortedArr.length; i++) {
            const currKey = parseInt(sortedArr[i].key);
            const currKeyplus = parseInt(sortedArr[i].key) + 15;
            const realkey = currKeyplus.toString().includes("60")
            const realRealKey = realkey === true ? currKey + 40 : currKey
            const realkey60 = currKeyplus.toString().includes("60")
            const realkey000 = currKey.toString().includes("000")
            const realkey00 = currKey.toString().includes("00")
            const realPrevKey = realkey60 === true || realkey00 === true || realkey000 === true ? prevKey + 40 : prevKey
    
    
            if (!prevKey || (Number(realRealKey) - Number(realPrevKey) === MINUTES_IN_BLOCK)) {
    
              currArray.push(sortedArr[i]);
            } else {
              result.push(currArray);
              currArray = [sortedArr[i]];
            }
    
            prevKey = currKey;
          }
    
          if (currArray.length > 0) {
            result.push(currArray);
          }
          availBlocks.push(result.map(e => e.length))
        }
    
    
    
    
      }, [ThisDayDates, ThisDayDatesArr, month])
    
    
    
    
    
      const [avail2, setAvail2] = useState(false)
    
      useEffect(() => {  
        let blocks1 = itemsByTime.filter(e => e.length !== 0).map(e => e[0].time / 15);
        let blocks2 = availBlocks.length !== 0 ? availBlocks[availBlocks.length - 1] : null;
        let encontrado = false;
    
    
    
        if (blocks2 !== null) {
          blocks2.forEach(z => {
            let disp = blocks1.map(e => e <= z);
        
            if (disp.some(dato => dato === true) === true) {
              encontrado = true;
            }
          });
        
          if (encontrado) {
            setAvail2(true);
          } else {
            setAvail2(false);
      
          }
        }
    
      }, [availBlocks, month])
      
    
      useEffect(() => {
        if(avail2 === false) {
        
        } else {
          availDaysForDetail.push(day)
        }
        
      }, [avail2])


 

  return (
    <>
    {!forbiddenDay && avail2 && avail ?
        <option className='checkoutLabel w20rem' value={day}>
     {day.substring(1)}/{month}
    </option>
    :  <option disabled className='checkoutLabel w20rem' value={day}>
    {day.substring(1)}/{month} - AGOTADO
   </option> }
    </>

  )
}
