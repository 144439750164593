import React from 'react'
import { Link } from 'react-router-dom';
export default function RoomsEditItem({ customer, id, roomname }) {


    return (
            <Link className='serviceEditCont' to={`/dashboard/${id}`}>
                <p className='roomsEditCont-title'> <b> {id.toUpperCase()}</b> {roomname} </p>
                <p className='button'> <b>EDITAR</b> </p>
            </Link>
    )
}
