import React from 'react'
import MessagesItem from './MessagesItem'

export default function MessagesList({messages}) {
  return (
    
    <>
    <div className='bookOrderDashboardlist margintop'>
    <p className='sectionTittles'>| MESSAGES</p>
   
    {messages.sort(function (a, b) {
        return new Date(b.timestamp) - new Date(a.timestamp);
      }).map((message) => <MessagesItem key={message.timestamp} {...message} /> )}
       </div>
    </>
  )
}
