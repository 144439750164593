import React from 'react'
import BookingsWidgetItem from './BookingsWidgetItem';

export default function BookingsWidget({ bookings, dayType }) {


    // Obtener la fecha actual en formato YYYY-MM-DD
    const today = new Date();


    // Obtener la fecha de mañana en formato YYYY-MM-DD
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);


    // Función para formatear la fecha como "dd-mm-aaaa"
    const formatDateToString = (a) => {
        const day = a.getDate().toString();
        const month = (a.getMonth() + 1).toString();
        const year = a.getFullYear();
        return `${day}/${month}/${year}`;
    };
    // Filtrar las bookings para obtener las de hoy y ordenarlas por horario

    const bookingsFilter = dayType === "today" ?
        bookings
            .filter((booking) => booking.date == formatDateToString(today))
            .sort((a, b) => (a.time > b.time ? 1 : -1))
        :
        bookings
            .filter((booking) => booking.date == formatDateToString(tomorrow))
            .sort((a, b) => (a.time > b.time ? 1 : -1))





    return (
        <>
            {bookingsFilter.length === 0 ?

                <div className='column'>
                    <p className='DashboardTitles'>  {dayType === "today" ? "Hoy" : "Mañana"}</p>
                    <div className='emptybookingsWidgetCont'>
                        {dayType === "today" ? "No hay turnos" : "No hay turnos"}
                    </div>
                </div>

                :

                <div className='column'>
                    <p className='DashboardTitles'>  {dayType === "today" ? "Hoy" : "Mañana"}</p>
                    <div className='bookingsWidgetCont'>

                        {bookingsFilter.map((book) => (
                            <BookingsWidgetItem
                                key={book.id}
                                {...book}

                            />
                        ))}

                    </div>
                </div>
            }
        </>

    )
}
