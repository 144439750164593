import React, { useState, useEffect } from 'react'
import AgendaItem from './AgendaItem'

export default function AgendaItemCont({ bookings, letter, pushEmail }) {

    const [letteropen, setLetteropen] = useState(true)

    let bookingsmap = bookings.filter(e => e.client.name[0] ==  letter.toUpperCase()  || e.client.name[0] == letter)

    const openLetterF = () => {
      if(letteropen === false) {
        setLetteropen(true)
      } else {
        setLetteropen(false)
      }
    }

  return (
    <>
    
    {bookingsmap.length === 0 ? <></> :
    
    <div className='agendaLetterCont'>
    <button onClick={openLetterF} className='abcletter'>{letter.toUpperCase()}</button>
    {bookingsmap.length >= 1  && letteropen === true ?
        <>
            {bookingsmap.map((e) =>
                <AgendaItem key={e.id} {...e}  pushEmail={pushEmail} />
            )}
        </>
        : <></>}
</div>
    
    }
    </>

  )
}
