import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, getDocs, doc, addDoc,  writeBatch, where, query, documentId } from 'firebase/firestore';
import { Ping } from '@uiball/loaders'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Logo from '../microsites/Logo';

export default function Checkout() {

    const db = getFirestore()


    const location = useLocation();
    const [buyOrder, setBuyOrder] = useState(undefined)
    const [paymentData, setPaymentData] = useState([])
    const [searchPayments, setSearchPayments] = useState([])
    const [orderId, setOrderId] = useState(1312418417)
    const [bookings, setBookings] = useState([])


    useEffect(() => {
        setBuyOrder(JSON.parse(sessionStorage.getItem('bookClient')))
        
        setPaymentData(location)
    
      }, [location])

 

  // MERCADOPAGO

  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        `Bearer APP_USR-5337825040898144-041711-10d8f61c8053ebbeec9c5223f3394446-272278393`
    }
  };


  useEffect(() => {
    //getFetch();
    fetch(`https://api.mercadopago.com/v1/payments/54672495596`, data)
      .then(function (resp) {
        return resp.json();
      })
      .then((resp) => setSearchPayments(resp));

  }, []);



      useEffect(() => {
        if (location.search.length > 5 && location.search.includes("error") === false && location.search.includes("approved") === true) {
          changeToPaid()
        } else {

        }
  
      }, [paymentData])
      
useEffect(() => {
    const subg = location.search.substr(12, 20)

    setOrderId(Number(subg.match(/\d+/g)))
}, [location])


useEffect(() => {
    const QueryCollection = collection(db, "bookings")
    getDocs(QueryCollection)
        .then(resp => setBookings(resp.docs.map(item => ({ id: item.id, ...item.data() }))))
        .catch((err) => console.log(err))
    //   .finally(() => setLoading(false))
}, [])


useEffect(() => {
  if(bookings.length !== 0 && buyOrder !== undefined ) {

  }
}, [bookings])



const changeToPaid = async () => {

  
        const arr = bookings.filter(e => e.idCode == buyOrder.idCode)

        const queryCol = collection(db, `bookings`)

        const batch = writeBatch(db)
    
        const updateId = await query(
            queryCol,
          where(documentId(), 'in', arr.map(e => e.id)))
    
    
        await getDocs(updateId)
          .then(resp => resp.docs.forEach(res => 
                      batch.update(res.ref, { paid: true })
       
          ))
          await batch.commit(); 

  

}




  return (
    <div className='marginTop'>
          <Logo />

        {buyOrder !== undefined ? 

     
        <div className='column'>
               
            <p className='button2 biggertext '>¡gracias {buyOrder.client.name}!</p>
            <p className='checkoutTexts biggertext'>recibí tu pago correctamente.</p>

            <img className='paidImg' src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Fundraw_Credit_card_payments_re_qboh.png?alt=media&token=1709a56d-616a-456b-b292-169b7207946c" alt="" />
 
 
          
            <Link className='row' to="https://api.whatsapp.com/send/?phone=5492213990248&text&type=phone_number&app_absent=0">
            <p className='bankDataTexts'>podés contactarme por whatsapp </p>
            <img className='whatsappLogo' src="https://img.icons8.com/ios/50/76d273/whatsapp--v1.png"/>
            </Link>

        </div>

        
    :

    <>

    </>
    }

    </div>
  )
}
