import React, { useState, useEffect } from 'react';
import { addDoc, doc, updateDoc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
export default function ItemsSelectButton({id, name, time, price, render, adding, addNew, service, services}) {

    const db = getFirestore()

    const [currentTime, setcurrentTime] = useState(time)
    const [currentId, setcurrentId] = useState(id)
    const [currentPrice, setcurrentPrice] = useState(price)

    const [newTime, setNewTime] = useState(null)
    const [newId, setNewId] = useState(null)
    const [newPrice, setNewPrice] = useState(null)
    const [editing, setEditing] = useState(false)

    const [thisService, setThisService] = useState(undefined)

    useEffect(() => {
      let ss = services.filter(e => e.id == service)
    
      setThisService(ss)
    }, [services, service, render])
    



    const quit = async () => {
      
        const arr = [service]
        const itemsArr = thisService !== [] ? thisService[0].items : [];
        const newArr = itemsArr.filter(e => e !== id);
         
        const queryCol = collection(db, `services`)
       
        const batch = writeBatch(db)
        const updateId = await query(
            queryCol,
          where(documentId(), 'in', arr))
       
       
          
           await getDocs(updateId)
           .then(resp => resp.docs.forEach(res => {
            
        
            batch.update(res.ref, { items: newArr });
          })
            // const updateData = { ['d' + dateList[1]]: { status: false } };
           )
           await batch.commit(); 


        render()
      }

      
    const add = async () => {
        const arr = [service]
        const itemsArr = thisService !== [] ? thisService[0].items : [];
        const newArr = itemsArr.concat(id);
         
        const queryCol = collection(db, `services`)
       
        const batch = writeBatch(db)
        const updateId = await query(
            queryCol,
          where(documentId(), 'in', arr))
       
       
          
           await getDocs(updateId)
           .then(resp => resp.docs.forEach(res => {
            
        
            batch.update(res.ref, { items: newArr });
          })
            // const updateData = { ['d' + dateList[1]]: { status: false } };
           )
           await batch.commit(); 

          
           render()
      }

      const [exists, setExists] = useState(true)

 
      useEffect(() => {
        if(thisService !== undefined && thisService !== [] ) {
            let rr = thisService[0].items.find(e => e == id )


            if(rr !== undefined) {
                setExists(true)
            } else {
                setExists(false)
            }
      
        } else {
            setExists(false)
        }
        
      }, [thisService, render])
      

  return (
    <div className='itemsEditInputsCont'>
    <p className='itemsEditInput w30rem wMobile' > {name} </p>

    <>    {exists === false ? <button onClick={add}  className='button2'>Agregar</button> :     <button onClick={quit} className='cancelButton44'>Quitar</button>
}</>
   


  </div>
  )
}
