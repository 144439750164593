import React from 'react'
import RoomsEditItem from './RoomsEditItem'
import { Link } from 'react-router-dom';

export default function RoomsEditItemList({ services }) {

    return (
        <>
            <div className='servicesListCont margintop'>
                {services && (
services.map(service =>

    <RoomsEditItem key={service.id} services={services} {...service} />
)
                )}
  

     

            </div>
        </>

    )
}
