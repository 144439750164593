import React, { useState, useEffect } from 'react';
import BookListCont from '../../booking/BookListCont'
import NavbarPanel from '../NavbarPanel'
import { useParams, useLocation } from 'react-router-dom';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import BookCont from '../../book/BookCont';

export default function CalendarCont({ }) {

  const location = useLocation()
  const [adminAct, setAdminAct] = useState(false)
  const { customer } = useParams()
  const { loginCheck } = useLoginContext()




  useEffect(() => {
    if (location.pathname === "/dashboard/calendar") {
      setAdminAct(true)
    }
  }, [])


  const [loginAnimation, setLoginAnimation] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])


  return (
    <>

      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>

          <Login />
        </div>
        :

        <>
          <div className='dashboardCont'>
            <NavbarPanel />
            <p className='sectionTittles'>| NUEVA RESERVA</p>

            <div className='booklistcontAdmin '>
              <BookCont admin={adminAct}  />
            </div>
          </div>
        </>

      }
    </>
  )
}
