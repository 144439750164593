import React, { useState, useEffect } from 'react';
import { setDoc, doc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import { LineWobble } from '@uiball/loaders'
import NavbarPanel from '../NavbarPanel'
import { useParams } from 'react-router-dom';
import RoomsEditInput from '../roomsEdit/RoomsEditInput';
import RoomsEditImage from '../roomsEdit/RoomsEditImage';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
export default function MicrositeEditCont() {
    const db = getFirestore()
    const { customer } = useParams()
    const [loader, setLoader] = useState(true)
    const [showAddButton, setShowAddButton] = useState(true)
    const [profileData, setProfileData] = useState()
    const [socialData, setSocialData] = useState()
    const [imagesData, setImagesData] = useState()
    const [hotelData, setHotelData] = useState()
    const [newTwitter, setNewTwitter] = useState()
    const [newFacebook, setNewFacebook] = useState()
    const [newYoutube, setNewYoutube] = useState()
    const [newInstagram, setNewInstagram] = useState()
    const [newName, setNewName] = useState()
    const [newAddress, setNewAddress] = useState()
    const [newCity, setNewCity] = useState()
    const [newCountry, setNewCountry] = useState()
    const [newDescription, setNewDescription] = useState()
    const [newEmail, setNewEmail] = useState()
    const [newPhone, setNewPhone] = useState()
    const [newMaps, setNewMaps] = useState()
    const [newWhatsapp, setNewWhatsapp] = useState()
    const [newCover, setNewCover] = useState()
    const [newGallery1, setGallery1] = useState()
    const [newGallery2, setGallery2] = useState()
    const [newGallery3, setGallery3] = useState()
    const [newGallery4, setGallery4] = useState()
    const [newGallery5, setGallery5] = useState()
    const [newGallery6, setGallery6] = useState()
    const [newGallery7, setGallery7] = useState()
    const [newGallery8, setGallery8] = useState()
    const { loginCheck, rerenderLogin } = useLoginContext()

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const dataLoad = (a) => {
        return new Promise((resolve,reject) => {
            if(a === undefined || a === []) {
                reject()
            } else {
                resolve()
            }
        })
    }
    useEffect(() => {

        const QueryCollection = collection(db, `clients/${customer}/microsite`)

        getDocs(QueryCollection)
            .then(resp => setProfileData(resp.docs.map(site => ({ id: site.id, ...site.data() }))))
            .catch((err) => console.log(err))

    }, [customer, rerenderLogin])


    

    useEffect(() => {
        dataLoad(profileData)
        .then(() => {
            setHotelData(profileData.find(e => e.id == "texts"))
            setSocialData(profileData.find(e => e.id == "social"))
            setImagesData(profileData.find(e => e.id == "images"))
        })
        .catch(err => err)
     }, [profileData])

    const loaderPromise = new Promise((resolve, reject) => {
        setTimeout(() => { resolve([]); }, 2000);
    });

    loaderPromise.then(() => {
        setLoader(false);
    });

    const socialArr = ["social"]
    const textsArr = ["texts"]
    const imagesArr = ["images"]
    const editProp = async (a, b, c) => {

        const batch = writeBatch(db)

        const queryCol = collection(db, `clients/${customer}/microsite`)
        const queryRooms = await query(
            queryCol,
            where(documentId(), 'in', c.map(e => e)))

        await getDocs(queryRooms)
            .then(resp => resp.docs.forEach(((res) => {
                batch.update(res.ref, { [a]: b })
            })))
            .then(rerenderLogin)

        await batch.commit()
    }


    const AddNewImages = () => {

        setShowAddButton(false)
        setDoc(doc(db, `clients/${customer}/microsite`, "images"), {
            cover: null,
            gallery1: null,
            gallery2: null,
            gallery3: null,
            gallery4: null,
            gallery5: null,
            gallery6: null,
            gallery7: null,
            gallery8: null,
        })
    }


    const [loginAnimation, setLoginAnimation] = useState(false)

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)


        }, 2000);
    }, [])


    return (
        <>
            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
                    <p className='logoMicrositeLoading' >HOLDE <span className='logobackMicrosite'>.ar</span></p>

                    <Login customer={customer} />
                </div>
                :
                <>
                    <div className='dashboardCont'>
                        <NavbarPanel customer={customer} />
                        <p className='sectionTittles'>| MICROSITE ROOM </p>

                        {loader === true ?

                            <div className='weekcalendarloader'>
                                <LineWobble
                                    size={80}
                                    lineWeight={5}
                                    speed={1.75}
                                    color="#576F72" />
                            </div>
                            :

                            <>

                                <div className='roomeditCard ww40'>

                                    {hotelData !== undefined ? 
                                    <form className='RoomEditform' action="" onSubmit={handleSubmit}>
                                    <p className='roomsedittitle'> {hotelData.name} </p>

                                    <RoomsEditInput name="Nombre del hotel" data={hotelData.name} type="text" onchange={(e) => setNewName(e.target.value)} edit={editProp} newData={newName} prop="name" col={textsArr}  />

                                    <RoomsEditInput name="Dirección" data={hotelData.address} type="text" onchange={(e) => setNewAddress(e.target.value)} edit={editProp} newData={newAddress} prop="address" col={textsArr}  />

                                    <RoomsEditInput name="Ciudad" data={hotelData.city} type="text" onchange={(e) => setNewCity(e.target.value)} edit={editProp} newData={newCity} prop="city" col={textsArr}  />

                                    <RoomsEditInput name="País" data={hotelData.country} type="text" onchange={(e) => setNewCountry(e.target.value)} edit={editProp} newData={newCountry} prop="country" col={textsArr}  />

                                    <RoomsEditInput name="Descripción del hotel" data={hotelData.description} type="text" onchange={(e) => setNewDescription(e.target.value)} edit={editProp} newData={newDescription} prop="description" col={textsArr} textarea={true}  />

                                    <RoomsEditInput name="Email del hospedaje" data={hotelData.email} type="text" onchange={(e) => setNewEmail(e.target.value)} edit={editProp} newData={newEmail} prop="email" col={textsArr}  />

                                    <RoomsEditInput name="Teléfono" data={hotelData.phone} type="text" onchange={(e) => setNewPhone(e.target.value)} edit={editProp} newData={newPhone} prop="phone" col={textsArr}  />

                                    <RoomsEditInput name="Google Maps embed code" data={hotelData.maps} type="text" onchange={(e) => setNewMaps(e.target.value)} edit={editProp} newData={newMaps} prop="maps" col={textsArr} />

                                    <RoomsEditInput name="Whatsapp" data={hotelData.whatsapp} type="text" onchange={(e) => setNewWhatsapp(e.target.value)} edit={editProp} newData={newWhatsapp} prop="whatsapp" col={textsArr} />

                                    <RoomsEditInput name="facebook" data={socialData.facebook} type="text" onchange={(e) => setNewFacebook(e.target.value)} edit={editProp} newData={newFacebook} prop="facebook" col={socialArr} />

                                    <RoomsEditInput name="youtube" data={socialData.youtube} type="text" onchange={(e) => setNewYoutube(e.target.value)} edit={editProp} newData={newYoutube} prop="youtube" col={socialArr} />

                                    <RoomsEditInput name="instagram" data={socialData.instagram} type="text" onchange={(e) => setNewInstagram(e.target.value)} edit={editProp} newData={newInstagram} prop="instagram" col={socialArr} />

                                    <RoomsEditInput name="twitter" data={socialData.twitter} type="text" onchange={(e) => setNewTwitter(e.target.value)} edit={editProp} newData={newTwitter} prop="twitter" col={socialArr} />


                                </form>
                                    : <></>}

                                    

                                    {profileData.find(e => e.id == "images") === undefined && showAddButton === true ?
                                        <button className='beds' onClick={() => {
                                            AddNewImages()
                                        }}>Añadir imágenes</button>
                                        : <></>

                                    }

                                    {imagesData != undefined ?
                                        <>

                                            <p className='mercadopagoTittle margintopNone' >Portada</p>

                                            <div className='micrositeGalleryEditCont'>

                                                <RoomsEditImage prop="cover" image={imagesData.cover} customer={customer} newData={newCover} edit={editProp} microsite={true} col={imagesArr} />
                                            </div>
                                            <p className='mercadopagoTittle margintopNone'>Galería</p>
                                            <div className='micrositeGalleryEditCont'>
                                                
                                                <RoomsEditImage prop="gallery1" image={imagesData.gallery1} customer={customer} newData={newGallery1} edit={editProp} microsite={true} col={imagesArr} rerenderMicro={rerenderLogin} />



                                                {imagesData.gallery1 != null || imagesData.gallery1 != undefined ?
                                                    <RoomsEditImage prop="gallery2" image={imagesData.gallery2} customer={customer} newData={newGallery2} edit={editProp} microsite={true} col={imagesArr}  rerenderMicro={rerenderLogin} /> : <></>}
                                                {imagesData.gallery2 != null || imagesData.gallery2 != undefined ?
                                                    <RoomsEditImage prop="gallery3" image={imagesData.gallery3} customer={customer} newData={newGallery3} edit={editProp} microsite={true} col={imagesArr}  rerenderMicro={rerenderLogin}  /> : <></>}
                                                {imagesData.gallery3 != null || imagesData.gallery3 != undefined ?
                                                    <RoomsEditImage prop="gallery4" image={imagesData.gallery4} customer={customer} newData={newGallery4} edit={editProp} microsite={true} col={imagesArr} rerenderMicro={rerenderLogin}  /> : <></>}
                                                {imagesData.gallery4 != null || imagesData.gallery4 != undefined ?
                                                    <RoomsEditImage prop="gallery5" image={imagesData.gallery5} customer={customer} newData={newGallery5} edit={editProp} microsite={true} col={imagesArr} rerenderMicro={rerenderLogin}  /> : <></>}
                                                {imagesData.gallery5 != null || imagesData.gallery5 != undefined ?
                                                    <RoomsEditImage prop="gallery6" image={imagesData.gallery6} customer={customer} newData={newGallery6} edit={editProp} microsite={true} col={imagesArr}  rerenderMicro={rerenderLogin} /> : <></>}
                                                {imagesData.gallery6 != null || imagesData.gallery6 != undefined ?
                                                    <RoomsEditImage prop="gallery7" image={imagesData.gallery7} customer={customer} newData={newGallery7} edit={editProp} microsite={true} col={imagesArr} rerenderMicro={rerenderLogin}  /> : <></>}
                                                {imagesData.gallery7 != null || imagesData.gallery7 != undefined ?
                                                    <RoomsEditImage prop="gallery8" image={imagesData.gallery8} customer={customer} newData={newGallery8} edit={editProp} microsite={true} col={imagesArr} rerenderMicro={rerenderLogin}  /> : <></>}


                                            </div>

                                        </> :

                                        <></>
                                    }
                                </div>

                            </>

                        }
                    </div>
                </>}</>
    )
}
