import React from 'react';

export default function NewBookingsItem({ client, fullbookdate, hour, date, day, item, month, paid, service, status, time, total, year, }) {

    const mensajeDom = `hola ${client.name}! Soy Valu de la Coque. el día ${day}/${month} a las ${time} tenemos turno en tu espacio.\n\n ✂ Me repetirías tu dirección así me queda por este medio? \n ✂ por favor, esperame con el pelo limpio o sin productos de finalizar (cremas o geles)\n ✂ siempre trato de ser puntual, pero por cuestiones de traslado entre casa y casa, el margen de demora puede ser de hasta 10 minutos  \n ✂ en caso de que necesites cancelar, avisando con más de 24 horas se reprograma o devuelve la seña. con menos tiempo, se pierde la seña y la posibilidad de programar nuevamente el turno.\n\n Muchas gracias y te veo en tu espacio :)`;
    const baseUrl = "https://api.whatsapp.com/send/?phone=";
    const phoneNumber =  client !== null ? client.phone : null; 
    const encodedMessageDom = encodeURIComponent(mensajeDom);
    const fullUrlDom = `${baseUrl}${phoneNumber}&text=${encodedMessageDom}`;
    
    const mensajeLocal=  client !== null ? `hola ${client.name}! soy valu de la coque. el día ${day}/${month} a las ${time} tenemos turno en el COQUEstudio:) 
\n\n 📍 La dirección es 59 #993 casi esquina 15 (espacio de OtraCasaclub). 
    \n 〰️ la coqueteria esta en el piso de arriba. si tenes dificultades para subir escaleras ¡avisanos! que nos adaptamos a tu situación 
    \n 👉🏻 venir con pelo limpio o sin productos de finalizar (cremas o geles) 
    \n ⏰ xfi tratar de ser puntual asi no hacemos esperar a los siguientes clientes. 
    \n 〰️ si venis en auto, considera el tiempo de estacionamiento! es una zona con poca disponibilidad 
    \n 👉🏻 en caso de no poder asistir, avisando con mas de 24 hs se reprograma o devuelve la seña. con menos de ese tiempo, se pierde la seña y la posibilidad de programar de nuevo el turno. ➖no avisando o cancelandouna hora antes, se deberá abonar el valor completo del turno. 
    \n  🤩 en el espacio de @otracasa_ funcionan algunos talleres y emprendimientos. también podes venir a merendar o tomar matecitos que la casa esta abierta al publico de 16 a 20hs . pasate cuando quieras a compartir el rato! 🥰
    \n\n muchas gracias, te espero!
    \n valu de @lacoque.teria 🙋🏼‍♀️`: null
    
    const encodedMessageLocal = encodeURIComponent(mensajeLocal);
    const fullUrlLocal = `${baseUrl}${phoneNumber}&text=${encodedMessageLocal}`;
    
    
    
    return (
        <div className='ArrivalsItem '>
            <p className='ArrivalsName'>{client.name}</p>

            <p className='dateTextDash'>
            <strong> {item.toUpperCase()} </strong>  
            </p>

            <p className='dateTextDash '>
             FECHA: <strong>  {day}/{month}/{year}</strong>
            </p>

            <p className='dateTextDash '>
        <strong> {time}</strong>
            </p>


            <p className='dateTextDash'>
              {service === "local" || service === "color" ? "COQUESTUDIO" : "DOMICILIO"}
            </p>

   

            {status === true ? <></> : <p className='nonewdatamini'> Cancelada</p>}

            <div className='ArrivalsButtonsCont'>
                <button className='actionButton' onClick={() => window.location = `mailto: ${client.email}`}>
                    <img className='actionImg' src="https://img.icons8.com/ios-filled/100/000000/new-post.png" />
                </button>



                <button className='actionButton'  onClick={() => window.location = service === "local" || service === "color" ? `${fullUrlLocal}`:  `${fullUrlDom}` }>
                    <img className='actionImg' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
                </button>

                <button className='actionButton' onClick={() => window.location = `tel:${Number(client.phone)}`}>
                    <img className='actionImg' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
                </button>
            </div>
        </div>

    )
}
