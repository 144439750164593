import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Book from './Book';

export default function BookList({ itemsByTime, year, month, firstDay, monthDays, weekCalendar, serviceChoose, siguiente, anterior, currentMonth, availDaysForDetail}) {
  const db = getFirestore()


  

  const [daysLoop, setDaysLoop] = useState([])

  useEffect(() => {
    const days = []

    for (var i = 1; i <= monthDays; i++) {
      days.push(i);
    }
    setDaysLoop(days)

  }, [monthDays, month])

  
  const [monthOfThisService, setMonthOfThisService] = useState(null)

  
  useEffect(() => {
    if(serviceChoose !== null && year !== null && month !== null) {
        const QueryCollection = collection(db, `services/${serviceChoose}/${year}/`)

        getDocs(QueryCollection)
            .then(resp => setMonthOfThisService(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
            .catch((err) => console.log(err))
    }

}, [serviceChoose, month, year, daysLoop])


const [serviceMonth, setServiceMonth] = useState(null)
const [notAvailability, setNotAvailability] = useState(false)

useEffect(() => {
  if(monthOfThisService !== null ) {
    setServiceMonth(monthOfThisService.filter(e => e.id == month))
} else {
  setNotAvailability(true)
}
}, [monthOfThisService, month])



  return (
    <>
          {availDaysForDetail.length === 0 ? 
                    <div className='buttonred'>
                        renuevo la agenda quincenalmente! <br /> si no hay turnos disponibles en este momento, <br /> pronto será actualizada :)
                    </div>
                    
                    : <></> }
      <div className={weekCalendar === true ? 'BookListContWeek' : availDaysForDetail.length === 0 ? 'BookListCont2 opacity50' : 'BookListCont2'}>

        <p className='monthText'>
    

          {month === 1 ? "ENERO" : month === 2 ? "FEBRERO" : month === 3 ? "MARZO" : month === 4 ? "ABRIL" : month === 5 ? "MAYO" : month === 6 ? "JUNIO" : month === 7 ? "JULIO" : month === 8 ? "AGOSTO" : month === 9 ? "SEPTIEMBRE" : month === 10 ? "OCTUBRE" : month === 11 ? "NOVIEMBRE" : month === 12 ? "DICIEMBRE" : "MES"}

          {month === currentMonth ?    
          
          <button className='button2' onClick={
            siguiente}>
          {month === 12 ? "ENERO" : month === 1 ? "FEBRERO" : month === 2 ? "MARZO" : month === 3 ? "ABRIL" : month === 4 ? "MAYO" : month === 5 ? "JUNIO" : month === 6 ? "JULIO" : month === 7 ? "AGOSTO" : month === 8 ? "SEPTIEMBRE" : month === 9 ? "OCTUBRE" : month === 10 ? "NOVIEMBRE" : month === 11 ? "DICIEMBRE" : "MES"}
          </button> 
          :    
          
          <button className='button2' onClick={
            anterior
          }>
          {month === 2 ? "ENERO" : month === 3 ? "FEBRERO" : month === 4 ? "MARZO" : month === 5 ? "ABRIL" : month === 6 ? "MAYO" : month === 7 ? "JUNIO" : month === 8 ? "JULIO" : month === 9 ? "AGOSTO" : month === 10 ? "SEPTIEMBRE" : month === 11 ? "OCTUBRE" : month === 12 ? "NOVIEMBRE" : month === 1 ? "DICIEMBRE" : "MES"}
          </button> }

        </p>
     

        <div className={weekCalendar === true ? 'displayNone' : 'BookListMonth'}>
          <p className='monthbox'>LUN</p>
          <p className='monthbox'>MAR</p>
          <p className='monthbox'>MIE</p>
          <p className='monthbox'>JUE</p>
          <p className='monthbox'>VIE</p>
          <p className='monthbox'>SAB</p>
          <p className='monthbox'>DOM</p>
        </div>

        <div className={weekCalendar === true ? 'BookListWeek' : 'BookListMonth'}>  


          {firstDay == "Tue" ? <>   <p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Wed" ? <>   <p className='pointbox'>•</p> <p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Thu" ? <>   <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p>  </> : <></>}

          {firstDay == "Fri" ? <>  <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Sat" ? <>   <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Sun" ? <>   <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p> </> : <></>}

 
          {daysLoop.map((dayloop) =>
            <Book key={dayloop} itemsByTime={itemsByTime}  day={dayloop} year={year} month={month} serviceChoose={serviceChoose} serviceMonth={serviceMonth} monthDays={monthDays}  />
          )}



        </div>
      </div>

      

 
    </>
  )
}
