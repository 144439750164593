import React, { useState, useEffect } from 'react'
import RoomCardsDetail from './RoomCardsDetail'
import { useParams } from 'react-router-dom';
import { useRoomsContext } from '../context/RoomsContext'

export default function RoomCardsDetailCont({admin, weekCalendar, customerId, detailID}) {



    const { services } = useRoomsContext()

    const [service, setService] = useState([])
    const [render, setRender] = useState(0)
  
    useEffect(() => {
      setService(services.find(e => e.id === detailID))
    }, [render])
    

    const renderF = () => {
      setRender(render + 1)
    }


    const [currentMonth, setCurrentMonth] = useState(null)

    useEffect(() => {
      function getDaysInMonth(y, m) {
          return new Date(y, m, 0).getDate();
      }

      const date = new Date();

      const monthF = date.getMonth() + 1

      setCurrentMonth(monthF)

  }, [])



  return (
    <>

    <RoomCardsDetail  detailID={detailID} service={service} renderF={renderF} currentMonth={currentMonth} />

    </>
  )
}
