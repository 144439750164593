import React, { useState, useEffect } from 'react';
import MiniCalendarItem from './MiniCalendarItem';

export default function MiniCalendarList({ unblockDay, services, allDatesMerged, blockDay, daysInCurrentMonth, monthDays, daysLoop, year, month, today, todaysMonth, todaysYear, bookings, scroll, changeWeek, render, renderF, allDatesW, servicesSchedules, serviceChosen}) {


  const [todayBookings, setTodayBookings] = useState([])




  useEffect(() => {

    if (bookings.length > 0) {
      let todayFilter = bookings.filter(e => e.date == today + "/" + todaysMonth + "/" + todaysYear)
      setTodayBookings(todayFilter)
    } else {

    }

  }, [bookings, render])


  useEffect(() => {

    const newTodayBookings = [];
    for (let hora = 9; hora < 20; hora++) {
      for (let minuto = 0; minuto < 60; minuto += 15) {
        const horaFormateada = hora.toString().padStart(2, '0');
        const minutoFormateado = minuto.toString().padStart(2, '0');
        const horario = { "time": `${horaFormateada}:${minutoFormateado}`, "client": null, "item": null };
        newTodayBookings.push(horario);
      }
    }
    setTodayBookings(prevState => prevState.concat(newTodayBookings));

  }, [render])



  return (

    <>

      <div className='BookListWeekMini'>

                
        <div className='row relative'>



          <div className='orderCardCont darkblue w3rem' >
            <p className='orderCardText whitetext'>LIBRES</p>
          </div>


        </div>

        {todayBookings && (
          todayBookings.filter((item, index, self) => {
            const firstIndex = self.findIndex((t) => t.time === item.time);
            return (firstIndex === index) || (item.client !== null);
          }).sort((a, b) => {
            if (a.time < b.time) {
              return -1;
            }
            if (a.time > b.time) {
              return 1;
            }
            return 0;
          }).map((e) =>
            <MiniCalendarItem key={e.id} {...e} render={render} allDatesMerged={allDatesMerged} unblockDay={unblockDay} blockDay={blockDay} today={today} todaysMonth={todaysMonth} todaysYear={todaysYear} allDatesW={allDatesW} renderF={renderF} services={services} servicesSchedules={servicesSchedules} serviceChosen={serviceChosen} />
          )
        )}

      </div>

    </>
  )
}
