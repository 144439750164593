import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Book from './BookAddCalendar';

export default function BookListAddCalendar({ rerenderF, render, selectDate, year, month, firstDay, monthDays, weekCalendar, serviceChoose, siguiente, anterior, currentMonth }) {
  const db = getFirestore()

  const [daysLoop, setDaysLoop] = useState([])

  useEffect(() => {
    const days = []

    for (var i = 1; i <= monthDays; i++) {
      days.push(i);
    }
    setDaysLoop(days)

  }, [monthDays, month])


  const [monthOfThisService, setMonthOfThisService] = useState(null)


  useEffect(() => {
    if (serviceChoose !== null && year !== null && month !== null) {
      const QueryCollection = collection(db, `services/${serviceChoose}/${year}/`)

      getDocs(QueryCollection)
        .then(resp => setMonthOfThisService(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
        .catch((err) => console.log(err))
    }

  }, [serviceChoose, month, year, daysLoop])


  const [serviceMonth, setServiceMonth] = useState(null)
  const [notAvailability, setNotAvailability] = useState(false)

  useEffect(() => {
    if (monthOfThisService !== null) {
      setServiceMonth(monthOfThisService.filter(e => e.id == month))
    } else {
      setNotAvailability(true)
    }
  }, [monthOfThisService, month])



  return (
    <>
      <div className={weekCalendar === true ? 'BookListContWeek' : 'BookListCont2 bggrey'}>



  <div className='column'>
      <p  className='button2'>
  {year}
  </p>
          <div className='row'>  <button className='MonthButton' onClick={
            anterior}>〈</button>

            <p className='button2'>
            {month === 1 ? "ENERO" : month === 2 ? "FEBRERO" : month === 3 ? "MARZO" : month === 4 ? "ABRIL" : month === 5 ? "MAYO" : month === 6 ? "JUNIO" : month === 7 ? "JULIO" : month === 8 ? "AGOSTO" : month === 9 ? "SEPTIEMBRE" : month === 10 ? "OCTUBRE" : month === 11 ? "NOVIEMBRE" : month === 12 ? "DICIEMBRE" : "MES"}
            </p>

            <button className='MonthButton' onClick={siguiente}>〉</button></div>

            </div>

     
          {/* {month === currentMonth ?    
          
          <button className='button2' onClick={
            siguiente}>
          {month === 12 ? "ENERO" : month === 1 ? "FEBRERO" : month === 2 ? "MARZO" : month === 3 ? "ABRIL" : month === 4 ? "MAYO" : month === 5 ? "JUNIO" : month === 6 ? "JULIO" : month === 7 ? "AGOSTO" : month === 8 ? "SEPTIEMBRE" : month === 9 ? "OCTUBRE" : month === 10 ? "NOVIEMBRE" : month === 11 ? "DICIEMBRE" : "MES"}
          </button> 
          :    
          
          <button className='button2' onClick={
            anterior
          }>
          {month === 2 ? "ENERO" : month === 3 ? "FEBRERO" : month === 4 ? "MARZO" : month === 5 ? "ABRIL" : month === 6 ? "MAYO" : month === 7 ? "JUNIO" : month === 8 ? "JULIO" : month === 9 ? "AGOSTO" : month === 10 ? "SEPTIEMBRE" : month === 11 ? "OCTUBRE" : month === 12 ? "NOVIEMBRE" : month === 1 ? "DICIEMBRE" : "MES"}
          </button> } */}

      


        <div className={weekCalendar === true ? 'displayNone' : 'BookListMonth'}>
          <p className='monthbox'>LUN</p>
          <p className='monthbox'>MAR</p>
          <p className='monthbox'>MIE</p>
          <p className='monthbox'>JUE</p>
          <p className='monthbox'>VIE</p>
          <p className='monthbox'>SAB</p>
          <p className='monthbox'>DOM</p>
        </div>

        <div className={weekCalendar === true ? 'BookListWeek' : 'BookListMonth'}>


          {firstDay == "Tue" ? <>   <p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Wed" ? <>   <p className='pointbox'>•</p> <p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Thu" ? <>   <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p>  </> : <></>}

          {firstDay == "Fri" ? <>  <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Sat" ? <>   <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p> </> : <></>}

          {firstDay == "Sun" ? <>   <p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p><p className='pointbox'>•</p> </> : <></>}


          {daysLoop.map((dayloop) =>

            <Book key={dayloop} selectDate={selectDate} day={dayloop} year={year} month={month} serviceChoose={serviceChoose} serviceMonth={serviceMonth} />
          )}



        </div>
      </div>




    </>
  )
}
