import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NewBookingsItem from './NewBookingsItem';
import { LineWobble } from '@uiball/loaders'
import BookingsWidget from '../widgets/BookingsWidget';
import RefreshButton from '../widgets/RefreshButton';
import MiniCalendarListCont from './miniCalendar/MiniCalendarListCont';

export default function NewBookings({ bookings, today, todaysMonth, todaysYear, day, month, year, customer, rerender}) {

  const [booksFromThisDay, setBooksFromThisDay] = useState([])
  const [loader, setLoader] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  }, [])


  useEffect(() => {
    let arrivalsToday = bookings.filter((e) => e.fullbookdate.day == today && e.fullbookdate.month == todaysMonth && e.fullbookdate.year == todaysYear)

    setBooksFromThisDay(arrivalsToday)
  }, [bookings])


  return (
    <div className='DashboardSectionCont'>
      <div className='dashboardWidgetsCont gap' >
      <BookingsWidget bookings={bookings} dayType="today" />
       <BookingsWidget bookings={bookings} dayType="tomorrow" />
      <MiniCalendarListCont />
      </div>

      <div className='row'>
        <p className='DashboardTitles'>Nuevas reservas</p>
        <Link className='linkArrivals' to={`/dashboard/bookings`}>
          <p className='minitextLink' > Detalles </p>
        </Link>
        <RefreshButton refreshFunction={rerender} />
      </div>


      {loader === true ? <div className='dashboardloader'>
        <LineWobble
          size={80}
          lineWeight={5}
          speed={1.75}
          color="#0010d5" />
      </div> : <div className='ArrivalsItemCont'>
        {booksFromThisDay.length === 0 ?

          <p className='nonewdata'> No hay nuevas reservas</p> :
          <>
            {booksFromThisDay.map((book) =>
              <NewBookingsItem key={book.id} {...book} />
            )}
          </>}

      </div>}




    </div>
  )
}
