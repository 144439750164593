import React, { useState, useEffect, useRef } from "react";
import {
  addDoc,
  collection,
  getFirestore,
  getDocs,
  writeBatch,
  where,
  query,
  documentId,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useCalendarContext } from "../context/CalendarContext";
import { useBookingContext } from "../context/BookingContext";
import { useRoomsContext } from "../context/RoomsContext";
import { LineWobble } from "@uiball/loaders";
import emailjs from "@emailjs/browser";
import InputCheckOut from "./InputCheckOut";
import BookedOrderModal from "../booking/BookedOrderModal";
import Logo from "../microsites/Logo";

export default function RoomCardsDetail({
  renderF,
  render,
  currentMonth,
  detailID,
}) {
  const db = getFirestore();

  const { dateList, daysInCurrentMonth, availDays, availDaysForDetail } =
    useCalendarContext();
  const { guestsTotal } = useBookingContext();
  const { services } = useRoomsContext();

  const [cartCant, setCartCant] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalPrice, settotalPrice] = useState(Number);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dni, setDni] = useState("");
  const [address, setAddress] = useState("");
  const [ig, setIg] = useState("");
  const [itemSelectedPrice, setItemSelectedPrice] = useState(Number);
  const [itemSelectedTime, setItemSelectedTime] = useState(Number);
  const [itemSelected, setItemSelected] = useState("");

  const [modal, setModal] = useState(false);
  const [checkOut, setCheckOut] = useState(false);
  const [bookOrder, setBookOrder] = useState([]);
  const [ordenmp, setOrdenmp] = useState([]);

  const [booking, setBooking] = useState(false);
  const [booked, setBooked] = useState(false);
  const [step3true, setStep3true] = useState(false);

  const [admin, setAdmin] = useState(false);
  const [itemsOfThisService, setItemsOfThisService] = useState([]);

  const [reserve, setReserve] = useState(0);
  const [newDate, setNewDate] = useState(null);
  const [dateAlreadyTaken, setDateAlreadyTaken] = useState(false);

  const [payment, setPayment] = useState("transferencia");

  const day = newDate === null ? dateList[1].substring(1) : newDate;
  const month = dateList[2];
  const year = dateList[3];

  function dataLoaded(a) {
    return new Promise((resolve, reject) => {
      if (a === undefined || a.length === 0 || a === null) {
        reject("Not ok");
      } else {
        resolve("ok");
      }
    });
  }

  const [profileData, setProfileData] = useState([]);
  const [itemsData, setItemsData] = useState(null);

  useEffect(() => {
    // OK
    const queryCol = collection(db, `profile`);

    getDocs(queryCol)
      .then((resp) =>
        setProfileData(
          resp.docs.map((bdata) => ({ id: bdata.id, ...bdata.data() }))
        )
      )
      .catch((err) => console.log(err));
  }, [render]);

  useEffect(() => {
    // OK
    const queryCol = collection(db, `items`);

    getDocs(queryCol)
      .then((resp) =>
        setItemsData(
          resp.docs.map((bdata) => ({ id: bdata.id, ...bdata.data() }))
        )
      )
      .catch();
  }, [render]);

  const [serviceSchedule, setServiceSchedule] = useState([]);
  useEffect(() => {
    dataLoaded(detailID)
      .then(() => {
        let a = services.filter((e) => e.id == detailID);
        setServiceSchedule(a[0].schedules);
      })
      .catch((e) => {});
  }, [detailID, render]);

  const [schedulesOfThisDay, setSchedulesOfThisDay] = useState(null);

  const nuevoObjeto = Object.fromEntries(
    Object.entries(dateList[4][0]).map(([key, value]) => {
      if (key.startsWith("d")) {
        return [key.slice(1), value];
      }
      return [key, value];
    })
  );

  const newDateF = (a) => {
    setNewDate(a);
    renderF();
  };

  useEffect(() => {
    dataLoaded(dateList).then(() => {
      let a = newDate === null ? dateList[1] : newDate;
      let thisDay = a;
      setSchedulesOfThisDay(dateList[4][0][thisDay]);
    });
  }, [dateList, day]);

  const [schedulesOfThisDayArr, setSchedulesOfThisDayArr] = useState(null);
  useEffect(() => {
    if (schedulesOfThisDay !== null) {
      const myArray = Object.entries(schedulesOfThisDay).map(
        ([key, value]) => ({ key, value })
      );
      setSchedulesOfThisDayArr(myArray);
    }
  }, [schedulesOfThisDay, day, dateAlreadyTaken]);

  ////////////////////////////
  ////////////////////////////
  ////////ELEGIR HORA///////
  ////////////////////////////
  ////////////////////////////

  const [time, setTime] = useState(null);

  const chooseTime = (a, b, c) => {
    function compararArrays(arrA, arrB) {
      const blocks = Math.floor(b / 15); // Redondear hacia abajo el número de bloques
 

      let hora = Number(a[0] + a[1]);
      let minuto = Number(a[2] + a[3]);

      setBlocksOfItemcheck([]);

      for (let i = 0; i < blocks; i++) {
        const horaFormateada = hora.toString().padStart(2, "0");
        const minutoFormateado = minuto.toString().padStart(2, "0");
        const horario = horaFormateada + minutoFormateado;
        blocksOfItemcheck.push(horario);

        // Agregar 15 minutos al tiempo actual
        minuto += 15;
        if (minuto === 60) {
          hora += 1;
          minuto = 0;
        }
      }
      // Verificar que ambos arrays tengan la misma longitud
      // Verificar que todos los elementos del primer array estén en el segundo
      const resultado = arrA.every((elemento) => arrB.includes(elemento));
      return resultado;
    }

    let schedulesAvail = schedulesOfThisDayArr
      .filter((e) => e.value === true)
      .map((e) => e.key);
    let check = compararArrays(blocksOfItemcheck, schedulesAvail);

    if (check === true) {
      setTime(a);
      const blocks = Math.floor(b / 15); // Redondear hacia abajo el número de bloques
      let hora = Number(a[0] + a[1]);
      let minuto = Number(a[2] + a[3]);

      for (let i = 0; i < blocks; i++) {
        const horaFormateada = hora.toString().padStart(2, "0");
        const minutoFormateado = minuto.toString().padStart(2, "0");
        const horario = horaFormateada + minutoFormateado;
        blocksOfItem.push(horario);
        // Agregar 15 minutos al tiempo actual
        minuto += 15;
        if (minuto === 60) {
          hora += 1;
          minuto = 0;
        }
      }
    } else {
      setImposible(true);
      setTimeout(() => {
        setImposible(false);
      }, 5000);
    }
  };

  const resetChooseTime = () => {
    setTime(null);
    setDateAlreadyTaken(false);
    setBlocksOfItem([]);
  };

  const [blocksOfItemcheck, setBlocksOfItemcheck] = useState([]);
  const [blocksOfItem, setBlocksOfItem] = useState([]);
  const [imposible, setImposible] = useState(false);

  ////////////////////////////
  ////////////////////////////
  ////////ELEGIR ITEM///////
  ////////////////////////////
  ////////////////////////////

  const [cart, setCart] = useState([]);

  const chooseItem = (a, b, c) => {
    setTime(null)
    cart.push({ item: a, price: b, time: c });
    setCartCant(cartCant + 1);
  };

  const deletedItem = (a) => {
    setCart(cart.filter((e) => e.item !== a));
    setTime(null)
    setCartCant(cartCant + 1);
    setBlocksOfItem([]);
  };

  useEffect(() => {
    let itemsName = cart.map(e => e.item);

 
      setItemSelected(itemsName.join(" + "));
      setItemSelectedTime(
        cart.reduce((total, objeto) => total + objeto.time, 0)
      );
      settotalPrice(
        cart.reduce((total, objeto) => total + objeto.price, 0).toFixed(2)
      );

  }, [cartCant]);


  // const resetChooseItem = () => {
  //   setItemSelected("")
  //   setItemSelectedTime(Number)
  // }

  useEffect(() => {
    setItemSelected("");
    setItemSelectedTime(Number);
    setTime(null);
    setDateAlreadyTaken(false);
    setBlocksOfItem([]);
  }, [dateList]);

  const [availBlocks, setAvailBlocks] = useState([]);

  useEffect(() => {
    const MINUTES_IN_BLOCK = 15;
    let currArray = [];
    let prevKey = null;
    let result = [];

    if (schedulesOfThisDay !== [] && schedulesOfThisDay !== null) {
      let sortedArr = schedulesOfThisDayArr
        .filter((element) => element.value === true)
        .sort((a, b) => a.key.localeCompare(b.key));

      for (let i = 0; i < sortedArr.length; i++) {
        const currKey = parseInt(sortedArr[i].key);
        const currKeyplus = parseInt(sortedArr[i].key) + 15;
        const realkey = currKeyplus.toString().includes("60");
        const realRealKey = realkey === true ? currKey + 40 : currKey;
        const realkey60 = currKeyplus.toString().includes("60");
        const realkey000 = currKey.toString().includes("000");
        const realkey00 = currKey.toString().includes("00");
        const realPrevKey =
          realkey60 === true || realkey00 === true || realkey000 === true
            ? prevKey + 40
            : prevKey;

        if (
          !prevKey ||
          Number(realRealKey) - Number(realPrevKey) === MINUTES_IN_BLOCK
        ) {
          currArray.push(sortedArr[i]);
        } else {
          result.push(currArray);
          currArray = [sortedArr[i]];
        }

        prevKey = currKey;
      }

      if (currArray.length > 0) {
        result.push(currArray);
      }

      availBlocks.push(result.map((e) => e.length));
      renderF();
    }
  }, [schedulesOfThisDayArr]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };


  const [error, setError] = useState(false);

  //// EMAILJS

  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    customer: "",
    customerEmail: "",
    customerWhatsapp: "",
    customerAddress: "",
    customerWebsite: "",
    to_name: "",
    email_to: "",
    email: "",
    clientName: "",
    price: "",
    date: "",
    service: "",
    time: "",
    item: "",
  });



  useEffect(() => {
  
      setFormData({
        to_name: "Valu",
        customer: "La Coquetería",
        customerEmail: "lacoqueteria.lp@gmail.com",
        customerWebsite: "lacoqueteria.com.ar",
        customerWhatsapp: "221-399-0248",
        customerAddress: "Calle 59 993, OtraCasaClub, La Plata ",
        email_to: email,
        clientName: name,
        price: totalPrice,
        date: day + "/" + month + "/" + year,
        service: detailID,
        time:
          time !== null ? time[0] + time[1] + ":" + time[2] + time[3] : "0000",
        item: itemSelected,
      });
    
  }, [totalPrice, name, time, itemSelected, email]);

  const handleChange2 = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  
  const emailJsF = () => {
    emailjs
      .sendForm(
        "lacoqueteria",
        "template_1wiqe1a",
        formRef.current,
        "5HliEyZdhFxxuTp9H",
        { variables: formData }
      )
      .then((response) => {
        if (response.status === 200) {
        } else {
        }
      });
  };

  // VALIDACION DE DISPONIBILIDAD
  let aDate = newDate === null ? dateList[1].substring(1) : newDate;
  let dateAndHour = dateList[1];

  const validationFunction = async () => {
    const results = [];
    await Promise.all(
      blocksOfItem.map(async (e) => {
        const arr = [month.toString()];
        const queryCol = collection(db, `services/${detailID}/${dateList[3]}/`);

        const queryData = await getDocs(queryCol).then((querySnapshot) => {
          const docsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return { id: detailID, docsData };
        });

        const monthData = queryData.docsData.filter(
          (item) => item.name === arr[0]
        );

        if (
          monthData.length > 0 &&
          monthData[0][dateAndHour] &&
          monthData[0][dateAndHour][e] !== undefined
        ) {
          results.push(monthData[0][dateAndHour][e]);
        }
      })
    );

    return results;
  };

  const checkOutPayment = async () => {
    const date = new Date();
    setBooking(true);
    async function batchh() {
      try {
        const validationData = await validationFunction();
        const queryColB = collection(db, `bookings`);

        const queryDataB = await getDocs(queryColB).then((querySnapshot) => {
          const docsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return { docsData };
        });

        const bookingsData = queryDataB.docsData.filter(
          (book) =>
            book.date == day + "/" + month + "/" + year && book.status === true
        );

        let blocksFounded =
          bookingsData.length > 0
            ? bookingsData.map((e) => e.blockTimes).flat()
            : false;

        let blocksComparison = blocksFounded
          ? blocksFounded.some((elemento) => blocksOfItem.includes(elemento))
          : false;

        if (
          validationData.every((e) => e === true) &&
          blocksComparison === false
        ) {
          let book = {};

          book.idCode = parseInt(
            (Math.random() * 10000000 + Math.random() * 10000000) /
              Math.random(1, 50)
          );
          book.client = { name, email, phone, dni, ig };
          book.item = itemSelected;
          book.total = totalPrice;
          book.subtotal = 1500;
          book.paid = false;
          book.fullbookdate = {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
          };
          book.hour = date.getHours() + ":" + date.getMinutes();
          book.status = true;
          book.timestamp = Date.now();
          book.blockTimes = blocksOfItem;
          book.service = detailID;
          book.time = time[0] + time[1] + ":" + time[2] + time[3];
          book.date = day + "/" + month + "/" + year;
          book.day = day;
          book.month = month;
          book.address = address;
          book.year = year;
          book.blocks = itemSelectedTime / 15;
          book.payment = payment;
          book.transfer = false;
          sessionStorage.setItem("bookClient", JSON.stringify(book));

          const queryCollection = collection(db, `bookings`);
          addDoc(queryCollection, book)
            .then((res) => console.log(res))
            .catch((err) => console.log(err));

          services.forEach((service) => {
            blocksOfItem.forEach(async (e) => {
              const arr = [month.toString()];
              const queryCol = collection(
                db,
                `services/${service.id}/${dateList[3]}/`
              );
              const batch = writeBatch(db);
              const updateId = await query(
                queryCol,
                where(documentId(), "in", arr)
              );

              let aDate = newDate === null ? dateList[1].substring(1) : newDate;

              await getDocs(updateId).then((resp) =>
                resp.docs.forEach((res) => {
                  batch.update(res.ref, { ["d" + aDate + "." + e]: false });
                })
              );
              await batch.commit();
            });
          });

          setTimeout(() => {
            setBooking(false);
            setBooked(true);
          }, 3000);

          emailJsF();
        } else {
          setDateAlreadyTaken(true);

          setTimeout(() => {
            setDateAlreadyTaken(false)
          }, 3000);

           // Bloqueo de horarios
        services.forEach((service) => {
          blocksOfItem.forEach(async (e) => {
            const arr = [month.toString()];
            const queryCol = collection(
              db,
              `services/${service.id}/${dateList[3]}/`
            );
            const batch = writeBatch(db);
            const updateId = await query(
              queryCol,
              where(documentId(), "in", arr)
            );

            let aDate = newDate === null ? dateList[1].substring(1) : newDate;

            await getDocs(updateId).then((resp) =>
              resp.docs.forEach((res) => {
                batch.update(res.ref, { ["d" + aDate + "." + e]: false });
              })
            );
            await batch.commit();
          });
        });


          setBooking(false);
        }
      } catch (error) {
        console.error(
          "Ocurrió un error durante la validación o el lote de escritura:",
          error
        );
        setBooking(false);
      }
    }
    batchh();
  };

  const openModal = () => {
    setModal(true);
    setStep3true(true);
    window.scrollTo(0, 0);
  };

  const closeModal = () => {
    setModal(false);
  };

  const showCheckOut = () => {
    setCheckOut(true);
  };

  useEffect(() => {
    if (booked === true) {
      showCheckOut();

      setTimeout(() => {
        window.scrollTo(0, 1000);
      }, 250);
    }
  }, [booked]);

  useEffect(() => {
    let thiserv = services.filter((e) => e.id == detailID);
    if (thiserv.length > 0) {
      let thiservItems = thiserv[0].items;
      setItemsOfThisService(thiservItems);
    }
  }, [detailID, itemsData, services, day]);

  const choosePayment = (a) => {
    setPayment(a);
  };

  // MERCADOPAGO

  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer APP_USR-5337825040898144-041711-10d8f61c8053ebbeec9c5223f3394446-272278393`,
    },
    body: JSON.stringify({
      items: [
        {
          title: "Reserva La Coquetería",
          description: "¡Gracias por tu reserva!",
          picture_url:
            "https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Flogo.jpg?alt=media&token=8d13d404-2cbf-48d9-8e14-578f9f4f5b74",
          category_id: "coqueteria",
          quantity: 1,
          currency_id: "ARS",
          unit_price: parseInt(5000),
        },
      ],
      auto_return: "approved",
      back_urls: { success: `https://lacoqueteria.com.ar/orden` },
    }),
  };

  useEffect(() => {
    //getFetch();
    fetch("https://api.mercadopago.com/checkout/preferences", data)
      .then(function (resp) {
        return resp.json();
      })
      .then((resp) => setOrdenmp(resp));
  }, [checkOut]);

  useEffect(() => {
    setBookOrder(JSON.parse(sessionStorage.getItem("bookClient")));
  }, [checkOut]);

  useEffect(() => {
    setAdmin(JSON.parse(sessionStorage.getItem("adminToken")));
  }, []);

  const [availDaysOfThisMonth, setAvailDaysOfThisMonth] = useState([]);
  var newDay = new Date();
  var today = newDay.getDate();
  useEffect(() => {
    if (month === currentMonth) {
      setAvailDaysOfThisMonth(availDays[0].filter((e) => e >= today));
    } else {
      setAvailDaysOfThisMonth(availDays[0]);
    }
  }, [availDays, month, currentMonth]);

  const [itemsCheck, setItemsCheck] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      if (itemsData !== null) {
        let j = itemsData
          .filter((item) => itemsOfThisService.includes(item.id))
          .filter(
            (e) =>
              e.time / 15 <= Math.max(...availBlocks[availBlocks.length - 1])
          );
        setItemsCheck(j.length);
      }
    }, 100);
  }, [day]);

  // Calcular cuántos bloques necesitas
  const blocksNeeded = Math.ceil(itemSelectedTime / 15);

  let suma = 0;
  const cartBlocks = (a) => {
    for (let i = 0; i < a.length; i++) {
      return (suma += a[i].valor);
    }
  };
  const blocksNeededCart = Math.ceil(
    cart.reduce((total, objeto) => total + objeto.time, 0) / 15
  );

  // Filtrar y ordenar los horarios disponibles
  function filterAvailableTimes(schedules, blocksNeeded) {
    const availableTimes = schedules
      .filter((time) => time.value === true)
      .map((time) => time.key)
      .sort();

    const eligibleTimes = [];

    for (let i = 0; i < availableTimes.length; i++) {
      const current = availableTimes[i];
      const blocksInRange = [current];
      let nextTime = current;

      for (let j = 1; j < blocksNeeded; j++) {
        nextTime = add15Minutes(nextTime);
        if (!availableTimes.includes(nextTime)) {
          break;
        }
        blocksInRange.push(nextTime);
      }

      if (blocksInRange.length === blocksNeeded) {
        eligibleTimes.push(current);
      }
    }

    return eligibleTimes;
  }

  function add15Minutes(time) {
    const hours = parseInt(time.substring(0, 2));
    const minutes = parseInt(time.substring(2));
    const nextMinutes = minutes + 15;
    if (nextMinutes === 60) {
      return `${(hours + 1).toString().padStart(2, "0")}00`;
    }
    return `${hours.toString().padStart(2, "0")}${nextMinutes
      .toString()
      .padStart(2, "0")}`;
  }

  const eligibleTimes =
    schedulesOfThisDayArr && blocksNeededCart
      ? filterAvailableTimes(schedulesOfThisDayArr, blocksNeededCart)
      : [];

  return (
    <>
      {loading === true ? (
        <div className="loader column">
          <LineWobble size={300} lineWeight={8} speed={2.5} color="#ffb1ec" />
        </div>
      ) : (
        <>
          <div className="RoomCardsDetailCont">
            {/* {itemSelected.length > 1 ?
                <></>

                : <>
                  {dateList && (
                    <div className='dateTopText'>
                      <br />
                      {availDaysForDetail.filter(e => e !== day && e >= today).map((e) =>
                        <button onClick={() => {
                          newDateF(e)
                        }} key={e.id} className='button2'> {e} </button>
                      )}
                    </div>
                  )}

                </>} */}
            {cart ? <div className="cart-cont"></div> : <></>}

            {itemsCheck === 0 ? (
              <h2 className="RoomCardsDetailTittle ">
                {" "}
                no hay horarios disponibles en este día!{" "}
              </h2>
            ) : (
              <>
                {dateList && (
                  <div className="dateTopText">
                    <p>estás reservando para el </p>
                    <span className="button2 biggerButton2">
                      {day}/{dateList[2]}/{dateList[3]}
                    </span>
                  </div>
                )}
              {!time ? 
              
              <>
                              <h2 className="RoomCardsDetailTittle ">
                  {" "}
                  ¿qué vamos a hacer?{" "}
                </h2>
              </>
              :
              <>
                                           <h2 className="RoomCardsDetailTittle ">
                  {" "}
      
                </h2>
              </>
            
            }

                <div className="chooseHaircutButtonCont">
                  
                  {itemsData && !time && (
                    <>
                    {itemsData
                    .filter((item) => itemsOfThisService.includes(item.id))
   
                    .map((e, index) =>  {

                      let notavail = e.time / 15 <= Math.max(...availBlocks[availBlocks.length - 1]);

                    return (
<div className="column relative" key={index}>
  {!notavail ? <p className="notavailincartad"> <img width="14" height="14" src="https://img.icons8.com/material-outlined/24/ffffff/time.png" alt="time"/> No disponible </p>  : <></>}

            <button
              className={`chooseHaircutButton ${cart.map((item) => item.item).includes(e.name)
                ? "chooseHaircutButtonColor"
                : ""} ${!notavail ? "notavailincart" : ""}`}
              onClick={
                cart.map((item) => item.item).includes(e.name) || !notavail
                  ? undefined
                  : () => {
                      chooseItem(e.name, e.price, e.time);
                    }
              }
            >
              {e.name.toUpperCase()} -{" "}
              <b>
                {e.name === "iluminar-oscurecer: mechas balayage" ||
                e.name === "peinado de melenas para eventos: modelado finger coil rulos"
                  ? "DESDE"
                  : ""}{" "}
                ${e.price}
              </b>
            </button>
            {cart.map((item) => item.item).includes(e.name) && notavail && (
              <button
                className="deleteHaircutButton"
                onClick={() => deletedItem(e.name)}
              >
                <img
                  width="17"
                  height="17"
                  src="https://img.icons8.com/fluency-systems-filled/96/ffffff/delete.png"
                  alt="filled-trash"
                />
              </button>
            )}
          </div>
                    )} )}
                    </>
                  )}

                  {cart.length > 0 && (
                    <>
                      <div className="row">
                        {" "}
                        <button className="chooseTimeButton2">
                          {" "}
                          <strong>{itemSelected.toUpperCase()}</strong>
                          <br /> TIENE UNA DURACIÓN DE <br />{" "}
                          <strong>{itemSelectedTime} MINUTOS</strong> <br /> Y UN PRECIO  DE <br /> <b>${totalPrice} </b>
                        </button>
                      </div>
                    </>
                  )}

                  {cart.length > 0 && (
                    <>
                      <h2 className="RoomCardsDetailTittle ">
                        {" "}
                        elegí la hora{" "}
                      </h2>
                      {/* {imposible === true ? (
                        <>
                          <p className="reselectButton imposibleButton">
                            no podés elegir este horario, ya que el tiempo
                            requerido supera la disponibilidad. por favor, elegí
                            un horario acorde a la duración del servicio.
                          </p>
                        </>
                      ) : (
                        <></>
                      )} */}
                      <div className="chooseTimeButtonCont">
                        {time !== null ? (
                          <>
                            <div className="row">
                              {" "}
                              <button className="chooseTimeButton">
                                {" "}
                                ELEGISTE EL TURNO DE LAS{" "}
                                <strong>
                                  {time[0]}
                                  {time[1]}:{time[2]}
                                  {time[3]}{" "}
                                </strong>
                              </button>
                              {time !== null && booked === false ? (
                                <button
                                  onClick={resetChooseTime}
                                  className="button2"
                                >
                                  {" "}
                                  cambiar{" "}
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {schedulesOfThisDayArr && (
                              <>
                                {eligibleTimes.length === 0 ? (
                                  <p className="chooseHaircutText">
                                    no alcanza el tiempo disponible para tantas
                                    cosas!{" "}
                                  </p>
                                ) : (
                                  <>
                                    {eligibleTimes.map((e) => (
                                      <button
                                        className="chooseTimeButton"
                                        onClick={() => {
                                          chooseTime(
                                            e,
                                            itemSelectedTime,
                                            itemSelectedPrice
                                          );
                                        }}
                                        key={e.key}
                                      >
                                        {" "}
                                        {e[0]}
                                        {e[1]}:{e[2]}
                                        {e[3]}{" "}
                                      </button>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <img
              className="imgSeparador"
              src="https://img.icons8.com/ios-filled/100/0010d5/barber-scissors.png"
            />

            {time && (
              <div className="ContactInfoCont">
                <form
                  className="formAgregarProducto"
                  action=""
                  ref={formRef}
                  onSubmit={handleSubmit}
                >
                  <p className="RoomCardsDetailTittle lighter">
                    {" "}
                    dejá tus datos para reservar!{" "}
                  </p>
                  <div className="noShowForm">
                    <input
                      type="email"
                      name="email_to"
                      value={formData.email_to}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="to_name"
                      value={formData.to_name}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="room"
                      value={formData.room}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="price"
                      value={formData.price}
                      onChange={handleChange2}
                    />
                  </div>

                  <p className="RoomCardsDetailDescription positionR">
                    * requerido
                  </p>
                  <InputCheckOut
                    place="Nombre y apellido *"
                    onchange={(e) => setName(e.target.value)}
                  />
                  <InputCheckOut
                    place="Email *"
                    onchange={(e) => setEmail(e.target.value)}
                  />
                  <InputCheckOut
                    place="Teléfono *"
                    onchange={(e) => setPhone(e.target.value)}
                  />
                  <InputCheckOut
                    place="DNI/ID *"
                    onchange={(e) => setDni(e.target.value)}
                  />
                  <InputCheckOut
                    place="Instagram *"
                    onchange={(e) => setIg(e.target.value)}
                  />
                  {detailID !== "local" ? (
                    <InputCheckOut
                      place="Dirección *"
                      onchange={(e) => setAddress(e.target.value)}
                    />
                  ) : null}

                  <p className="RoomCardsDetailDescription">
                    con tu DNI/ID podrás hacer un seguimiento de tu reserva.{" "}
                  </p>

                  <div className="noShowForm">
                    <input
                      type="email"
                      name="email_to"
                      value={formData.email_to}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="email_client"
                      value={formData.email}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="to_name"
                      value={formData.to_name}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="clientName"
                      value={formData.clientName}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="price"
                      value={formData.price}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="date"
                      value={formData.date}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="service"
                      value={formData.service}
                      onChange={handleChange2}
                    />
                    <input
                      type="text"
                      name="time"
                      value={formData.time}
                      onChange={handleChange2}
                    />
                    <input
                      type="text"
                      name="item"
                      value={formData.item}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="customer"
                      value={formData.customer}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="customerWhatsapp"
                      value={formData.customerWhatsapp}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="customerAddress"
                      value={formData.customerAddress}
                      onChange={handleChange2}
                    />

                    <input
                      type="text"
                      name="customerWebsite"
                      value={formData.customerWebsite}
                      onChange={handleChange2}
                    />
                    <input
                      type="text"
                      name="customerEmail"
                      value={formData.customerEmail}
                      onChange={handleChange2}
                    />
                  </div>

                  {dateAlreadyTaken === true ? (
                    <Link to="/" className="submitButton margintop">
                      el horario ya no está disponible! probá con otro
                    </Link>
                  ) : (
                    <>
                      {payment === null ||
                      name.length <= 1 ||
                      email.length <= 1 ||
                      phone.length <= 1 ||
                      dni.length <= 1 ||
                      ig.length <= 1 ? (
                        <></>
                      ) : (
                        <button
                          type="submit"
                          className={
                            booked === true
                              ? "submitButtonBooked margintop"
                              : "submitButton margintop"
                          }
                          onClick={
                            booked === true || booking === true
                              ? null
                              : checkOutPayment
                          }
                        >
                          {" "}
                          {booking === true ? (
                            <div className="checkedoutBook">
                              <LineWobble
                                size={80}
                                lineWeight={5}
                                speed={1.75}
                                color="white"
                              />
                            </div>
                          ) : booked === true ? (
                            <img
                              className="checkSubmitTick"
                              src="https://img.icons8.com/external-regular-kawalan-studio/96/000000/external-tick-user-interface-regular-kawalan-studio.png"
                              alt=""
                            />
                          ) : (
                            "RESERVAR"
                          )}
                        </button>
                      )}
                    </>
                  )}
                </form>

                {payment === null &&
                name.length > 1 &&
                email.length > 1 &&
                phone.length > 1 &&
                dni.length > 1 &&
                ig.length > 1 ? (
                  <>
                    <div className="column ">
                      <p className="button2">elegí el medio de pago</p>
                      <div className="row">
                        <button
                          onClick={() => {
                            choosePayment("mercadopago");
                          }}
                          className="button margin"
                        >
                          {" "}
                          mercadopago{" "}
                        </button>
                        <button
                          onClick={() => {
                            choosePayment("transferencia");
                          }}
                          className="button margin"
                        >
                          {" "}
                          transferencia{" "}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {name.length > 1 &&
                email.length > 1 &&
                phone.length > 1 &&
                dni.length > 1 &&
                ig.length > 1 ? (
                  <></>
                ) : (
                  <div className="column margintop">
                    <p className="buttonbyn">completá los datos requeridos</p>
                  </div>
                )}
              </div>
            )}

            {admin === true || reserve === 0 ? (
              <>
                {/* 
                    {checkOut === true && payment == "mercadopago" ? <div className='CheckOutCont'>

<p className='RoomCardsDetailTittle lighter'>con una seña reservás tu lugar!</p>
<p className='RoomCardsDetailDescription pink'><b>*en caso de que no puedas asistir, avisando con mas de 24hs se reprograma el turno o devuelve la seña. con menos de ese tiempo, se pierde el dinero y la chance de cambiar tu día.</b></p>

<div className='checkoutPayOpCont'>
  <div className='checkoutPaymentContOptions'>
    <a href={ordenmp.init_point} className="ButtonNone" >
      <img className='checkOutImgSmall' src="https://www.mgscreativa.com/images/stories/virtuemart/product/logo-mercadopago9.png" alt="" />
    </a>
  </div>
</div>
</div>  : <></>} */}

                <p className="RoomCardsDetailTittle lighter">
                  con una seña de <b> $5000</b>
                  <br />
                  reservás tu lugar!
                </p>
                <p className="RoomCardsDetailDescription pink">
                  <b>
                    *en caso de no poder asistir, avisando con mas de 24 hs se
                    reprograma o devuelve la seña. con menos de ese tiempo, se
                    pierde la seña y la posibilidad de programar de nuevo el
                    turno. ➖no avisando o cancelando una hora antes, se deberá
                    abonar el valor completo del turno.
                  </b>
                </p>

                {checkOut === true && payment == "transferencia" ? (
                  <div className="CheckOutCont">
                    <div className="checkoutPayOpCont">
                      <div className="checkoutPaymentContOptions">
                        <button
                          type="submit"
                          className="button"
                          onClick={() => {
                            openModal();
                          }}
                        >
                          DETALLE DE ORDEN Y DATOS BANCARIOS
                        </button>
                      </div>

                      <p className="RoomCardsDetailDescription padding">
                        {" "}
                        podés pagar la reserva con transferencia bancaria. <b>si no abonás, queda a mi disposición la cancelación de la misma en cualquier momento -en este caso, te avisaré-.</b>de cualquier otra forma, la reserva permanece <b>vigente.</b> si deseás cancelar el turno, podés hacerlo desde "mis reservas", en la sección "gestioná tu turno" del inicio.
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {modal === false ? (
              <></>
            ) : (
              <BookedOrderModal
                modal={modal}
                closeModal={closeModal}
                day={day}
                month={month}
                year={year}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
