import React, { useState, useEffect } from 'react';
import { setDoc, doc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import { Link } from 'react-router-dom';



export default function RoomsEditDetail({ services, name, id, enabled, renderized, render, rerender }) {

  const db = getFirestore()
 

  const [roomMonthsNY, setRoomMonthsNY] = useState([])
  const [roomMonths, setRoomMonths] = useState([])
  const [roomMonthsNYsorted, setRoomMonthsNYsorted] = useState([])
  const [roomMonthssorted, setRoomMonthssorted] = useState([])
  const [openMonthEditorSwitch, setOpenMonthEditorSwitch] = useState(false)
  const [thisyear, setThisyear] = useState()
  const [year, setYear] = useState()
  const [month, setMonth] = useState()
  const [loader, setLoader] = useState(true)


  useEffect(() => {
    setLoader(true)

    setTimeout(() => {
      setLoader(false)
    }, 2000);
  }, [])

  const [items, setItems] = useState([])

  useEffect(() => {
    const QueryCollection = collection(db, `items`)
    getDocs(QueryCollection)
        .then(resp => setItems(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
        .catch((err) => console.log(err))

}, [render])



  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear())
    setThisyear(date.getFullYear())
  }, [])

  const openMonthEditor = () => {
    if (openMonthEditorSwitch === true) {
      setOpenMonthEditorSwitch(false)
    } else {
      setOpenMonthEditorSwitch(true)
    }
  }
  const getMonths = () => {
    function saveMonths(a, set) {
      const queryCol = collection(db, `services/${id}/${a}`)

      getDocs(queryCol)
        .then(resp => set(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
        .catch((err) => console.log(err))
    }

    saveMonths(year, setRoomMonths)
    saveMonths((year + 1), setRoomMonthsNY)
  }

  useEffect(() => {
    setRoomMonthsNYsorted(roomMonthsNY.map(e => e.id).sort((a, b) => a - b))
    setRoomMonthssorted(roomMonths.map(e => e.id).sort((a, b) => a - b))
  }, [roomMonthsNY, renderized])


  const localDates = {   
    "0800": true,
    "0900": true,
  }

  const domicilioDates = {   
    "0800": true,
    "0900": true,
  }





  const addMonth = () => {// This function adds the month to the calendar in firebase.

    render()
    if ((id == "local") && (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12)){

      setTimeout(() => {
        const roomRef = doc(db, `services`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": localDates, "d2": localDates , "d3": localDates , "d4": localDates , "d5": localDates , "d6": localDates , "d7": localDates , "d8": localDates , "d9": localDates , "d10": localDates , "d11": localDates , "d12": localDates , "d13": localDates , "d14": localDates , "d15": localDates , "d16": localDates , "d17": localDates , "d18": localDates , "d19": localDates , "d20": localDates , "d21": localDates , "d22": localDates , "d23": localDates , "d24": localDates , "d25": localDates , "d26": localDates , "d27": localDates , "d28": localDates , "d29": localDates , "d30": localDates , "d31": localDates 
        }
        )
      }, 1000);
    } if ((id == "local") &&( month == 4 || month == 6 || month == 9 || month == 11)) {
      setTimeout(() => {
        const roomRef = doc(db, `services`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": localDates , "d2": localDates , "d3": localDates , "d4": localDates , "d5": localDates , "d6": localDates , "d7": localDates , "d8": localDates , "d9": localDates , "d10": localDates , "d11": localDates , "d12": localDates , "d13": localDates , "d14": localDates , "d15": localDates , "d16": localDates , "d17": localDates , "d18": localDates , "d19": localDates , "d20": localDates , "d21": localDates , "d22": localDates , "d23": localDates , "d24": localDates , "d25": localDates , "d26": localDates , "d27": localDates , "d28": localDates , "d29": localDates , "d30": localDates
        }
        )
      }, 1000);
    } if ((id == "local") && month == 2) {
      setTimeout(() => {
        const roomRef = doc(db, `services`, id)

        setDoc(doc(roomRef, year, month), {
          "d1": localDates , "d2": localDates , "d3": localDates , "d4": localDates , "d5": localDates , "d6": localDates , "d7": localDates , "d8": localDates , "d9": localDates , "d10": localDates , "d11": localDates , "d12": localDates , "d13": localDates , "d14": localDates , "d15": localDates , "d16": localDates , "d17": localDates , "d18": localDates , "d19": localDates , "d20": localDates , "d21": localDates , "d22": localDates , "d23": localDates , "d24": localDates , "d25": localDates , "d26": localDates , "d27": localDates , "d28": localDates , "d29": localDates
        }
        )
      }, 1000);
    }
  }



  const roomEdit = [id]


  const editProp = async (a, b) => {

    const batch = writeBatch(db)

    const queryCol = collection(db, `services`)
    const queryRooms = await query(
      queryCol,
      where(documentId(), 'in', roomEdit.map(e => e)))

    await getDocs(queryRooms)
      .then(resp => resp.docs.forEach(((res) => {
        batch.update(res.ref, { [a]: b })
      })))

    await batch.commit()

    render()
  }


  return (

    <>

      <div className='column center'>
        <Link className='button absoluteTop' to={`/dashboard/agendas`}>
         ⬸ Regresar
        </Link>


       
    
            <div className='roomeditCard'>

 
  

               <Link className='button biggerButton' to={`/dashboard/${id}/schedule`}>
                Modificar items
               </Link>

               <Link className='button biggerButton' to={`/dashboard/addcalendar/${id}`}>
                Agregar horarios
               </Link>
  
    


            </div>

         

     

  

      </div>
    </>
  )
}
