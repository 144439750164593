import React from 'react'
import AgendaItemCont from './AgendaItemCont'

export default function AgendaItemList({ bookings, pushEmail }) {

    const abc = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "ñ", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]

    return (
        <div className='agendaListCont'>
            {abc.map((e) =>
                <AgendaItemCont key={e} bookings={bookings} letter={e} pushEmail={pushEmail} />
            )}
        </div>

    )
}
