import React, { useState, useEffect } from 'react';
import WeekCalendarItem from './WeekCalendarItem'

export default function WeekCalendarList({ unblockDay, services, allDatesMerged, blockDay, daysInCurrentMonth, monthDays, daysLoop, year, month, today, todaysMonth, todaysYear, bookings, scroll, changeWeek, render, renderF, allDatesW, servicesSchedules, serviceChosen}) {


  const [todayBookings, setTodayBookings] = useState([])



  useEffect(() => {
    if (bookings.length > 0) {
      let todayFilter = bookings.filter(e => e.date == today + "/" + todaysMonth + "/" + todaysYear)
      setTodayBookings(todayFilter)
    } else {

    }

  }, [bookings, render])



  useEffect(() => {

    const newTodayBookings = [];
    for (let hora = 9; hora < 20; hora++) {
      for (let minuto = 0; minuto < 60; minuto += 15) {
        const horaFormateada = hora.toString().padStart(2, '0');
        const minutoFormateado = minuto.toString().padStart(2, '0');
        const horario = { "time": `${horaFormateada}:${minutoFormateado}`, "client": null, "item": null };
        newTodayBookings.push(horario);
      }
    }
    setTodayBookings(prevState => prevState.concat(newTodayBookings));

  }, [render])

console.log(todayBookings)
  return (

    <>

      <div className='BookListWeek'>

                 

        <div className='row relative'>



          <div className='orderCardCont darkblue w3rem' >
            <p className='orderCardText whitetext'>HORA</p>
          </div>

                    
        <div className='orderCardCont darkblue w5rem2' >
            <p className='orderCardText whitetext'>SERVICIO</p>
          </div>

          <div className='orderCardCont darkblue' >
            <p className='orderCardText whitetext'>CLIENTE</p>
          </div>

          <div className='orderCardCont darkblue' >
            <p className='orderCardText whitetext'>ITEM</p>
          </div>

          <div className='orderCardCont darkblue w5rem2' >
            <p className='orderCardText whitetext'>TIEMPO</p>
          </div>

          
          <div className='orderCardCont w2rem darkblue' >
            <p className='orderCardText whitetext'>MAPS</p>
          </div>

          <div className='orderCardCont w2rem darkblue' >
            <p className='orderCardText whitetext'>WA</p>
          </div>

          <div className='orderCardCont w2rem darkblue' >
            <p className='orderCardText whitetext'>IG</p>
          </div>

        </div>

        {todayBookings && (
          todayBookings.filter((item, index, self) => {
            const firstIndex = self.findIndex((t) => t.time === item.time);
            return (firstIndex === index) || (item.client !== null);
          }).sort((a, b) => {
            if (a.time < b.time) {
              return -1;
            }
            if (a.time > b.time) {
              return 1;
            }
            return 0;
          }).map((e) =>
            <WeekCalendarItem key={e.id} {...e} render={render} allDatesMerged={allDatesMerged} unblockDay={unblockDay} blockDay={blockDay} today={today} todaysMonth={todaysMonth} todaysYear={todaysYear} allDatesW={allDatesW} renderF={renderF} services={services} servicesSchedules={servicesSchedules} serviceChosen={serviceChosen} />
          )
        )}

      </div>

    </>
  )
}
