import React from 'react'

export default function AgendaItem({ client, pushEmail }) {

    const pushEmailF = () => {
        pushEmail(client.email)
    }


    return (
        <div className='agendaItemCont'>
            <p className='contactName'>{client.name}</p>

            <button className='actionButton' onClick={pushEmailF}>
            <img className='actionImgAgenda' src="https://img.icons8.com/ios-filled/100/000000/add-message.png" />

            </button>
   
            <button className='actionButton' onClick={() => window.location = `mailto: ${client.email}`}>
                <img className='actionImgAgenda' src="https://img.icons8.com/ios-filled/100/000000/new-post.png" />
            </button>

            <button className='actionButton' onClick={() => window.location = `https://wa.me/${Number(client.phone)}`}>
                <img className='actionImgAgenda' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
            </button>

            <button className='actionButton' onClick={() => window.location = `tel:${Number(client.phone)}`}>
                <img className='actionImgAgenda' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
            </button>
        </div>
    )
}
