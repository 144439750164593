
import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import NewCalendarDay from './NewCalendarDay';
import RoomCardsDetailCont from '../rooms/RoomCardsDetailCont';
import { Link } from 'react-router-dom';


export default function NewCalendarListCont({ itemsByTime, year, month, dateList, addToDates, toZero, firstDay, monthDays, weekCalendar, serviceChoose, siguiente, anterior, currentMonth, availDaysForDetail }) {
    const db = getFirestore()

    var urlActual = new URL(window.location.href);
    var urlParams = urlActual.pathname;
    var params = urlParams.substring(1);

    const [monthOfThisService, setMonthOfThisService] = useState([])


    useEffect(() => {
        if (serviceChoose !== null && year !== null && month !== null) {
            const QueryCollection = collection(db, `services/${serviceChoose}/${year}/`)
            getDocs(QueryCollection)
                .then(resp => setMonthOfThisService(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
                .catch((err) => console.log(err))
        }

    }, [serviceChoose, month, year])


    const [serviceMonth, setServiceMonth] = useState(null)
    const [notAvailability, setNotAvailability] = useState(false)

    useEffect(() => {
        if (monthOfThisService !== null) {
            setServiceMonth(monthOfThisService.filter(e => e.id == month))
        } else {
            setNotAvailability(true)
        }
    }, [monthOfThisService, month])



    // Crear un array de objetos a partir del objeto de entrada
    const arrayOfDays = serviceMonth && serviceMonth.length > 0 ? Object.keys(serviceMonth[0])
        .filter(key => key !== "id") // Filtrar para excluir la propiedad "id"
        .map(key => ({ day: key, data: serviceMonth[0][key] })) : []



    const [daySelected, setDaySelected] = useState(null)

    const handleInputChange = (e) => {
        setDaySelected(e.target.value);
    };



    const addToDatesF = () => {
        if (daySelected) {
            addToDates([serviceChoose, daySelected, month, year, serviceMonth])
        }
    }

    const toZeroDate = () => {
        addToDates([])

    }


    const [totalAvail, setTotalAvail] = useState(true)

    const totalAvailCheck = (a) => {
        totalAvail.push(a)
    }


    return (
        <>
            {availDaysForDetail.length === 0 ?
                <div className='buttonred'>
                    renuevo la agenda quincenalmente! <br /> si no hay turnos disponibles en este momento, <br /> pronto será actualizada :)
                </div>

                : <></>}
            <div className={weekCalendar === true ? 'BookListContWeek' : availDaysForDetail.length === 0 ? 'BookListCont' : 'BookListCont'}>
                <button className='button2 marginauto2' onClick={() => {
                    toZero(); toZeroDate()
                }}>  VOLVER </button>
                <p className='monthText'>


                    {month === 1 ? "ENERO" : month === 2 ? "FEBRERO" : month === 3 ? "MARZO" : month === 4 ? "ABRIL" : month === 5 ? "MAYO" : month === 6 ? "JUNIO" : month === 7 ? "JULIO" : month === 8 ? "AGOSTO" : month === 9 ? "SEPTIEMBRE" : month === 10 ? "OCTUBRE" : month === 11 ? "NOVIEMBRE" : month === 12 ? "DICIEMBRE" : "DICIEMBRE"}

                    {month === currentMonth ?

                        <button className='button2' onClick={
                            siguiente}>
                            {month === 12 ? "ENERO" : month === 1 ? "FEBRERO" : month === 2 ? "MARZO" : month === 3 ? "ABRIL" : month === 4 ? "MAYO" : month === 5 ? "JUNIO" : month === 6 ? "JULIO" : month === 7 ? "AGOSTO" : month === 8 ? "SEPTIEMBRE" : month === 9 ? "OCTUBRE" : month === 10 ? "NOVIEMBRE" : month === 11 ? "DICIEMBRE" : "ENERO"}
                        </button>
                        :

                        <button className='button2' onClick={
                            anterior
                        }>
                            {month === 2 ? "ENERO" : month === 3 ? "FEBRERO" : month === 4 ? "MARZO" : month === 5 ? "ABRIL" : month === 6 ? "MAYO" : month === 7 ? "JUNIO" : month === 8 ? "JULIO" : month === 9 ? "AGOSTO" : month === 10 ? "SEPTIEMBRE" : month === 11 ? "OCTUBRE" : month === 12 ? "NOVIEMBRE" : month === 1 ? "DICIEMBRE" : "ENERO"}
                        </button>}

                </p>


                <div className={weekCalendar === true ? 'BookListWeek' : 'BookListMonth'}>


                    {arrayOfDays && (
                        <div className='column marginauto2'>
                            <label htmlFor="ticket" className="chooseHaircutButton w20rem"> Elegí el día </label>
                            <select onChange={handleInputChange
                            } className="checkoutLabel " name="ticketSelected" id="ticket" >
                                <option value=""> {arrayOfDays.length === 0 ? "Sin turnos programados" : "..."} </option>

                                {arrayOfDays.sort((a, b) => {
                                    return a.day.substring(1) - b.day.substring(1);
                                }).map((day, index) =>

                                    <NewCalendarDay serviceMonth={serviceMonth} itemsByTime={itemsByTime} key={index} day={day.day} month={month} year={year} serviceChoose={serviceChoose} totalAvailCheck={totalAvailCheck} />
                                    // <option 
                                    // // disabled={ticket.stock === 0 ? true : false} 
                                    // key={index} type="text" value={day.day}> {day.day.substring(1)}  </option>
                                )}
                            </select>
                            {serviceMonth && (
                                <>
                                    {serviceMonth.length > 0 ? <button onClick={addToDatesF} className='buttonbyn biggerButton2'> Confirmar </button> : <></>}

                                </>
                            )}

{serviceChoose.includes("domicilio") ? <Link to={`/contactos-turno?${params}`}  className="chooseHaircutButton w20rem yellowBG"> ✂ Coordinar otro horario ✂ </Link> :  <></> }

                        </ div>
                    )}



                </div>

   

            </div>


            {/*  
          {daysLoop.map((dayloop) =>
            <Book key={dayloop} itemsByTime={itemsByTime}  day={dayloop} year={year} month={month} serviceChoose={serviceChoose} serviceMonth={serviceMonth} monthDays={monthDays}  />
          )} */}

            {dateList.length > 0 ?

                <RoomCardsDetailCont detailID={serviceChoose} />

                : <></>}



        </>
    )
}
