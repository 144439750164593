import React, { useState, useEffect } from 'react';
import NavbarPanelItem from './NavbarPanelItem';

export default function NavbarPanel({ customer, bookings }) {

    const [noti, setNoti] = useState(false)
    const [notiNumber, setNotiNumber] = useState(Number)
    const [screen, setScreen] = useState()
    const [menuOpen, setMenuOpen] = useState(false)
    useEffect(() => {
        setScreen(window.screen.width)
    }, [])


    const weektimeStamp = 1659571200
    const daytimeStamp = (weektimeStamp / 7)
    const hourtimeStamp = ((weektimeStamp / 7) / 24)

    useEffect(() => {
        if (bookings != undefined) {


            const notiInLastHour = bookings.map((a) => a.timestamp > (Date.now() - (hourtimeStamp))).filter(e => e === true)


            if (bookings.some((a) => a.timestamp > (Date.now() - (hourtimeStamp))) === true) {
                setNoti(true)
                setNotiNumber(notiInLastHour.length)
            }
        }
    }, [])


    const openMenu = () => {
        if (menuOpen === false) {
            setMenuOpen(true)

        } else { setMenuOpen(false) }
    }



    return (
        <>
            {screen > 800 ? <></> :
                <button className='navbarmobilebutton' onClick={openMenu} ><p className={menuOpen === false ? 'leftBar' : 'leftBarOpened'}>|</p> <p className={menuOpen === false ? 'righBar' : 'righBarOpened'}>|</p> <p className={menuOpen === false ? 'leftBar' : 'displayNone'}>|</p>
                </button>
            }

            {menuOpen === false && screen < 800 ? <></> :

                <div className='navbarAllCont'>
                    <nav className='navbarPanelCont inverted-border-radius'>

                        <NavbarPanelItem route={`/`} src="https://img.icons8.com/external-nawicon-glyph-nawicon/64/0010d5/external-browser-seo-and-marketing-nawicon-glyph-nawicon.png" text="IR AL SITIO"  />

                        <NavbarPanelItem route={`/dashboard/calendar`} src="https://img.icons8.com/ios-glyphs/90/0010d5/macos-maximize.png" text="NUEVA RESERVA" />

                        <NavbarPanelItem route={`/dashboard`} src="https://img.icons8.com/material-rounded/192/0010d5/home.png" text="DASHBOARD" />

                        <NavbarPanelItem route={`/dashboard/bookings`} src="https://img.icons8.com/ios-filled/100/0010d5/reservation.png" text="RESERVAS" />

                        <NavbarPanelItem route={`/dashboard/requests`} src="https://img.icons8.com/ios-filled/50/0010d5/ask-question.png" text="SOLICITUDES" />


                        <NavbarPanelItem route={`/dashboard/bookcalendar`} src="https://img.icons8.com/ios-filled/100/0010d5/calendar--v1.png" text="CALENDARIO" />

                        <NavbarPanelItem route={`/dashboard/agendas`} src="https://img.icons8.com/ios-glyphs/90/0010d5/bicycle.png" text="AGENDAS" />
                        
                        <NavbarPanelItem route={`/dashboard/items`} src="https://img.icons8.com/ios-filled/100/0010d5/barber-scissors.png" text="ITEMS" />

                        <NavbarPanelItem route={`/dashboard/contactos`} src="https://img.icons8.com/material-rounded/96/0010d5/mail-account.png" text="MAILING"/>

                        <NavbarPanelItem route={`/dashboard/payments`} src="https://img.icons8.com/ios-glyphs/90/0010d5/bank-card-back-side.png" text="MÉTODOS DE PAGO" />

                        
                        <NavbarPanelItem route={`/dashboard/metricas`} src="https://img.icons8.com/external-flat-icons-inmotus-design/67/0010d5/external-database-economics-data-analisys-flat-icons-inmotus-design.png" text="ESTADÍSTICAS"  />

                        {/* <NavbarPanelItem route={`/dashboard/profile`} src="https://img.icons8.com/material/96/878fe2/user--v1.png" text="PERFIL" /> */}
{/* 
                        <NavbarPanelItem route={`/dashboard/microsite`} src="https://img.icons8.com/material/96/878fe2/web.png" text="SITIO" /> */}

                        <NavbarPanelItem route={`/dashboard/microsite/comments`} src="https://img.icons8.com/ios-filled/100/0010d5/speech-bubble--v1.png" text="COMENTARIOS" />


                    </nav>

                </div>
            }


        </>
    )
}


