import React, { useState, createContext, useContext, useEffect } from 'react';
import { getFirestore, collection, getDocs} from 'firebase/firestore';

const MessagesContext = createContext([])

export const useMessagesContext = () => useContext(MessagesContext)


const MessagesContextProvider = ({ children }) => {


    const db = getFirestore()
    const [messages, setMessages] = useState([])
    const [render, setRender] = useState(false)
    const [contextMessages, setContextMessages] = useState([])


    const rerenderMes = () => {
      if(render === true) {
        setRender(false)
      } else {
        setRender(true)
      }
     }
    useEffect(() => {
        const QueryCollection = collection(db, `messages`)
        getDocs(QueryCollection)
          .then(resp => setMessages(resp.docs.map(message => ({ id: message.id, ...message.data() }))))
          .catch((err) => console.log(err))
  
       }, [render])


    return (
        <MessagesContext.Provider value={{
            contextMessages,
            messages,
            rerenderMes,
            
        }}>
            {children}

        </MessagesContext.Provider>
    )

}

export default MessagesContextProvider