import React, { useState, useEffect } from 'react';
import { setDoc, doc, addDoc, updateDoc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import moment from 'moment';
import { LineWobble } from '@uiball/loaders';
import NavbarPanel from '../NavbarPanel';
import Login from '../login/Login';
import { useLoginContext } from '../../context/LoginContext'
import { useCalendarContext } from '../../context/CalendarContext'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import BookContAddCalendar from './calendar/BookContAddCalendar';
export default function AddCalendar() {
    const db = getFirestore()
    const { loginCheck, rerenderLogin } = useLoginContext()
    const { addToDates, dateList } = useCalendarContext()
    const { detailID } = useParams();

    const [loader, setLoader] = useState(true)
 
    const [done, setDone] = useState(false)
    const [horarioDesde, setHorarioDesde] = useState('0600');
    const [horarioHasta, setHorarioHasta] = useState('0615');

    const handleHorarioDesdeChange = (event) => {
        setHorarioDesde(event.target.value);
    };

    const handleHorarioHastaChange = (event) => {
        setHorarioHasta(event.target.value);
    };

    const [loginAnimation, setLoginAnimation] = useState(false)

    function dataLoaded(a) {
        return new Promise((resolve, reject) => {
    
          if (!a) {
            reject("Not ok")
          } else {
            resolve("ok")
          }
        })
    
      }
    

    useEffect(() => {
        setLoginAnimation(true)

        setTimeout(() => {
            setLoginAnimation(false)


        }, 2000);
    }, [])

    const loaderPromise = new Promise((resolve, reject) => {
        setTimeout(() => { resolve([]); }, 2000);
    });

    loaderPromise.then(() => {
        setLoader(false);
    });


    const [serviceChosen, setServiceChosen] = useState(detailID)

    const [services, setServices] = useState(null)

    const [horarios, setHorarios] = useState([])
    const [horariosElegidos, setHorariosElegidos] = useState([]);

    const [monthOfThisService, setMonthOfThisService] = useState([])
    const [daysAlreadyAdded, setDaysAlreadyAdded] = useState([])
    const [serviceMonth, setServiceMonth] = useState(null)
    const [serviceYear, setServiceYear] = useState(null)

    const [schedulesOfThisDay, setSchedulesOfThisDay] = useState(null)

    const [schedulesArr, setSchedulesArr] = useState(null)


    useEffect(() => {

        for (let hora = 6; hora < 24; hora++) {
            for (let minuto = 0; minuto < 60; minuto += 15) {
                const horaFormateada = hora.toString().padStart(2, '0');
                const minutoFormateado = minuto.toString().padStart(2, '0');
                const horario = horaFormateada + minutoFormateado;
                horarios.push(horario)
            }
        }

    }, [])

    useEffect(() => {
        const QueryCollection = collection(db, `services`)

        getDocs(QueryCollection)
            .then(resp => setServices(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
            .catch((err) => console.log(err))
    }, [])



    const handleChange = (event) => {
        setServiceChosen(event.target.value);
    }


    const [selectedDay, setSelectedDay] = useState('');
    const [daysInMonth, setDaysInMonth] = useState([]);

    useEffect(() => {
        // Obtiene los días del mes actual utilizando Moment.js
        const days = [];
        const daysInCurrentMonth = moment().daysInMonth();
        for (let day = 1; day <= daysInCurrentMonth; day++) {
            days.push(day);
        }
        setDaysInMonth(days);
    }, []);

    const handleChange2 = (event) => {
        setSelectedDay(event.target.value);
    }


    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);

    useEffect(() => {
        // Obtiene los meses utilizando Moment.js
        const monthsArray = moment.months();
        setMonths(monthsArray);

        // Obtiene los años
        const currentYear = moment().year();
        const yearsArray = [];
        for (let year = currentYear; year <= currentYear + 1; year++) {
            yearsArray.push(year);
        }
        setYears(yearsArray);
    }, []);




    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    }

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    }

    useEffect(() => {
        if (serviceChosen !== null && selectedYear !== null && selectedMonth !== null) {
            const QueryCollection = collection(db, `services/${serviceChosen}/${selectedYear}/`)

            getDocs(QueryCollection)
                .then(resp => setMonthOfThisService(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
                .catch((err) => console.log(err))
        }

    }, [serviceChosen, selectedYear])

    useEffect(() => {
        if (monthOfThisService !== []) {

            const monthSel = monthOfThisService.find(e => e.id == selectedMonth)

            setDaysAlreadyAdded(monthSel)
        }
    }, [selectedYear, serviceChosen, monthOfThisService])



    useEffect(() => {
        const horariosArray = [];
        const horarioMomentDesde = moment(horarioDesde, 'HH:mm');
        const horarioMomentHasta = moment(horarioHasta, 'HH:mm');
        while (horarioMomentDesde <= horarioMomentHasta) {
            const horario = horarioMomentDesde.format('HHmm');
            horariosArray.push({ [horario]: true });
            horarioMomentDesde.add(15, 'minutes');
        }
        setHorariosElegidos(horariosArray);
    }, [horarioDesde, horarioHasta]);



    const newSchedule = async () => {
       
        const objetoDeHorarios = Object.assign({}, ...horariosElegidos)
        const docRef = doc(db, `services/${serviceChosen}/${selectedYear}`, selectedMonth);
 
        await setDoc(docRef, {
            [`d${selectedDay}`]: objetoDeHorarios
        }, { merge: true });

        setDone(true)
        setTimeout(() => {
            setDone(false)
        }, 2000);


    }

    useEffect(() => {
        if (serviceChosen !== null) {
            const QueryCollection = collection(db, `services/${serviceChosen}/${selectedYear}`)
            getDocs(QueryCollection)
            .then(resp => setServiceYear(resp.docs.map(room => ({ id: room.id, ...room.data() }))))
            .catch((err) => console.log(err))
        } else {
            
        }

}, [serviceChosen, selectedYear])


    
    useEffect(() => {
        if(serviceYear !== null) {
            setServiceMonth(serviceYear.filter(e => e.id == selectedMonth))
        } else {
    
        }
    }, [serviceYear, selectedMonth])
    
    useEffect(() => {
  
        if(serviceChosen && selectedDay && selectedMonth && selectedYear) {
            addToDates([serviceChosen, selectedDay, selectedMonth, selectedYear, serviceMonth])
        } else {

        }
       
    }, [serviceChosen, selectedDay, selectedMonth, selectedYear, serviceMonth])


    useEffect(() => {
        dataLoaded(dateList)
          .then(() => {
            const a = "d" + dateList[1]
            setSchedulesOfThisDay(dateList[4][0][a])
          })
      }, [dateList, serviceChosen, selectedDay])

      useEffect(() => {
        if(schedulesOfThisDay !== null && schedulesOfThisDay !== undefined ) {
            setSchedulesArr(Object.keys(schedulesOfThisDay).sort((a, b) => a - b))
        } else {
            setSchedulesArr(null)
        }
            }, [schedulesOfThisDay])

            const [schedulesExists, setSchedulesExists] = useState(false)
       
        useEffect(() => {
            if(schedulesOfThisDay !== null && schedulesOfThisDay !== undefined && horariosElegidos !== null && horariosElegidos !== undefined) {
         
                const objetoDeHorarios =  Object.keys(Object.assign({}, ...horariosElegidos))
                let check = objetoDeHorarios.some(valor => Object.keys(schedulesOfThisDay).includes(valor));

                if(check ===true) {
                    setSchedulesExists(true)
                } else {
                    setSchedulesExists(false)
                }
               
            } else {
         
                setSchedulesExists(false)
            }
   

        }, [horariosElegidos, schedulesOfThisDay, selectedDay, selectedMonth, selectedYear])
            

        const selectDate = (a, b, c) => {
            setSelectedDay(a.toString())
            setSelectedMonth(b.toString())
            setSelectedYear(c.toString())
        }
    
 
    return (
        <>
            {loginCheck != true ?

                <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>

                    <Login />
                </div>
                :

                <div className='dashboardCont'>
                    <NavbarPanel />
                    <p className='sectionTittles'>| NUEVOS HORARIOS </p>

                    {loader === true ?

                        <div className='weekcalendarloader'>
                            <LineWobble
                                size={80}
                                lineWeight={5}
                                speed={1.75}
                                color="#0010d5" />
                        </div> :


                        <div className='newScheduleselectorCont'>
                            <Link className='button absoluteTop' to={`/dashboard/agendas`}>
                                ⬸ Regresar
                            </Link>
                            <div className='row gap'>

                                <label className='labelSelector' htmlFor="options">Agenda:</label>
                                <select className='selector' id="options" value={serviceChosen} onChange={handleChange}>
                                <option className='selector thin' value={detailID}>{detailID}</option>
                                </select>
                            </div>

                            {/* <div className='row gap'>
                                <label className='labelSelector' htmlFor="days">Día:</label>
                                <select className='selector' id="days" value={selectedDay} onChange={handleChange2}>
                                    <option className='selector' value="">Seleccione un día</option>
                                    {daysInMonth.map((day) => (
                                        <option className='selector thin' key={day} value={day}>{day}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='row gap'>
                                <label className='labelSelector' htmlFor="months">Mes:</label>
                                <select className='selector' id="months" value={selectedMonth} onChange={handleMonthChange}>
                                    <option className='selector' value="">Seleccione un mes</option>
                                    {months.map((month, index) => (
                                        <option className='selector thin' key={month} value={index + 1}>{month === "April" ? "Abril" : month === "March" ? "Marzo" : month === "January" ? "Enero" : month === "February" ? "Febrero" : month === "May" ? "Mayo" : month === "June" ? "Junio" : month === "July" ? "Julio" : month === "August" ? "Agosto" : month === "September" ? "Septiembre" : month === "October" ? "Octubre" : month === "November" ? "Noviembre" : month === "December" ? "Diciembre" : <></>}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='row gap'>
                                <label className='labelSelector' htmlFor="years">Año:</label>
                                <select className='selector' id="years" value={selectedYear} onChange={handleYearChange}>
                                    <option className='selector' value="">Seleccione un año</option>
                                    {years.map((year) => (
                                        <option className='selector thin' key={year} value={year}>{year}</option>
                                    ))}
                                </select>

                            </div> */}

<BookContAddCalendar selectDate={selectDate}  />

{selectedDay && ( 
    <p className='buttonbyn'>Agregarás horarios en el día: <br /> <b>{selectedDay}/{selectedMonth}/{selectedYear}</b> </p>
)}

                            <div className='row gap'>
                                <label className='labelSelector'>
                                    Horario desde:
                                    <select className='selector w10rem' value={horarioDesde} onChange={handleHorarioDesdeChange}>
                                        {horarios && (
                                            horarios.map((e) =>
                                                <option className='selector thin' key={e.id} value={e}>{e[0]}{e[1]}:{e[2]}{e[3]}</option>
                                            )
                                        )}
                                    </select>
                                </label>
                            </div>

                            <div className='row gap '>
                                <label className='labelSelector'>
                                    Horario hasta:
                                    <select className='selector w10rem' value={horarioHasta} onChange={handleHorarioHastaChange}>
                                        {horarios && (
                                            horarios.map((e) =>
                                                <option className='selector thin ' key={e.id} value={e}>{e[0]}{e[1]}:{e[2]}{e[3]}</option>
                                            )
                                        )}
                                    </select>
                                </label>
                            </div>
                            {schedulesOfThisDay && (
                                <p className='buttonbyn margintop '>HORARIOS DE ESTE DÍA</p>
                             
                            )}
                               {schedulesArr && (
                                    <div className='row w500pxmax'>
                                        {schedulesArr.map(e =>
                                            <p className='buttonbyn' key={e}> {e[0]}{e[1]}:{e[2]}{e[3]} </p>
                                            )}
                                    </div>
                                    )}
                            {/* <p className='paragraph'>Si tenés alguna reserva dentro del rango horario y día que elegiste, los horarios <b>sobreescribirán</b> a los ya existentes y los bloques reservados volverán a aparecer como disponibles (<b>la reserva no desaparecerá ni se cancelará</b>). <br />
                            Si no hay reservas en este día y horario, no te preocupes. Los nuevos horarios se combinarán con los ya existentes. </p> */}
                            {schedulesExists === true ? <button className='buttonbyn redgradient margintop' > YA EXISTEN HORARIOS <br /> EN EL RANGO ELEGIDO </button> : <button className='button' onClick={newSchedule}> CREAR HORARIO </button> }
                            
                            {done === true ?
                        <p className='button2'>¡Listo! Podés agregar otro</p>
                        : <></>}
                        </div>

                    }

              
                </div>
            }
        </>
    )
}






















