import React, { useState, useEffect } from 'react';
import { getFirestore, } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import RoomsEditItemList from './RoomsEditItemList'
import { useRoomsContext } from '../../context/RoomsContext'
import NavbarPanel from '../NavbarPanel';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
export default function RoomsEditCont() {


  const db = getFirestore()
  const { services, rerender, allImagesLinks } = useRoomsContext()
  const { loginCheck } = useLoginContext()



  const [loginAnimation, setLoginAnimation] = useState(false)


  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)
    }, 2000);
  }, [])

  const [loadingData, setLoadingData] = useState(false)

  useEffect(() => {
    if(services.length === 0) {
      rerender()
    } else {
        setLoadingData(true)
    }
  }, [services])
  


  return (
    <>
      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>

          <Login />
        </div>
        :


        <>
          <div className='dashboardCont'>
            <NavbarPanel services={services}  />
            <p className='sectionTittles'>| EDITÁ LOS SERVICIOS </p>


            <RoomsEditItemList services={services}  />
          </div>

          <div>
         
          </div>
        </>}
        </>
  )
}
