import React, { useState, useEffect } from 'react'
import { collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import BookOrderDashboardList from './BookOrderDashboardList'
import { useBookingContext } from '../../context/BookingContext'
import NavbarPanel from '../NavbarPanel';
import { useLocation } from 'react-router-dom';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import RefreshButton from '../widgets/RefreshButton';
export default function BookOrderDashboardListCont() {
  const db = getFirestore()
  const { bookings, rerender } = useBookingContext()
  const { loginCheck } = useLoginContext()



  const [search, setSearch] = useState("")
  const [active, setActive] = useState(false)

  const location = useLocation();




  const handleSubmit = (event) => {
    event.preventDefault();
  }

  useEffect(() => {
    setActive(true)
  }, [])


  const [loginAnimation, setLoginAnimation] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])

  const transferTrue = async ( g) => {

    const arr = [g.toString()]
       
       
    const queryCol = collection(db, `bookings/`)
   
    const batch = writeBatch(db)
    const updateId2 = await query(
        queryCol,
      where(documentId(), 'in', arr))
   
   
      
       await getDocs(updateId2)
       .then(resp => resp.docs.forEach(res => {
        
    
        batch.update(res.ref, { transfer: true });
      })   )  
      await batch.commit();
      rerender()

    }

  const cancel = async (a, b, c, d, f, g) => {

    const arr = [g.toString()]
       
       
    const queryCol = collection(db, `bookings/`)
   
    const batch = writeBatch(db)
    const updateId2 = await query(
        queryCol,
      where(documentId(), 'in', arr))
   
   
      
       await getDocs(updateId2)
       .then(resp => resp.docs.forEach(res => {
        
    
        batch.update(res.ref, { status: false });
      })
        // const updateData = { ['d' + dateList[1]]: { status: false } };
       )
       await batch.commit();
      a.forEach(async(e) => {    
        const arr2 = [c.toString()]
       
       
        const queryCol = collection(db, `services/${b}/${d}/`)
       
        const batch = writeBatch(db)
        const updateId = await query(
            queryCol,
          where(documentId(), 'in', arr2))
       
       
          
           await getDocs(updateId)
           .then(resp => resp.docs.forEach(res => {
            
        
            batch.update(res.ref, { ['d' + f + '.' + e]: true });
          })
    
           )
           await batch.commit(); 
         } ) 

         rerender()

  }

  useEffect(() => {
    setSearch( decodeURIComponent(location.search.replace("?","" ).replace("-"," " )))
  }, [location])


  return (
    <>
      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>


          <Login  />
        </div>
        :
        <>
          <div className='dashboardCont'>
            <NavbarPanel bookings={bookings} active={active}  />
            <div className='bookOrderDashboardlistCol margintop'>
              <p className='sectionTittles'>| RESERVAS</p>

              <div className='searchOrderCont'>
              <RefreshButton refreshFunction={rerender} />
                <form className='addRoomFormCont' action="" onSubmit={handleSubmit}>
                  <input className='searchOrderInput' type="search" onChange={(e) => setSearch(e.target.value)} placeholder='Buscar por nombre...' />
                </form>
              </div>

              <div>

 
              </div>

              <BookOrderDashboardList bookings={bookings} search={search} rerender={rerender} cancel={cancel} transferTrue={transferTrue} />
            </div>
          </div>
        </>
      }
    </>
  )
}
