import React from 'react'



export default function Backwardsbutton({text, onclick}) {
  return (
 
    <button className='button' onClick={onclick}> {text} </button> 

  )
}
