import React, { useState, useEffect } from 'react';
import { addDoc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import { useLoginContext } from '../../context/LoginContext'
import { Line } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler, ArcElement
} from 'chart.js';
import NavbarPanel from '../NavbarPanel';
import Login from '../login/Login';

export default function MetricsListCont() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement, ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  const { loginCheck } = useLoginContext()

  const db = getFirestore()


  const [bookings, setBookings] = useState([])
  const [monthBuys, setMonthBuys] = useState([])
  const [buysByMonth, setBuysByMonth] = useState([])
  const [servicesTotal, setServicesTotal] = useState([])
  const [itemsTotal, setItemsTotal] = useState([])
  const [moneyTotal, setMoneyTotal] = useState([])
  const [moneyTotalWMonths, setMoneyTotalWMonths] = useState([])
  const [paymentTotal, setPaymentTotal] = useState([])

  useEffect(() => {
    const QueryCollection = collection(db, `bookings`)

    getDocs(QueryCollection)
      .then(resp => setBookings(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
      .catch()

  }, [])

  useEffect(() => {
    setMonthBuys(bookings.map(e => e.month))
    setItemsTotal(bookings.map(e => e.item))
    setServicesTotal(bookings.map(e => e.service))
    setMoneyTotalWMonths(bookings.map(e => ({ [e.month]: e.total })))
    setMoneyTotal(bookings.map(e => e.total))
    setPaymentTotal(bookings.map(e => e.payment))
  }, [bookings])


  useEffect(() => {
    let comprasPorMes = new Array(12).fill(0);

    for (let i = 0; i < meses.length; i++) {
      let mes = meses[i];
      for (let j = 0; j < monthBuys.length; j++) {
        if (monthBuys[j] === i + 1) {
          comprasPorMes[i]++;
        }
      }
    }

    setBuysByMonth(comprasPorMes)

  }, [monthBuys])




  var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  ///RESERVAS////
  var midata = {
    labels: meses,
    datasets: [ // Cada una de las líneas del gráfico
      {
        label: 'Reservas',
        data: buysByMonth,
        tension: 0.5,
        fill: true,
        borderColor: '#0010d5',
        backgroundColor: '#878fe2',
        pointRadius: 5,
        pointBorderColor: '#f7aeae',
        pointBackgroundColor: '#ffb1ec',
      }
    ],
  };

  var misoptions = {
    scales: {
      y: {
        min: 0
      },
      x: {
        ticks: { color: '#0010d5' }
      }
    }
  };



  /////COBROS/////

  const [paymentTotalAcc, setPaymentTotalAcc] = useState([])


  useEffect(() => {
    const moneyTotalSum = moneyTotalWMonths.reduce((acc, obj) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      if (!acc[key]) {
        acc[key] = value;
      } else {
        acc[key] += value;
      }
      return acc;
    }, {});

    const months = Array.from({ length: 12 }, (_, i) => i + 1); // array con números del 1 al 12
    months.forEach(month => {
      if (!moneyTotalSum[month]) {
        moneyTotalSum[month] = 0;
      }
    });

    setPaymentTotalAcc(moneyTotalSum);

  }, [monthBuys])



  var midataCobros = {
    labels: meses,
    datasets: [ // Cada una de las líneas del gráfico
      {
        label: 'Recaudación',
        data: Object.values(paymentTotalAcc),
        tension: 0.5,
        fill: true,
        borderColor: '#878fe2 ',
        backgroundColor: '#ffb1ec',
        pointRadius: 5,
        pointBorderColor: '#0010d5 ',
        pointBackgroundColor: ' #878fe2 ',
      }
    ],
  };

  ///////////////// PIE ///////////


  /////ITEMS/////


  const [itemsArray, setItemsArray] = useState([])


  var optionsPie = {
    responsive: true,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    const haircutsCount = itemsTotal.reduce((acc, haircut) => {
      acc[haircut] = (acc[haircut] || 0) + 1;
      return acc;
    }, {});

    setItemsArray(haircutsCount)
  }, [itemsTotal])

  var dataPie = {
    labels: Object.keys(itemsArray),
    datasets: [
      {
        label: '',
        data: Object.values(itemsArray),
        backgroundColor: [
          '#c3c5f8',
          'rgba(255, 206, 86, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          '#878fe2',
          'rgba(255, 206, 86, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

    /////SERVICIO/////


    const [servicesArray, setServicesArray] = useState([])


    var optionsPie = {
      responsive: true,
      maintainAspectRatio: false,
    };


  
    useEffect(() => {
      const servicesCount = servicesTotal.reduce((acc, service) => {
        acc[service] = (acc[service] || 0) + 1;
        return acc;
      }, {});
  
      setServicesArray(servicesCount)
    }, [servicesTotal])

    var dataPieServices = {
      labels: Object.keys(servicesArray),
      datasets: [
        {
          label: '',
          data: Object.values(servicesArray),
          backgroundColor: [
            '#c3c5f8',
            'rgba(255, 206, 86, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
          ],
          borderColor: [
            '#878fe2',
            'rgba(255, 206, 86, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };


       /////PAYMENTS/////


       const [paymentsArray, setPaymentsArray] = useState([])


       var optionsPie = {
         responsive: true,
         maintainAspectRatio: false,
       };
   
   
     
       useEffect(() => {
         const paymentsCount = paymentTotal.reduce((acc, payment) => {
           acc[payment] = (acc[payment] || 0) + 1;
           return acc;
         }, {});
     
         setPaymentsArray(paymentsCount)
       }, [paymentTotal])
      
       var dataPiePayments = {
         labels: Object.keys(paymentsArray),
         datasets: [
           {
             label: '',
             data: Object.values(paymentsArray),
             backgroundColor: [
               '#c3c5f8',
               'rgba(255, 206, 86, 0.2)',
               'rgba(54, 162, 235, 0.2)',
               'rgba(75, 192, 192, 0.2)',
               'rgba(153, 102, 255, 0.2)',
             ],
             borderColor: [
               '#878fe2',
               'rgba(255, 206, 86, 1)',
               'rgba(54, 162, 235, 1)',
               'rgba(75, 192, 192, 1)',
               'rgba(153, 102, 255, 1)',
             ],
             borderWidth: 1,
           },
         ],
       };
   


  const checkOutPayment = async () => {
    const date = new Date();



    let book = {}

    book.idCode = parseInt(((Math.random() * 10000000) + (Math.random() * 10000000)) / (Math.random(1, 50)));
    book.item = "Peinado"
    book.total = 5500
    book.subtotal = 1000
    book.paid = true
    book.fullbookdate = { day: 6, month: 3, year: 2023 }
    book.hour = date.getHours() + ":" + date.getMinutes()
    book.status = true
    book.timestamp = Date.now()
    book.blockTimes = 4
    book.service = "domiciliozona4"
    book.day = 7
    book.month = 8
    book.year = 2023
    book.blocks = 60 / 15
    book.payment = "mercadopago"
    book.transfer = false



    const queryCollection = collection(db, `fakebookings`)
    addDoc(queryCollection, book)
      .then(res => console.log(res))
      .catch(err => console.log(err))

  }

  const [loginAnimation, setLoginAnimation] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])


  return (


    <>

      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>


          <Login />
        </div>
        :

        <>
          <div className='metricsCont'>
          {/* <button onClick={checkOutPayment} >click</button> */}
            <NavbarPanel bookings={bookings} />
            <p className='sectionTittles'>| MÉTRICAS</p>

            <div className='dashboardRowCont'>

              <div className='rowOfCharts'>
              <p className='sectionTittle'>ESTADÍSTICAS</p>

                <div className='lineChart ' >
                  <Line data={midata} options={misoptions} />

               
                </div>

                <div className='lineChart' >

                  <Line data={midataCobros} options={misoptions} />
                  <p className='buttonMetrics'> TOTAL ANUAL <br /> <span className='biggerText'>${moneyTotal.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toLocaleString()}</span> </p>

                </div>

                <div className='pieChart'>
                  <p className='chartTittle'>Items</p>
                  <Pie data={dataPie} options={optionsPie} />
                </div>

                <div className='pieChart'>
                <p className='chartTittle'>Servicios </p>
                  <Pie data={dataPieServices} options={optionsPie} />
                </div>

                <div className='pieChart'>
                <p className='chartTittle'>Métodos de pago</p>
                  <Pie data={dataPiePayments} options={optionsPie} />
                </div>
              </div>


              
            </div>
          </div>
        </>
      }


    </>

  )
}


