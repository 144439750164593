import React, { useState, useEffect } from 'react';
import { addDoc, setDoc, doc, collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';


import { useRoomsContext } from '../../context/RoomsContext'
import NavbarPanel from '../NavbarPanel';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import ItemsEditButton from './ItemsEditButton';
export default function ItemsEditCont() {


  const db = getFirestore()
  const { services, rerender, allImagesLinks } = useRoomsContext()
  const { loginCheck } = useLoginContext()

  const [items, setItems] = useState([])

  const [loginAnimation, setLoginAnimation] = useState(false)
  const [addingNew, setAddingNew] = useState(false)
  const [render, setRender] = useState(0)

 
  useEffect(() => {
    const QueryCollection = collection(db, `items`)

    getDocs(QueryCollection)
      .then(resp => setItems(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
      .catch((err) => console.log(err))

  }, [render])

  const renderF = () => {
    setRender(render + 1)
  }

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)
    }, 2000);
  }, [])

  const [loadingData, setLoadingData] = useState(false)

  useEffect(() => {
    if (items.length === 0) {
      rerender()
    } else {
      setLoadingData(true)
    }
  }, [items])
 
  const addingNewF = () => {
    setAddingNew(true)
  }

  const [error, setError] = useState(false)

  const addNew = (a, b, c, d, e) => {

    let item = {}
    item.name = d
    item.sub = e ? e : ""
    item.time = Number(b)
    item.price = Number(c)
    console.log(item)

   
 if(items.find(e => e.id === a) === undefined ) {


    const queryCollection = doc(db, `items`, a)
    setDoc(queryCollection, item)
      .then(res => console.log(res))
      .catch(err => console.log(err))

    setAddingNew(false)
    renderF()
  } else {
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 2000);
  }
  }



  return (
    <>
      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>

          <Login />
        </div>
        :


        <>
          <div className='dashboardCont'>
            <NavbarPanel services={services} />
            <p className='sectionTittles'>| EDITÁ LOS ITEMS </p>

            <div className='column'>
              <div className='itemsEditInputsCont'>
                <p className='itemsEditInput w30rem minitext bgreen' >
                  ITEM
                </p>
                <p className='itemsEditInput w5itemsrem minitext bgreen' >
                  TIEMPO
                </p>
                <p className='itemsEditInput w5itemsrem minitext bgreen' >
                  PRECIO
                </p>
                <p className='itemsEditInput w5itemsrem minitext bgreen' >
                  DETALLE
                </p>
              </div>
              {items && (
                items.map((e) =>
                  <ItemsEditButton key={e.id} {...e} render={renderF} />
                )
              )}

              {addingNew === true ? <ItemsEditButton adding={true} addNew={addNew} error={error} /> : <></>}

              {addingNew === true ?       
       <></> : 
              <button onClick={addingNewF} className='itemsEditNewButton addNewItemButton' >
                AGREGAR NUEVO ITEM
              </button>}

            </div>

          </div>
          <div>

          </div>
        </>}
    </>
  )
}

