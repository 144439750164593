import React, { useState } from 'react';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { RaceBy } from '@uiball/loaders'


export default function RemoveImg({ prop, link, customer, roomid, edit, rerender, col, micro, rerenderMicro }) {

    const [openConfirmation, setOpenConfirmation] = useState(false)

    const showOpenConf = () => {
        if (openConfirmation === true) {
            setOpenConfirmation(false)
        } else {
            setOpenConfirmation(true)
        }
    }

    const removeImgChange = () => {
        edit(prop, null)
    
        setTimeout(() => {
          rerender()
    
        }, 200);
      }
    
      const removeImgChangeMicrosite = () => {
        edit(prop, null, col)
        setTimeout(() => {
            rerenderMicro()
      
          }, 200);
    
      }

    

    return (
        <>
            {openConfirmation === false ? 
            
                <button onClick={showOpenConf} className="removeImgButton">
                    <img className='crossImg' src="https://img.icons8.com/ios-glyphs/90/000000/multiply.png" alt="" />
                </button>
           
                :
                <>
                {micro === true ?  
                <button onClick={removeImgChangeMicrosite} className='deleteText'>Borrar</button>

                :
                <button onClick={removeImgChange} className='deleteText'>Borrar</button>
                }
                
                </>
                
            }
        </>

    )
}
