import React, { useState, useEffect } from 'react'
import NavbarPanel from '../NavbarPanel'
import MessagesList from './MessagesList'
import { useMessagesContext } from '../../context/MessagesContext'
import { useParams } from 'react-router-dom';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
export default function MessagesListCont() {
  const { customer } = useParams()
  const { contextMessages, messages } = useMessagesContext()
  const { loginCheck } = useLoginContext()

  useEffect(() => {
    contextMessages.push(customer)

  }, [])




  const [loginAnimation, setLoginAnimation] = useState(false)

  useEffect(() => {
    setLoginAnimation(true)

    setTimeout(() => {
      setLoginAnimation(false)


    }, 2000);
  }, [])


  return (
    <>
      {loginCheck != true ?

        <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
          <Login customer={customer} />
        </div>
        :
        <>
          <div className='dashboardCont'>
            <NavbarPanel customer={customer} />
            <MessagesList messages={messages} />
          </div>
        </>}
    </>
  )
}
