import React, { useState } from 'react';

export default function AddRoomInput({name, type, onchange, place, value }) {


  const [edition, setEdition] = useState(false)

  const editing = () => {
    if(edition === false) {
      setEdition(true)
    } else {
      setEdition(false)
    }
  }

 


  return (
    
    
    <div className='inputRoomEditLabelCont'>
    <label className='labelRoomEdit' htmlFor=""> {name.toUpperCase()} </label>
    <input className='RoomEditInput' name={name} type={type} onChange={onchange} placeholder={place} value={value} />
    </div>

  )
}
