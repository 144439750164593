import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

export default function Logo() {
    const db = getFirestore()

    const [micrositeData, setMicrositeData] = useState([])
    const [images, setImages] = useState()

    useEffect(() => {
        const QueryCollection = collection(db, `microsite`)
    
        getDocs(QueryCollection)
          .then(resp => setMicrositeData(resp.docs.map(amen => ({ id: amen.id, ...amen.data() }))))
          .catch((err) => console.log(err))
      }, [])
    
    
  useEffect(() => {
    setImages(micrositeData.find(data => data.id == "images"))

  }, [micrositeData])

  return (
    <>
    { images && (
      <Link to="/">
        <img  className='logoMicrosite' src={images.logo} alt="" />
        </Link>
       )}
       </>
  )
}
