
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { emojify } from 'node-emoji';






export default function WeekCalendarItem({ unblockDay, serviceChosen, blockDay, allDatesMerged,   year, address, client, day, item, month, service, status, time, blocks, today, todaysMonth, todaysYear, allDatesW, servicesSchedules, render}) {


  const [forbiddenDay, setForbiddenDay] = useState(false)
  const [dayMouseOver, setDayMouseOver] = useState(false)
  const [availability, setAvailability] = useState(null)
  const [rrender, setRrender] = useState(0)

  const rrenderF = () => {
    setRrender(rrender+1)
  }

  const a = "d" + day

  const checkDate = async () => {

    var newDay = new Date();
    var today = newDay.getDate();
    var currentMonth = newDay.getMonth() + 1;
    var currentYear = newDay.getFullYear();



    if (today > day && currentMonth === month && currentYear === year) {
      setForbiddenDay(true)

      setTimeout(() => {
        setForbiddenDay(false)
      }, 2900);
    } else {

    }

  }

  // const mouseOver = () => {
  //   setDayMouseOver(true)
  // }

  // const mouseLeave = () => {
  //   setDayMouseOver(false)
  // }

  const [ThisDayDates, setThisDayDates] = useState(null)


  const [onHoverItem, setOnHoverItem] = useState(false)

  const onHoverItemIn = () => {
    setOnHoverItem(true)
  }

  const onHoverItemOut = () => {
    setOnHoverItem(false)
  }

  const color = service === ("local" || "asesoria") ? "ybg" : "gbg"

  const [exists, setExists] = useState(false)

  useEffect(() => {
    let existsF = allDatesMerged.includes(time[0] + time[1] + time[3] + time[4])

    if (existsF === true) {
      setExists(true)
    } else {
      setExists(false)
    }

  }, [allDatesMerged])

  const [serviceOfBlock, setServiceOfBlock] = useState(null)
  const [confirmar, setConfirmar] = useState(false)
  const [blocked, setBlocked] = useState(false)
  const [unblocked, setUnblocked] = useState(false)

  useEffect(() => {
    allDatesW.forEach((e) => {
      const findex = e[0].find(a => a == time[0] + time[1] + time[3] + time[4])
      if (findex !== undefined) {
        setServiceOfBlock(e[1].id)
      }
    })
  }, [allDatesW])
  


  useEffect(() => {



    if (servicesSchedules !== null && serviceOfBlock !== null) {
      let tod = "d" + today
      let tim = time[0] + time[1] + time[3] + time[4]
  
      let ll = servicesSchedules.filter(e => e.id === serviceOfBlock)
      let kk = ll[0].docsData.filter(e => e.id == todaysMonth)

 
      if(kk[0] !== undefined && kk[0][tod] !== undefined) {
        setAvailability(kk[0][tod][tim])
      } else {
        setAvailability(false)
      }

    } else {

    }
  }, [serviceOfBlock, servicesSchedules])



 const conf = () => {
  if(confirmar === true) {
    setConfirmar(false)
  } else {
    setConfirmar(true)
  }

 }

 const blockedF = () => {
  setBlocked(true)
 }

 const unblockedF = () => {
  setUnblocked(true)
 }

 const [showAddress, setShowAddress] = useState(false)

const showAddressF = () => {
  setShowAddress(true)

}

const closeAddressF = () => {
  setShowAddress(false)
}

const [showOrNot, setShowOrNot] = useState(false)

useEffect(() => {
 
  setShowOrNot(serviceChosen === service || serviceChosen === null || serviceChosen === "todos")

}, [serviceChosen])


const mensajeDom = client !== null ? `hola ${client.name}! Soy Valu de la Coque. el día ${day}/${month} a las ${time} tenemos turno en tu espacio.\n\n ✂ Me repetirías tu dirección así me queda por este medio? \n ✂ por favor, esperame con el pelo limpio o sin productos de finalizar (cremas o geles)\n ✂ siempre trato de ser puntual, pero por cuestiones de traslado entre casa y casa, el margen de demora puede ser de hasta 10 minutos  \n ✂ en caso de que necesites cancelar, avisando con más de 24 horas se reprograma o devuelve la seña. con menos tiempo, se pierde la seña y la posibilidad de programar nuevamente el turno.\n\n Muchas gracias y te veo en tu espacio :)`: null

const baseUrl = "https://api.whatsapp.com/send/?phone=";
const phoneNumber =  client !== null ? client.phone : null; 
const encodedMessageDom = encodeURIComponent(mensajeDom);
const fullUrlDom = `${baseUrl}${phoneNumber}&text=${encodedMessageDom}`;

const mensajeLocal=  client !== null ? `hola ${client.name}! soy valu de la coque. el día ${day}/${month} a las ${time} tenemos turno en el COQUEstudio:) 
\n\n 📍 La dirección es 59 #993 casi esquina 15 (espacio de OtraCasaclub). 
\n 〰️ la coqueteria esta en el piso de arriba. si tenes dificultades para subir escaleras ¡avisanos! que nos adaptamos a tu situación 
\n 👉🏻 venir con pelo limpio o sin productos de finalizar (cremas o geles) 
\n ⏰ xfi tratar de ser puntual asi no hacemos esperar a los siguientes clientes. 
\n 〰️ si venis en auto, considera el tiempo de estacionamiento! es una zona con poca disponibilidad 
\n 👉🏻 en caso de no poder asistir, avisando con mas de 24 hs se reprograma o devuelve la seña. con menos de ese tiempo, se pierde la seña y la posibilidad de programar de nuevo el turno. ➖no avisando o cancelandouna hora antes, se deberá abonar el valor completo del turno. 
\n  🤩 en el espacio de @otracasa_ funcionan algunos talleres y emprendimientos. también podes venir a merendar o tomar matecitos que la casa esta abierta al publico de 16 a 20hs . pasate cuando quieras a compartir el rato! 🥰
\n\n muchas gracias, te espero!
\n valu de @lacoque.teria 🙋🏼‍♀️`: null

const encodedMessageLocal = encodeURIComponent(mensajeLocal);
const fullUrlLocal = `${baseUrl}${phoneNumber}&text=${encodedMessageLocal}`;




  return (
    <>
 {status === false ? <></> : 
 
 <div className='row relative'>
      <div className={client === null && exists === false ? 'orderCardCont lightred w3rem thinLine' : client === null && exists === true ? "orderCardCont darkblue w3rem" : client !== null && status === true && showOrNot === true  ? 'orderCardCont darkblue w3rem' : "orderCardCont darkblue w3rem"} >
        <p className='orderCardText whitetext'>{time}</p>
      </div>

      <div className={client !== null && blocks === 1 && status === true  && showOrNot === true ? `rowOrderCardCont  ` :
       client !== null && blocks === 2 && status === true && showOrNot === true  ? `rowOrderCardCont h2blocksName` : 
       client !== null && blocks === 3 && status === true && showOrNot === true ? `rowOrderCardCont h3blocksName ` : 
      client !== null && blocks === 4 && status === true && showOrNot === true ? `rowOrderCardCont h4blocksName ` : 
      client !== null && blocks === 5 && status === true && showOrNot === true ? `rowOrderCardCont h5blocksName ` :
        client !== null && blocks === 6 && status === true&& showOrNot === true  ? `rowOrderCardCont h6blocksName ` :
          client !== null && blocks === 7 && status === true && showOrNot === true ? `rowOrderCardCont h7blocksName ` :
            client !== null && blocks === 8 && status === true && showOrNot === true ? `rowOrderCardCont h8blocksName ` :
              client !== null && blocks === 9 && status === true&& showOrNot === true  ? `'rowOrderCardCont h9blocksName ` :
                client !== null && blocks === 10 && status === true && showOrNot === true ? `rowOrderCardCont h10blocksName ` :
                  client !== null && blocks === 11 && status === true && showOrNot === true ? `rowOrderCardCont h11blocksName ` :
                    client !== null && blocks === 12 && status === true&& showOrNot === true  ? `rowOrderCardCont h12blocksName ` :
                      client !== null && blocks === 13 && status === true && showOrNot === true ? `rowOrderCardCont h13blocksName ` :
                        client !== null && blocks === 14 && status === true && showOrNot === true ? `rowOrderCardCont h14blocksName` :
                          client !== null && blocks === 15 && status === true && showOrNot === true ? `rowOrderCardCont h15blocksName ` : client !== null && blocks === 16 && status === true&& showOrNot === true  ? `rowOrderCardCont h16blocksName ` :
                            'rowOrderCardCont'}>

        <div className={client === null && exists === false  ? 'orderCardCont lightred w5rem2 thinLine' : client === null && exists === true ? "orderCardCont darkblue w5rem2" : client !== null && status === true && showOrNot === true ? 'orderCardCont w5rem2' : "orderCardCont darkblue w5rem2"}>
          {service !== null && service ===( "local" || "asesoria")&& status === true && showOrNot === true  ? <p className='orderCardText '>{service.toUpperCase()}</p> : service !== null && service !== ( "local" && "asesoria")&& status === true  && showOrNot === true  ? <p className='orderCardText '>{service.toUpperCase()}</p> : <p className='orderCardText whitetext'>-</p>}
        </div>

        <Link to={client !== null && showOrNot === true  ? `/dashboard/bookings?${client.name.replace(" ","-" )}`  :  null}>
        <div className={client === null && exists === false  ? 'orderCardCont lightred  thinLine' : client === null && exists === true ? "orderCardCont darkblue" : client !== null && status === true && showOrNot === true  ? 'orderCardCont ' : "orderCardCont darkblue"} >
          {client !== null && status === true && showOrNot === true  ? <strong className='orderCardText'> <span className='button2FitCont'>{client.name.toUpperCase()}</span> </strong> : <p className='orderCardText whitetext'>-</p>}
          {/* {client !== null ? <p className='orderCardText'></p> : } */}
        </div>
        </Link>

        <div className={client === null && exists === false  ? 'orderCardCont lightred  thinLine' : client === null && exists === true && availability === false ? "orderCardCont darkblue" : client !== null && status === true && showOrNot === true  ? 'orderCardCont ' : "orderCardCont darkblue"}>

          {item !== null && status === true  
          ? 
          <p className='orderCardText'>{item.toUpperCase()}</p>
          : 
          exists === true && confirmar === false && availability === true
          ?
          <div className='row'>  <button className=' button2'> DISPONIBLE </button>  
          {serviceChosen === null || serviceChosen == "todos" ? <></> : <button onClick={conf} className=' cancelButton44'> BLOQUEAR </button>}
          
          </div>
          : 
          exists === true && confirmar === true &&  availability === true
          ? 
          <div className='row'>  <button onClick={
                () => {
                  blockDay(serviceOfBlock, todaysYear, todaysMonth, today, time[0] + time[1] + time[3] + time[4]); blockedF()
                }
              } className=' cancelButton44'> {blocked === true ? "¡LISTO!" : "CONFIRMAR"} </button></div> 
          : 
          <p className='orderCardText whitetext'> { availability === false && exists === false  ? 
    
            "-"
        
      
          : exists === true && availability === false && serviceChosen !== "todos" ?        
          <div className='row'>
          <button className=' cancelButton44'>BLOQUEADO</button>
          <button  onClick={
                () => {
                  unblockDay(serviceOfBlock, todaysYear, todaysMonth, today, time[0] + time[1] + time[3] + time[4]); unblockedF()
                }
              } className=' button2Desbloquear'>{unblocked === true ? "¡LISTO!" : "DESBLOQUEAR"}</button>
          </div> 
          : exists === true && availability === false && serviceChosen === "todos" ? <button className=' cancelButton44'>BLOQUEADO</button> : "-"} </p>}
        </div>



        <div className={client === null && exists === false ? 'orderCardCont lightred w5rem2 thinLine' : client === null && exists === true ? "orderCardCont darkblue w5rem2" : client !== null && status === true && showOrNot === true  ? 'orderCardCont w5rem2' : "orderCardCont darkblue w5rem2"}>
          {blocks > 0 && status === true && showOrNot === true  ? <p className='orderCardText'> {blocks * 15} MINS </p> : <p className='orderCardText whitetext'>-</p>}
        </div>

        <div className={client === null && exists === false  ? 'orderCardCont lightred w2rem thinLine' : client === null && exists === true ? "orderCardCont darkblue w2rem" : client !== null && status === true && showOrNot === true  ? 'orderCardCont w2rem' : "orderCardCont darkblue w2rem"}>
          {service !== "local" && service !== "color"  && service !== "asesoria" &&  client !== null && showOrNot === true  ?
          <div  onMouseEnter={showAddressF} onMouseLeave={closeAddressF} className='showAddressCont'>
              {showAddress === true ? <p className='showAddressText'> {address} </p> : <></>}
  
            <img  className='iconImg' src="https://img.icons8.com/ios/50/000000/place-marker--v1.png" />
            </div>
            : <p className='orderCardText whitetext'>-</p>}
        </div>

        <a target='_blank' href={client !== null && showOrNot === true && service !== "local" && service !== "color" ? `${fullUrlDom}` : client !== null && showOrNot === true && service === "local" ? `${fullUrlLocal}` : client !== null && showOrNot === true && service === "color" ? `${fullUrlLocal}` : null} className={client === null && exists === false? 'orderCardCont lightred w2rem thinLine ' : client === null && exists === true ? "orderCardCont darkblue w2rem" : client !== null && showOrNot === true  && status === true ? 'orderCardCont w2rem' : "orderCardCont darkblue w2rem"}>
          {client !== null && showOrNot === true  && status === true ?
           
              <img className='iconImg' src="https://img.icons8.com/ios/50/000000/whatsapp--v1.png" />
          
            : <p className='orderCardText whitetext'>-</p>}
        </a>

        <a target='_blank' href={client !== null && showOrNot === true  ? `https://instagram.com/${client.ig}` : null} className={client === null && exists === false  ? 'orderCardCont lightred w2rem thinLine' : client === null && exists === true ? "orderCardCont darkblue w2rem" : client !== null && status === true  && showOrNot === true ? 'orderCardCont w2rem' : "orderCardCont darkblue w2rem"}>
          {client !== null && showOrNot === true  && status === true ?
            <img className='iconImg' src="https://img.icons8.com/ios/50/000000/instagram-new--v1.png" />
            : <p className='orderCardText whitetext'>-</p>}
        </a>






      </div>

    </div>
 
 }
</>
      

)
}

