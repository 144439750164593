
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function ForWeekCalendarItem({ serviceChoose, blockDay, allDatesMerged, onlyCalendar, serviceMonth, year, address, id, client, fullbookdate, hour, date, day, item, month, paid, service, status, time, total, blocks, renderF, render, today, todaysMonth, todaysYear, allDatesW, services, servicesSchedules }) {





  const [daysAvail, setDaysAvail] = useState()
  const [dayChosed, setDayChosed] = useState(false)
  const [forbiddenDay, setForbiddenDay] = useState(false)
  const [dayMouseOver, setDayMouseOver] = useState(false)
  const [availability, setAvailability] = useState(null)
  const [rrender, setRrender] = useState(0)



  const mouseOver = () => {
    setDayMouseOver(true)
  }

  const mouseLeave = () => {
    setDayMouseOver(false)
  }


  const [onHoverItem, setOnHoverItem] = useState(false)

  const onHoverItemIn = () => {
    setOnHoverItem(true)
  }

  const onHoverItemOut = () => {
    setOnHoverItem(false)
  }

  const color = service === "local" || service === "color"  ? "ybg" : "gbg"

  const [exists, setExists] = useState(false)
  useEffect(() => {
    let existsF = allDatesMerged.includes(time[0] + time[1] + time[3] + time[4])

    if (existsF === true) {
      setExists(true)
    } else {
      setExists(false)
    }

  }, [allDatesMerged])

  const [serviceOfBlock, setServiceOfBlock] = useState(null)
  const [confirmar, setConfirmar] = useState(false)
  const [blocked, setBlocked] = useState(false)


  useEffect(() => {
    allDatesW.forEach((e) => {
      const findex = e[0].find(a => a == time[0] + time[1] + time[3] + time[4])
      if (findex !== undefined) {
        setServiceOfBlock(e[1].id)
      }
    })
  }, [allDatesW])



  return (

    <div className='row relative'>
      <div className={client === null && exists === false ? 'weekorderCardCont lightred w3rem ' : client === null && exists === true ? "weekorderCardCont darkblue w3rem" : client !== null && status === true ? 'weekorderCardCont darkblue w3rem' : "weekorderCardCont darkblue w3rem"} >
        <p className='orderCardText whitetext'>{time}</p>
      </div>

      <div className={client !== null && blocks === 1 && status === true ? `rowOrderCardCont  ` : client !== null && blocks === 2 && status === true ? `rowOrderCardCont WEEKLYh2blocksName` : client !== null && blocks === 3 && status === true ? `rowOrderCardCont WEEKLYh3blocksName` : client !== null && blocks === 4 && status === true ? `rowOrderCardCont WEEKLYh4blocksName` : client !== null && blocks === 5 && status === true ? `rowOrderCardCont WEEKLYh5blocksName` :
        client !== null && blocks === 6 && status === true ? `rowOrderCardCont WEEKLYh6blocksName` :
          client !== null && blocks === 7 && status === true ? `rowOrderCardCont WEEKLYh7blocksName` :
            client !== null && blocks === 8 && status === true ? `rowOrderCardCont WEEKLYh8blocksName` :
              client !== null && blocks === 9 && status === true ? `'rowOrderCardCont WEEKLYh9blocksName` :
                client !== null && blocks === 10 && status === true ? `rowOrderCardCont WEEKLYh110blocksName` :
                  client !== null && blocks === 11 && status === true ? `rowOrderCardCont WEEKLYh11blocksName` :
                    client !== null && blocks === 12 && status === true ? `rowOrderCardCont WEEKLYh12blocksName` :
                      client !== null && blocks === 13 && status === true ? `rowOrderCardCont WEEKLYh13blocksName` :
                        client !== null && blocks === 14 && status === true ? `rowOrderCardCont WEEKLYh14blocksName` :
                          client !== null && blocks === 15 && status === true ? `rowOrderCardCont WEEKLYh15blocksName` : client !== null && blocks === 16 && status === true ? `rowOrderCardCont WEEKLYh16blocksName` :
                            'rowOrderCardCont'}>

        <Link to={client !== null ? `/dashboard/bookings?${client.name.replace(" ", "-")}` : ""}>
          <div className={client === null && exists === false ? 'weekorderCardCont lightred  ' : client === null && exists === true ? "weekorderCardCont darkblue" : client !== null && status === true ? 'weekorderCardCont ' : "weekorderCardCont darkblue"} >
            {client !== null && status === true ? <strong className='weeklyOrderCardText'> <span className=' button2FitCont'>{client.name.toUpperCase()}</span> </strong> : <p className='orderCardText whitetext'> {client === null && exists === true ? <p className='button2 button2minitext'>
              DISP
            </p> : "-"} </p>}
            {/* {client !== null ? <p className='orderCardText'></p> : } */}
          </div>

        </Link>

      </div>

    </div>


  )

}

