import React, { useState, createContext, useContext, useEffect } from 'react';
import { setDoc, doc, getFirestore, collection, getDocs, getDoc, query, where} from 'firebase/firestore';
// import axios from 'axios'
import { useParams } from 'react-router-dom';

const LoginContext = createContext([])

export const useLoginContext = () => useContext(LoginContext)


const LoginContextProvider = ({ children }) => {


    const db = getFirestore()

    const [profileData, setProfileData] = useState([])
    const [loginUsers, setLoginUsers] = useState([])
    const [render, setRender] = useState(false)
    const [contextLogin, setContextLogin] = useState([])
    const [ip, setIP] = useState('');
    const [loginCheck, setLoginCheck] = useState(true)
    const [usersRegistered, setUsersRegistered] = useState([])
    const [planCheck, setPlanCheck] = useState([])

    // //creating function to load ip address from the API
    // const getData = async () => {
    //   const res = await axios.get('https://geolocation-db.com/json/')
    //   setIP(res.data.IPv4)
    // }
    
    // useEffect( () => {
    //   //passing getData method to the lifecycle method
    //   getData()
  
    // }, [])

    //

    


    useEffect(() => {
        setLoginCheck(JSON.parse(localStorage.getItem(`CoquetaloginToken`)))
    }, [render])
    



    const rerenderLogin = () => {
      if(render === true) {
        setRender(false)
      } else {
        setRender(true)
      }
     }

 
    useEffect(() => {
        const QueryCollection = collection(db, `profile`)
        getDocs(QueryCollection)
          .then(resp => setProfileData(resp.docs.map(accounts => ({ id: accounts.id, ...accounts.data() }))))
          .catch((err) => console.log(err))
  
       }, [render])


       useEffect(() => {
        const loginFind = profileData.find(e => e.id === "login" )  

        setLoginUsers(loginFind)
   
      }, [profileData, contextLogin])
      
  

    return (
        <LoginContext.Provider value={{
            contextLogin,
            loginUsers,
            rerenderLogin,
            ip,
            loginCheck,
            usersRegistered,
            planCheck
            
        }}>
            {children}

        </LoginContext.Provider>
    )

}

export default LoginContextProvider