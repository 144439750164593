import React, { useState, useEffect } from 'react'
import { collection, getFirestore, getDocs, writeBatch, where, query, documentId } from 'firebase/firestore';
import { useBookingContext } from '../../context/BookingContext'
import NavbarPanel from '../NavbarPanel';
import { useLoginContext } from '../../context/LoginContext'
import Login from '../login/Login';
import RefreshButton from '../widgets/RefreshButton';
import RequestsList from './RequestsList';

export default function RequestsListCont() {
    const db = getFirestore()
    const { requests, rerender } = useBookingContext()
    const { loginCheck } = useLoginContext()
  
  
  
    const [search, setSearch] = useState("")
    const [active, setActive] = useState(false)
  

 
  
    const handleSubmit = (event) => {
      event.preventDefault();
    }
  
    useEffect(() => {
      setActive(true)
    }, [])
  
  
    const [loginAnimation, setLoginAnimation] = useState(false)
  
    useEffect(() => {
      setLoginAnimation(true)
  
      setTimeout(() => {
        setLoginAnimation(false)
  
  
      }, 2000);
    }, [])
  
    const transferTrue = async ( g) => {
  
      const arr = [g.toString()]
         
         
      const queryCol = collection(db, `requests/`)
     
      const batch = writeBatch(db)
      const updateId2 = await query(
          queryCol,
        where(documentId(), 'in', arr))
     
     
        
         await getDocs(updateId2)
         .then(resp => resp.docs.forEach(res => {
          
      
          batch.update(res.ref, { transfer: true });
        })   )  
        await batch.commit();
        rerender()
  
      }
  
  
  
  
    return (
      <>
        {loginCheck != true ?
  
          <div className={loginAnimation === true ? 'logincont loginlogoAnim' : 'logincont'}>
  
  
            <Login  />
          </div>
          :
          <>
            <div className='dashboardCont'>
              <NavbarPanel requests={requests} active={active}  />
              <div className='bookOrderDashboardlistCol margintop'>
                <p className='sectionTittles'>| SOLICITUDES</p>
  
                {/* <div className='searchOrderCont'>
                <RefreshButton refreshFunction={rerender} />
                  <form className='addRoomFormCont' action="" onSubmit={handleSubmit}>
                    <input className='searchOrderInput' type="search" onChange={(e) => setSearch(e.target.value)} placeholder='Buscar por nombre...' />
                  </form>
                </div> */}
  
                <div>
  
   
                </div>
  
                <RequestsList requests={requests} search={search} rerender={rerender} transferTrue={transferTrue} />
              </div>
            </div>
          </>
        }
      </>
    )
  }
  